import * as Vue from "vue";
import { materialize } from "rxjs/operator/materialize";
let KeyRange: string = "01234567889ABCDEF";
export class SocketState{
	Socket: LiveSocket?;
	Messages: SocketResponse[];
	Error: Error?;
	Closed: boolean;
	constructor(){
		this.Socket = null;
		this.Messages = [];
		this.Error = null;
		this.Closed = false;
	}
}
export const LiveSocketModule = {
	namespaced: true,
	stric: true,
	state: new SocketState(),
	getters: { },
	mutations: {
		set: function(state: SocketState, socket: LiveSocket){
			state.Socket = socket
		},
		setMessages: function(state: SocketState, messages: SocketResponse[]){
			state.Messages = [];
		},
		add: function(state: SocketState, message: SocketResponse){
			state.Messages.push(message);
		},
		setError: function(state: SocketState, error: Error){
			state.Error = error;
		},
		setClosed: function(state: SocketState, closed: boolean){
			state.Closed = closed;
		}
	},
	actions: {
		create: function(context){
			let key: string = context.rootState.live.Key;
			let classroom: VirtualSession = context.rootState.live.Classroom;
			let userData: UserData = context.rootState.UserData;
			let location = context.rootState.live.Location;
			let socketData: SocketEmployee = {
				id: classroom.CLASSROOM_STRING_ID,
				key: key,
				session_id: classroom.CLASSROOM_VIRTUAL_SESSION_ID,
				emp_code: userData.EMP_CODE,
				emp_name: userData.EMP_NAME,
				company: userData.COMPANY_CODE,
				training: classroom.TRAINING_CODE_ID,
				instructor: classroom.EMP_CODE,
				lat: 0, lng: 0,
				site_code: location.SITE_CODE,
				site_name: location.SITE_NAME,
				location_id: location.LOCATION_ID,
				location_name: location.LOCATION_NAME,
				status: 10000,
				presenter: 0,
				mobile: 0
			};
			return new Promise((res, rej) => {
				context.commit(
					"set",
					new LiveSocket(context, res, rej,
						socketData,
						"Godfather."+classroom.CLASSROOM_STRING_ID
					)
				);
			});
		},
		process: function(context, msg: SocketResponse){
			context.commit("add", msg);
			if(msg.code){
				switch (msg.code) {

					case 100:
						//Instructor Returned
						context.commit("live/setInstructorStatus", true, { root: true });
						break;

					case 200: 
						//Session Completed
						context.dispatch("live/complete", null, { root: true });
						break;

					case 203:
						//Mute Participant
						if(msg.data.EMP_CODE == context.rootState.UserData.EMP_CODE){
							context.dispatch("live/setMicMute", true, { root: true });
							Materialize.toast("Muted by instructor.", 3260);
						}
						/*
						else{
							context.commit("live/setVoiceChatParticipantMute", {
								code: msg.data.EMP_CODE,
								muted: true
							}, { root: true });
						}
						*/
						break;

					case 204:
						//Un Mute Participant
						if(msg.data.EMP_CODE == context.rootState.UserData.EMP_CODE){
							context.dispatch("live/setMicMute", false, { root: true });
							Materialize.toast("Unmuted by instructor.", 3260);
						}
						/*
						else{
							context.commit("live/setVoiceChatParticipantMute", {
								code: msg.data.EMP_CODE,
								muted: false
							}, { root: true });
						}
						*/
						break;

					case 205:
						//Mute all
						if(msg.data.EMP_CODE == "-" && context.rootState.live.VoiceChat.Info.TrackId && context.rootState.live.VoiceChat.Info.Muted != msg.data.MUTED){
							context.dispatch("live/setMicMute", msg.data.MUTED, { root: true });
							Materialize.toast(msg.data.MUTED ? "Muted by instructor." : "Unmuted by instructor.", 3260);
						}
						//context.commit("live/setVoiceChatParticipantsMute", msg.data.MUTED, { root: true });
						break;

					case 206:
						// Instructor Track Added
						context.commit("live/addInstructorStream", msg.data, { root: true });
						break;

					case 207:
						// Instructor Track removed
						context.commit("live/removeInstructorStream", msg.data.id, { root: true });
						break;

					case 208:
						// ScreenLayout changed
						context.commit("live/rotateLayout", null, { root: true });
						break;

					case 209:
						//Session status info
						context.dispatch("live/setStatus", msg.data, { root: true });
						break;

					case 300:
						//Instructor Device Registered
						if(!msg.data.MOBILE){
							context.commit("live/setMainApp", true, { root: true });
						}
						break;

					case 409:
						//Instructor Device Disconnected
						if(!msg.data.MOBILE){
							context.commit("live/setMainApp", false, { root: true });
							if(
								context.rootState.live.Speaking 
								&& (context.rootState.live.Speaking.Requesting 
								||  context.rootState.live.Speaking.Connected)
							){
								context.dispatch("live/cancelSpeakRequest", null, { root: true });
							}
						}
						break;

					case 410:
						//Instructor Gone
						context.commit("live/setInstructorStatus", false, { root: true });
						break;

					case 503:
						//Session Cancelled

						break;

					case 1006:
						//Evaluation Started
						if(!context.rootState.live.Evaluation){
							context.dispatch("live/startEvaluation", { evaluation_id: msg.data.evaluation_id }, { root: true });
						}
						break;

					case 1007:
						//Toggle Pause Evaluation
						if(
							context.rootState.live.Evaluation
							&& context.rootState.live.Evaluation.test_header.CLASSROOM_TEST_HEADER_ID == msg.data.evaluation_id
						){
							context.commit("live/toggleEvaluationStatus", null, { root: true });
						}
						break;

					case 1008:
						//Finish Evaluation
						if(
							context.rootState.live.Evaluation
							&& context.rootState.live.Evaluation.test_header.CLASSROOM_TEST_HEADER_ID == msg.data.evaluation_id
						){
							context.dispatch("live/finishEvaluation", false, { root: true });
						}
						break;

					case 1009:
						//Cancel Evaluation
						if(
							context.rootState.live.Evaluation
							&& context.rootState.live.Evaluation.test_header.CLASSROOM_TEST_HEADER_ID == msg.data.evaluation_id
						){
							context.dispatch("live/finishEvaluation", true, { root: true });
						}
						break;

					default: break;
				}
			}
		},
		addEmployee: function(context, emp: Employee){
			let newEmpMsg: SocketData = new SocketData();
			newEmpMsg.data = {
				code: 1004,
				data: {
					EMP_CODE: emp.EMP_CODE,
					EMP_NAME: emp.EMP_FIRST_NAME+" "+emp.EMP_LAST_NAME
				}
			};
			context.commit("add", newEmpMsg);
			context.state.Socket.publish(newEmpMsg);
		},
		removeEmployee: function(context, emp: Employee){
			let removeMsg: SocketData = new SocketData();
			removeMsg.data = {
				code: 1005,
				data: {
					EMP_CODE: emp.EMP_CODE,
					EMP_NAME: emp.EMP_FIRST_NAME+" "+emp.EMP_LAST_NAME
				}
			};
			context.commit("add", removeMsg);
			context.state.Socket.publish(removeMsg);
		},

		sendEvaluationAnswer: function({ state, commit, rootState }, payload: { section: number, question: number, answers: [{}] }){
			let answerMsg: SocketData = new SocketData();
			answerMsg.data = {
				code: 1010,
				data: {
					"evaluation_id": rootState.live.Evaluation.test_header.CLASSROOM_TEST_HEADER_ID,
					"section_id": payload.section,
					"question_id": payload.question,
					"emp_code": rootState.UserData.EMP_CODE,
					"emp_name": rootState.UserData.EMP_NAME,
					"answers": payload.answers
				}
			};
			commit("add", answerMsg);
			state.Socket.publish(answerMsg);
		},
		cancelEvaluation: function({ state, commit, rootState }){
			let cancelMsg: SocketData = new SocketData();
			cancelMsg.data = {
				code: 1013,
				data: {
					"evaluation_id": rootState.live.Evaluation.test_header.CLASSROOM_TEST_HEADER_ID,
					"emp_code": rootState.UserData.EMP_CODE,
					"emp_name": rootState.UserData.EMP_NAME
				}
			};
			commit("add", cancelMsg);
			state.Socket.publish(cancelMsg);
		},
		finishEvaluation: function(context){
			let requestMsg: SocketData = new SocketData();
			requestMsg.data = {
				code: 1012,
				data: {
					"evaluation_id": context.rootState.live.Evaluation.test_header.CLASSROOM_TEST_HEADER_ID,
					"emp_code": context.rootState.UserData.EMP_CODE,
					"emp_name": context.rootState.UserData.EMP_NAME
				}
			}
			context.state.Socket.publish(requestMsg);
		},

		disconnect: function(context){
			if(context.state.Socket){
				context.state.Socket.disconnect();
			}
		},
		quit: function({ state, rootState }){
			if(state.Socket){
				let unregisterMsg: SocketData = new SocketData();
				unregisterMsg.data = {
					code: 202,
					data: {
						EMP_CODE: rootState.UserData.EMP_CODE,
						MOBILE: false
					}
				};
				state.Socket.publish(unregisterMsg);
				state.Socket.quit();
			}
		}
	}
}
export class LiveSocket{
	EmpData: SocketEmployee;
	Channel: string;
	Socket: WebSocket;
	Subscribed: boolean;
	constructor(context, res, rej, empData: SocketEmployee, channel: string){
		this.EmpData = empData;
		this.Channel = channel;
		this.Subscribed = false;
		let init: boolean = true;
		let errored: boolean = false;
		let quited: boolean = false;
		try{
			this.Socket = new WebSocket("wss://classroom.locstatt.net/cfws");
			}catch(err){
				rej("Error creating socket "+err);
				return;
			}
			this.Socket.onopen = () => {
				let welcomeMsg: SocketWelcome = new SocketWelcome();
				this.publish(welcomeMsg);
			};
			this.Socket.onmessage = (msg: MessageEvent) => {
				let response: SocketCFResponse;
				try{
					response = JSON.parse(msg.data);
				}catch(err){
					response = { ns: "", type: "none", code: -1, , reqType: "", clientid: "", msg: "", data: null };
					console.error("Unable to process socket message ", msg);
				}
				if(response.type == "response"){
					if(response.reqType == ColdFusion.WebSocket.WELCOME){
						let authMsg: SocketAuthentication = new SocketAuthentication(this.EmpData.emp_code+"", this.EmpData.emp_code+"");
						this.publish(authMsg);
					}else if(response.reqType == ColdFusion.WebSocket.AUTHENTICATE) {
						if(response.code != 0){
							rej(response.msg);
						}else{
							this.subscribe();
						}
					}else if(response.reqType == ColdFusion.WebSocket.SUBSCRIBE){
						if(response.code != 0){
							rej(response.msg);
						}else{
							let registerMsg: SocketData = new SocketData();
							registerMsg.data = {
								code: 201,
								data: this.EmpData
							};
							this.publish(registerMsg);	


							let statusMsg: SocketData = new SocketData();
							statusMsg.data = {
								code: 209,
								data: {}
							};
							this.publish(statusMsg);
						}
					}else if(response.reqType == ColdFusion.WebSocket.PUBLISH){
						if(response.code != 0){
							console.error("Publish Error: "+response.msg);
						}
					}else if(response.reqType == ColdFusion.WebSocket.UNSUBSCRIBE){
						this.Subscribed = false;
						//this.Socket.close();
					}
				}else if(response.type == ColdFusion.WebSocket.PUBLISH){
					rej("Error subscribing? or authenticating?");
				}else if(response.type == "subscribe"){
					rej("User already in the session.");
					this.Socket.close();
				}else if(response.type == "data"){
					try{
						let cMsg: SocketResponse = JSON.parse(response.data);
						if(cMsg){
							if(init && cMsg.code == 209){
								res();
								init = false;
							}else if(cMsg.code == 401){
								rej("Register Required.");
								return;
							}else if(cMsg.code == 406){
								rej("User already in session.");
								return;
							}
							context.dispatch("process", cMsg);
						}
					}catch(err){
						console.error("Unable to process socket data message ", response.data, err);
					}
				}
			};
			this.Socket.onerror = (err: Event) => {
				errored = true;
				if(init){
					rej("Socket error: "+err);
				}else{
					context.commit("setError", err);
				}
			};
			this.Socket.onclose = () => {				
				if(!errored && !quited){
					let newError: Error = new Error();
					newError.message = "Socket closed unexpectedly."
					context.commit("setError", newError);
				}
				context.commit("setClosed", true);
				context.commit("set");
				context.commit("setMessages", []);
			};
	}
	subscribe(): void{
		if(this.Socket.readyState == this.Socket.OPEN){
			let subscribeMsg: SocketSubscribe = new SocketSubscribe(this.Channel);
			this.publish(subscribeMsg);
		}
	}
	publish(msg: SocketMessage): void{
		if(this.Socket.readyState == this.Socket.OPEN){
			if(!msg.channel){
				msg.channel = this.Channel;
			}
			this.Socket.send(msg.toString());
		}
	}
	disconnect(): void{
		this.Socket.close();
	}
	quit(): void{
		let unsubscribeMsg: SocketUnsubscribe = new SocketUnsubscribe();
		this.publish(unsubscribeMsg);
		this.Socket.close();
	}
}
export class SocketMessage {
	ns: string;
	appName: string;
	authKey: string;
	type: string;
	channel: string;
	data: any;
	constructor(){
		this.ns = ColdFusion.WebSocket.NS;
		this.appName = "LocstattClassroom";
	}
	toString(): string{
		return JSON.stringify(this);
	}
}
export class SocketWelcome extends SocketMessage{
	constructor(){
		super();
		this.type = ColdFusion.WebSocket.WELCOME;
		if(ColdFusion.WebSocket.WelcomeSocket){
			this.authKey = ColdFusion.WebSocket.WelcomeSocket.options.cfauth;
		}else{
			let key: string = "";
			for(let i=0;i<32;i++){
				key += KeyRange[Math.floor(Math.random()*16)];
			}
			this.authKey = key;
		}
	}
}
export class SocketAuthentication extends SocketMessage{
	username: string;
	password: string?;
	constructor(username: string, password: string?){
		super();
		this.type = ColdFusion.WebSocket.AUTHENTICATE;
		this.username = username;
		this.password = password;
	}
}
export class SocketSubscribe extends SocketMessage{
	constructor(channel: string){
		super();
		this.type = ColdFusion.WebSocket.SUBSCRIBE;
		this.channel = channel;
	}
}
export class SocketData extends SocketMessage{
	constructor(){
		super();
		this.type = ColdFusion.WebSocket.PUBLISH;
	}
}
export class SocketUnsubscribe extends SocketMessage{
	constructor(){
		super();
		this.type = ColdFusion.WebSocket.UNSUBSCRIBE;
	}
}