
require.register("@twilio/webrtc/lib/rtcicecandidate.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@twilio/webrtc");
  (function() {
    /* global RTCIceCandidate */
'use strict';

if (typeof RTCIceCandidate === 'function') {
  module.exports = RTCIceCandidate;
} else {
  module.exports = function RTCIceCandidate() {
    throw new Error('RTCIceCandidate is not supported');
  };
}
  })();
});