export default {
	template: ` <div v-bind:id="'modal-'+course.TRAINING_CODE+'-'+token" class="modal modal-fixed-footer" style="width:40%;height:550px;max-height:550px;min-height:550px;">
                    <div class="modal-content" style="padding: 0px !important;">
                        <div :style="{'background-color': course.COVER_COLOR}">	
                            <div class="row z-depth-1">
                                <div class="col s3 center">
                                    <img v-if="course.COVER_IMAGE_NAME" v-bind:src="'https://locstatt.net/app/classroomCovers/' + course.COVER_IMAGE_NAME" style="width: 80px;height: 80px;margin-top: 10px;margin-bottom: 10px;">
                                    <img v-else src="https://locstatt.net/app/classroomCovers/student.png" style="width: 80px;height: 80px;margin-top: 10px;margin-bottom: 10px;">
                                </div>
                                <div class="col s9 center" style="height: 80px;margin-top: 10px;">
                                    <span style="color:white;display: block;text-align: center;font-size: 20px;margin-top: 10px;vertical-align: middle;line-height:20px;">{{course.TRAINING_NAME}}</span>
                                    <span v-if="course.TRAINING_RECURRENCE_NAME === 'Perpetual'" style="color:white;display: block;text-align: center;font-size: 20px;margin-top: 10px;vertical-align: middle;line-height:20px;">Valid for: {{course.TRAINING_RECURRENCE_NAME}}</span>
                                    <span v-else style="color:white;display: block;text-align: center;font-size: 20px;margin-top: 10px;vertical-align: middle;line-height:20px;">Valid for: {{course.TRAINING_VALID_FOR}} {{course.TRAINING_RECURRENCE_NAME}}</span>
                                </div>
                            </div>
                        </div>
                        <div style="margin-left:10%;margin-right:10%;" v-if="course.ONLINE_COURSE && course.COUNT_TESTS">
                            <p style="text-align:center;" v-if="course.CLASSROOM_TEST_TYPE_ID === 10000">
                                <strong>Important</strong><br><br>
                                <span>
                                    You are about to take the course <strong>{{course.TRAINING_CODE}}</strong> which includes a final test.
                                </span><br><br>

                                <span>
                                    If you fail the test, you will be given the option to re-take the test immediately. If you fail a second time, the system will record a 'Fail',
                                </span><br><br>

                                <span>
                                    and you will need to review the entire course before attempting to take the test again.
                                </span><br><br>

                                <span>
                                    If you pass the test, the status will be set as Current.
                                </span>
                            <p>
                            
                            <p style="text-align:center;" v-if="course.CLASSROOM_TEST_TYPE_ID === 10001">
                                <strong>Important</strong><br><br>

                                <span>
                                    You are about to take the course <strong>{{course.TRAINING_CODE}}</strong> which includes a sectional test.
                                </span><br><br>

                                <span>
                                   Failing a sectional test will result in that section of the course needing to be reviewed. You will need to retake the test before you can continue.
                                </span><br><br>

                                <span>
                                    Leaving a course with a sectional test will result in the loss of all progress made on the course slides and test.
                                </span><br><br>

                                <span>
                                    When you pass all sections of the test, your Course record will automatically be updated and set as Current.
                                </span><br><br>
                            <p>
                        </div>
                        <div style="margin-left:10%;margin-right:10%;" v-else>
                            <p style="text-align:center;">
                                <br><br>
                                <h5 style="text-align:center;">
                                    Are you sure you want to take this course?
                                </h5>
                            <p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link class="modal-action modal-close btn green right" style="border-radius:30px;" :to="{ name: 'CourseContent', params: {id: course.TRAINING_CODE_ID, token: token, detailid: detail_id, openedFromWebApp: true, course_color: course.COVER_COLOR, course_icon:course.COVER_IMAGE_NAME, course_name: course.TRAINING_NAME, course_code:course.TRAINING_CODE, firstTimeOpened: true, count_tests: course.COUNT_TESTS,passingScore: course.TRAINING_SCORE, recurrance_id: course.TRAINING_RECURRENCE_ID, valid_for: course.TRAINING_VALID_FOR} }">
                            Start
                        </router-link>
                        <a class="modal-action modal-close btn red right" style="border-radius:30px;margin-right:10px;">Close</a>
                    </div>
                </div>`,

	props:["course"],

    data: function(){
		return {
			token: "",
            detail_id: 0
		};
	},

	mounted: function(){
        $('.modal').modal();
        const classroom_token = this.course.CLASSROOM_TOKEN ? this.course.CLASSROOM_TOKEN : 'normal-web-course';
        this.token = classroom_token;
        const detail_id = this.course.SCHEDULED_TRAINING_DETAIL_ID ? this.course.SCHEDULED_TRAINING_DETAIL_ID : -1;
        this.detail_id = detail_id;
	}
};