import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import * as $ from 'jquery';
import * as rx from 'rx';

export default {
	template: `
		<div id="Compliance" class="controller" :class="{ 'open-drawer': showDrawer }">
			<navbar></navbar>
			<sidenav></sidenav>
			<loader v-if="Loading"></loader>
			<h5>Compliance</h5>
		</div>
	`,
	data: function(){
		return {
			Loading: true
		};
	},
	created: function(){
		
	}
	methods: { },
	computed: {
		showDrawer: function(){
			return this.$store.state.ShowMenuDrawer;
		}
	}
};