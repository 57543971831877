
require.register("xml-js/lib/json2xml.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "xml-js");
  (function() {
    var js2xml = require('./js2xml.js');

module.exports = function (json, options) {
  if (json instanceof Buffer) {
    json = json.toString();
  }
  var js = null;
  if (typeof (json) === 'string') {
    try {
      js = JSON.parse(json);
    } catch (e) {
      throw new Error('The JSON structure is invalid');
    }
  } else {
    js = json;
  }
  return js2xml(js, options);
};
  })();
});