import * as Utils from "../../../controllers/Utils";
import * as swal from 'sweetalert2';
import axios from "axios";

export default {
	template: `<div class="card sticky-action col s3 bounceInUp animated" style="margin-left:1%;padding:0 0.0rem !important;font-size:12px;transform: translateY(0px);transition: all 0.35s;">
                    <div class="card-image" :style="{ 'height':'155px', 'background-color': cover_color}">
                        <a v-if="courseType === 'in-progress'" class="dropdown-button" data-beloworigin="true" v-bind:data-activates="'dropdown-'+courseData.TRAINING_CODE.split('.').join('-')" style="cursor:pointer;">
                            <i class="material-icons right" style="margin-top:5px;margin-right:5px;cursor:pointer;color:white">more_vert</i>
                        </a>

                        <div class="row">
                            <div class="col s12" style="margin-top: 0px;" v-if="courseType === 'in-progress'">
                                <img v-if="courseData.COVER_IMAGE_NAME" style="width: 110px; height: 110px; margin-top: 0px; margin-left: -5px;display: block;margin-left: auto;margin-right: auto;" v-bind:src="'https://locstatt.net/app/classroomCovers/' + courseData.COVER_IMAGE_NAME"></img>
                                <img v-else style="width: 110px; height: 110px; margin-top: 22px; margin-left: -5px;display: block;margin-left: auto;margin-right: auto;" src="https://locstatt.net/app/classroomCovers/student.png"></img>
                            </div>

                            <div class="col s12" style="margin-top: 20px;" v-else>
                                <img v-if="courseData.COVER_IMAGE_NAME" style="width: 110px; height: 110px; margin-top: 0px; margin-left: -5px;display: block;margin-left: auto;margin-right: auto;" v-bind:src="'https://locstatt.net/app/classroomCovers/' + courseData.COVER_IMAGE_NAME"></img>
                                <img v-else style="width: 110px; height: 110px; margin-top: 22px; margin-left: -5px;display: block;margin-left: auto;margin-right: auto;" src="https://locstatt.net/app/classroomCovers/student.png"></img>
                            </div>
                            
                            <ul v-bind:id="'dropdown-'+courseData.TRAINING_CODE.split('.').join('-')" class='dropdown-content' v-if="courseType === 'in-progress'">
                                <li>
                                    <a style="color:red;font-size:12px" v-on:click="cancelCourse(courseData.CLASSROOM_TOKEN, courseData.TRAINING_ONLINE_HEADER_ID)">
                                        <i class="material-icons" style="color:red;font-size:20px;">delete</i>
                                        Cancel
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-content" style="padding:0px !important;">
                        <div class="row" style="border-top:2px solid black; height:40px;margin-bottom:0px;line-height: 14px;">
                            <span class="center" style="display:block;margin-top:8px;font-size:14px;margin-right:10%;margin-left:10%;">{{courseData.TRAINING_NAME}}</span>
                        </div>
                        <div class="row" style="height:20px;margin-bottom:0px;">
                            <span class="center" style="display:block;margin-top:8px;"><strong style="font-weight:bold">Code: </strong><span>{{courseData.TRAINING_CODE}}</span><span>
                        </div>

                        <div v-if="courseType === 'required-training'" >
                            <div class="row" style="height:20px;margin-bottom:0px;margin-top: -5px;">
                                <span class="center" style="display:block;margin-top:8px;"><strong style="font-weight:bold">Valid for: </strong><span>{{course_recurrance}}</span></span>
                            </div>
                            <div class="row" :style="{'border-top':'2px solid black','border-bottom':'2px solid black','margin-top':'5px','background-color':course_status_color,'margin-bottom':'0px'}">
                                <span class="center" style="display: block; margin-top: 6px;margin-bottom: 6px;"> <strong style="font-weight:bold;">Status: </strong><span> {{course_status}} </span></span>
                            </div>
                        </div>

                        <div v-if="courseType === 'scheduled-training'">
                            <div class="row" :style="{'border-top':'2px solid black','border-bottom':'2px solid black','margin-top':'5px','background-color':course_target_date_color,'margin-bottom':'0px'}">
                                <span class="center" style="display: block; margin-top: 6px;margin-bottom: 6px;"> <strong style="font-weight:bold;">Target Date: </strong><span> {{courseData.TARGET_DATE}} </span></span>
                            </div>
                            <div class="row" style="margin-bottom:0px;">
                                <span class="center" style="display: block; margin-top: 6px;margin-bottom: 6px;"> <strong style="font-weight:bold;">Scheduled By: </strong><span> {{courseData.SCHEDULED_BY}} </span></span>
                            </div>
                        </div>
                    </div>
                    <div class="card-action" style="border-top: none;max-height:100px;">
                        <div class="row" style="margin-bottom:0px;">
                            <router-link v-if="courseType === 'in-progress' || (courseType === 'scheduled-training' && courseData.IN_PROGRESS)" class="modal-action modal-close btn green right" style="border-radius:30px;" :to="{ name: 'CourseContent', params: {id: courseData.TRAINING_CODE_ID, token: course_token, course_color: courseData.COVER_COLOR, course_icon: courseData.COVER_IMAGE_NAME, detailid: courseData.SCHEDULED_TRAINING_DETAIL_ID ? courseData.SCHEDULED_TRAINING_DETAIL_ID : -1, openedFromWebApp: true, course_name: courseData.TRAINING_NAME, course_code:courseData.TRAINING_CODE, firstTimeOpened: false, count_tests: courseData.COUNT_TESTS, header_id: courseData.TRAINING_ONLINE_HEADER_ID, lastSlide: courseData.LAST_SLIDE, passingScore: courseData.TRAINING_SCORE, recurrance_id: courseData.TRAINING_RECURRENCE_ID, valid_for: courseData.TRAINING_VALID_FOR }}">
                                Continue
                            </router-link>
                            <a v-else style="border-radius:30px;" class="btn blue right modal-trigger" v-bind:href="'#modal-'+courseData.TRAINING_CODE+'-'+course_token">Open</a>
                        </div>
                        <div v-if="courseType === 'in-progress'" class="row center" style="margin-top:7px;margin-bottom:0px;">
                            <span style="margin-left: 10px;">Progress: {{courseData.progress}}%</span>
                            <div class="progress" style="border: 1px solid #c6c6c6;">
                                <div v-if="courseData.progress === 100" class="determinate center" v-bind:style="{ width: courseData.progress+'%'}">
                                </div>
                                <div v-else-if="courseData.progress < 100" class="determinate2 center" v-bind:style="{ width: courseData.progress+'%'}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`,

    data: function(){
        return{
            courseData: this.courseData, //prop
            courseType: this.courseType, //prop
            cover_color: "",
            course_recurrance: "",
            course_status: "",
            course_status_color: "",
            course_target_date_color: "",
            course_token: "normal-web-course",
            groupindex: 1,
            groupIndex:1,
            index: 1,
        };
    },

	props:["courseData", "courseType"],

	created: function(){
        const course_cover_color: string = this.courseData.COVER_COLOR;
        this.cover_color = course_cover_color ? course_cover_color : '#607D8B';
        
        //Check if the course is required-training, if it is then we need to show specific elements on card
        if(this.courseType === 'required-training'){
            const recurrance_name: string = this.courseData.TRAINING_RECURRENCE_NAME; //The recurrance name: years, months, days, etc
            const course_valid_for: string = this.courseData.TRAINING_VALID_FOR; //How long is the course valid for
            const courseLastExpirationDate: string = this.courseData.LAST_EXPIRATION_DATE; //Course expiration date
            const courseStatus: string = this.getRequiredCourseStatus(courseLastExpirationDate); //Get the course status according to the expiration date
            
            this.course_recurrance = (recurrance_name === 'Perpetual') ? recurrance_name : `${course_valid_for} ${recurrance_name}`;
            this.course_status = `${courseStatus} (${courseLastExpirationDate})`;
            this.course_status_color = (courseStatus === 'Current') ? '#FFFFFF' : '#fed311';
        }

        if(this.courseType === 'scheduled-training'){
            const course_target_date: string = this.courseData.TARGET_DATE;
            const today: Date = new Date();
            const targetDate: Date = new Date(course_target_date);
            const course_token: string = this.courseData.CLASSROOM_TOKEN;
            this.course_target_date_color = (today > targetDate) ? "#FF4A4A" : "#FFFFFF";
            this.course_token = course_token ? course_token : 'normal-web-course';
        }

        if(this.courseType === 'in-progress'){
            const course_token: string = this.courseData.CLASSROOM_TOKEN;
            this.course_token = course_token ? course_token : 'normal-web-course';
        }

        if(this.courseType === 'all-courses'){
            this.course_token = 'normal-web-course';
        }
	},

    mounted: function(){
        $('.dropdown-button').dropdown({
            inDuration: 300,
            outDuration: 225,
            constrainWidth: false, // Does not change width of dropdown to that of the activator
            gutter: 0, // Spacing from edge
            belowOrigin: false, // Displays dropdown below the button
            alignment: 'left', // Displays dropdown with edge aligned to the left of button
            stopPropagation: false // Stops event propagation});
        });
    },

	methods: {

        openCourseMessageBeforeStarting: function(courseData: any){
            if(this.courseType === 'required-training'){
                $(`#modal-${courseData.TRAINING_CODE}`).modal('open'); 
            }
        },

        cancelCourse: async function(token, header_id){
            if(token){
                swal.fire({
					title: "Error",
					text: 'This is a scheduled course and it cannot be cancelled!',
					icon: 'error'
				});
            }else{
                const cancelWarningResult = await swal.fire({
					title: "WARNING!",
					text: 'Are you sure you want to cancel this training in progress?',
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: "No",
					confirmButtonText: "Yes",
					reverseButtons:true,
					confirmButtonColor: "#64EC6C",
					cancelButtonColor: "#7C7C7C",
				});
                if(cancelWarningResult.isConfirmed){
                    this.Loading = true;
                    const cancelCourseData = {
                        params:{
                            method: "setUpdateOnlineCourseStatus",
                            training_online_header_id: header_id,
                            training_status_id: 3578
                        }
                    }
                    const cancelInProgressCourse = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", cancelCourseData);
                    await this.$parent.$parent.$parent.mountAllData();
                }
            }
        },

        getRequiredCourseStatus: function(last_expiration_date: string){
			const today: Date = new Date();
            const dateFormat: string = this.$store.state.UserData.LOCSTATT_DATE_FORMAT_X;
			const date: string = Utils.getrequestDate(last_expiration_date, dateFormat);
			const courseDate: Date = new Date(date);
			if (last_expiration_date){
				if (courseDate < today){
					return "Expired";
				}else if (courseDate > today){
					return "Current";
				}
			}	
			return "Not Taken";
		},
	}
};