export function convertToFormat(date, format): Date{
	let dateRegex = /([0-9]{2}|[0-9]{1})[- /.]([0-9]{2}|[0-9]{1})[- /.]([0-9]{4})|([0-9]{4})[- /.]([0-9]{2}|[0-9]{1})[- /.]([0-9]{2}|[0-9]{1})/g;
	if (dateRegex.test(date)){
		let formatRegex = /(m{2}|M{2})[- /.](d{2}|D{2})[- /.](y{4}|Y{4})|(d{2}|D{2})[- /.](m{2}|M{2})[- /.](y{4}|Y{4})|(y{4}|Y{2})[- /.](m{2}|M{2})[- /.](d{2}|D{2})|(y{4}|Y{4})[- /.](d{2}|D{2})[- /.](m{2}|M{2})/g;
		if (formatRegex.test(format)){
			date = new Date(date).format(format);
			return date;
		}else{
			
			return date;
		}
	}else{
		
		return date;
	}
}

export function getUserPhoto(EMP_CODE){
	return `https://locstatt.net/app/employeePhotos/${EMP_CODE.substr(-2)}/${EMP_CODE.substr(0,6)}.jpg`
}

export function getrequestDate(date, format): string{
	try{
		let dateRegex = /([0-9]{2}|[0-9]{1})[- /.]([0-9]{2}|[0-9]{1})[- /.]([0-9]{4})|([0-9]{4})[- /.]([0-9]{2}|[0-9]{1})[- /.]([0-9]{2}|[0-9]{1})/g;
		let testPassed = dateRegex.test(date);
		if (testPassed){
			date = parseDate(date, getDateFormat(format, true));
			let year = date.getFullYear();
			let month = (1 + date.getMonth()).toString();
  			month = month.length > 1 ? month : '0' + month;
  			let day = date.getDate().toString();
  			day = day.length > 1 ? day : '0' + day;
			return month + '/' + day + '/' + year;
		}else{
			return date;
		}
	}catch(error){
		
		return date;
	}
}

export function parseDate(input, format): Date{
	format = format || 'mm-dd-yyyy';
	let parts = input.match(/(\d+)/g);
	let i = 0;
	let fmt = {};
	format.replace(/(yyyy|dd|mm)/g, function(part){
		return fmt[part] = i++;
	});
	return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
}

export function dateFormat(date, userFormat): string{
	date = String(date);
	if (date == null || date == undefined || date == "" || date == "null"){
		return "";
	}
	let formatRegex = /([0-9]{2}|[0-9]{1})[- /.]([0-9]{2})[- /.]([0-9]{4})/g;
	if (formatRegex.test(date)){
		let format = userFormat.toLocaleLowerCase();
		let d = new Date(date);
		if (d.toString() == "Invalid Date"){
			return date;
		}else{
			let year = d.getFullYear();
			let month = (1 + d.getMonth()).toString();
  			month = month.length > 1 ? month : '0' + month;
  			let day = d.getDate().toString();
  			day = day.length > 1 ? day : '0' + day;
			switch(userFormat.toLowerCase()){
				case 'mm/dd/yyyy':
					return month + '/' + day + '/' + year;
					break;
				case "dd/mm/yyyy":
					return day + '/' + month + '/' + year;
					break;
				case "yyyy/mm/dd":
					return year + '/' + month + '/' + day;
					break;
				default:
					return month + '/' + day + '/' + year;
					break; 
			}
		}
	}else{
		return date;
	}
}

export function getDateFormat(userFormat, i = false): string{
	let formatRegex = /(m{2}|M{2})[- /.](d{2}|D{2})[- /.](y{4}|Y{4})|(d{2}|D{2})[- /.](m{2}|M{2})[- /.](y{4}|Y{4})|(y{4}|Y{2})[- /.](m{2}|M{2})[- /.](d{2}|D{2})|(y{4}|Y{4})[- /.](d{2}|D{2})[- /.](m{2}|M{2})/g;
	if (formatRegex.test(userFormat)){
		if (i){
			return userFormat.toLocaleLowerCase();
		}else{
			return userFormat;
		}
	}else{
		if (i){
			return "mm/dd/yyyy";
		}else{
			return "MM/DD/YYYY";
		}
	}
}
export function convert24toAMPM(time24: string): string{
	let ts = time24;
	let H = +ts.substr(0,2);
	let h = (H % 12) || 12;
	h = (h < 10) ? ("0"+h) : h;
	var ampm = H < 12 ? " AM" : " PM";
	ts = h + ts.substr(2,3) + ts.substr(5,6) + ampm;
	return ts;
}