
require.register("xml-js/lib/array-helper.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "xml-js");
  (function() {
    module.exports = {

  isArray: function(value) {
    if (Array.isArray) {
      return Array.isArray(value);
    }
    // fallback for older browsers like  IE 8
    return Object.prototype.toString.call( value ) === '[object Array]';
  }

};
  })();
});