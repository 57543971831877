import * as Vue from "vue";
export default Vue.extend({
    template: `
        <div id="Snackbar">
            <p :class="{ limited: action }">{{message}}</p>
            <a v-if="action" @click="doAction">
                <span v-if="actionStr">{{actionStr}}</span>
                <span v-else>Retry</span>
            </a>
        </div>
    `,
    props: ["message", "action", "actionStr", "time"],
    data: function(){
        return {
            message: "",
            action: null,
            actionStr: "",
            time: 6520,
            timeout: null
        };
    },
    created: function(){
        let presentSnackBar: HTMLElement? = document.getElementById("Snackbar");
        if(presentSnackBar){
            presentSnackBar.remove();    
        }
        this.$mount();
        window.document.body.appendChild(this.$el);
    },
    mounted: function(){
        this.$el.style.transform = "translateY(0)";
        if(this.time != -1){
            this.timeout = setTimeout(this.hide, this.time);
        }
    },
    beforeDestroy: function(){
        this.$el.parentNode.removeChild(this.$el);
    },
    methods:{
        hide: function(){
            if(this.timeout){
                clearTimeout(this.timeout);
            }
            this.$el.style.transform = "";
            setTimeout(() => {
                this.$destroy();
            }, 300);
        },
        doAction: function(){
            this.$destroy();
            if(this.action){
                this.action();    
            }
        }
    }
});