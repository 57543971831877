import * as Rx from "rxjs";
import Vue from "vue";
import { materialize } from "rxjs/operator/materialize";

export default {
    template: `
        <div id="EmployeeSearchModal" class="modal" ref="modal">
            <a class="btn-flat small close" @click="close">
                <i class="material-icons">close</i>
            </a>
            <div class="modal-content black-text">
                <div class="row">
                    <div class="col s3">
                        <select class="browser-default" v-model="type">
                            <option value="0">Code</option>
                            <option value="1">First Name</option>
                            <option value="2">Last Name</option>
                        </select>
                    </div>
                    <div class="input-field col s9">
                        <i class="material-icons prefix">search</i>
                        <input id="employee-search" :type="type == '0' ? 'number' : 'text'" v-model="keywords" ref="input">
                        <label for="employee-search">Search Employee by first name, last name or code</label>
                    </div>
                    <ul class="autocomplete-content dropdown-content col s12" v-if="showList && results.length">
                        <li v-for="emp in results" @click="add(emp)">
                            <span>
                                <i class="ion-person-add"></i>
                                {{emp.EMP_CODE}} - {{emp.EMP_FIRST_NAME}} {{emp.EMP_MIDDLE_NAME}} {{emp.EMP_LAST_NAME}}
                            </span>
                        </li>
                    </ul>
                </div>
                <loader v-if="loading"></loader>
                <div class="row">
                    <div class="col s12">
                        <table class="responsive-table highlight striped white">
                            <tbody>

                                <tr v-if="showList" v-for="(emp, index) in list">
                                    <td class="center-align">{{index + 1}}</td>
                                    <td>{{emp.EMP_CODE}}</td>
                                    <td>{{emp.EMP_FIRST_NAME}} {{emp.EMP_MIDDLE_NAME}} {{emp.EMP_LAST_NAME}}</td>
                                    <td class="center-align">
                                        <a class="btn-flat small waves-effect" @click="remove(emp)">
                                            <i class="material-icons">remove_circle</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="showList && !list.length">
                                    <td class="center-align" colspan="4">No employees registered.</td>
                                </tr>

                                <tr v-if="!showList" v-for="(emp, index) in results">
                                    <td class="center-align">{{index + 1}}</td>
                                    <td>{{emp.EMP_CODE}}</td>
                                    <td>{{emp.EMP_FIRST_NAME}} {{emp.EMP_MIDDLE_NAME}} {{emp.EMP_LAST_NAME}}</td>
                                    <td class="center-align">
                                        <a class="btn-flat small waves-effect" @click="add(emp)">
                                            <i class="material-icons">add_circle</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="!showList && !results.length">
                                    <td class="center-align" colspan="4">No employees registered.</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                

            </div>
        </div>
    `,
    props: ['showList', 'list'],
    data: function(){
        return {
            loading: false,
            type: "0",
            keywords: "",
            results: [],
            observable: null,
            addEvent: null,
            removeEvent: null
        }
    },
    created: function(){
        
        if(this.showList && !this.list){
            this.list = [];
        }
        /*
        this.$mount();
        document.body.appendChild(this.$el);
        */
    },
    mounted: function(){
        
        $(this.$refs.modal).modal({
            dismissible: false
        }).modal("open");
        Rx.Observable
        .create((obs) => {
            this.observable = obs;
        })
        .debounceTime(750)
        .subscribe(() => {
            this.search();
        });
    },
    watch: {
        keywords: function(){
            this.observable.next();
        }
    },
    methods: {
        close: function(){
            
            $(this.$refs.modal).modal("close");
            setTimeout(() => {
                this.$emit("close");
            }, 1630);
        },
        add: function(emp){
            
            this.$emit("add", emp);
            this.keywords = "";
            this.results = [];
            /*
            if(this.addEvent){
                this.addEvent(emp).then((msg) => {
                    
                    if(this.showList){
                        this.list.push(emp);
                    }
                    
                    this.keywords = "";
                    this.results = [];
                    Materialize.toast(msg, 3260);
                })
                .catch((err) => {
                    console.error(err);
                    Materialize.toast(err, 3260);
                });
            }else{
                
                if(this.showList){
                    this.list.push(emp);
                }
                
                this.keywords = "";
                this.results = [];
            }
            */
        },
        onAdd: function(on: (Employee) => Promise<string> ){
            this.addEvent = on;
            return this;
        },
        remove: function(emp){
            
            this.$emit("remove", emp);
            /*
            if(this.removeEvent){
                if(!this.removeEvent(emp)){
                    return;
                }
            }
            
            if(this.showList){
                for (let i = 0; i < this.list.length; i++) {
                    if(this.list[i].EMP_CODE == emp.EMP_CODE){
                        this.list.splice(i, 1);
                        break;
                    }
                }
            }
            */
            this.keywords = "";
            this.results = [];
        },
        onRemove: function(on: (Employee) => boolean ){
            this.onRemove = on;
            return this;
        },
        search: function(){
            if(this.keywords){
                this.loading = true;
                var action: string = "";
				if(this.type == "0"){
					action = "searchEmployeeByCode";
				}else if(this.type == "1"){
					action = "searchEmployeeByName";
				}else{
					action = "searchEmployeeByLastName";
				}
				this.$store.dispatch(action, this.keywords)
				.done((data: Array<Employee>) => {
					this.results = data;
				})
				.fail(function(jqxhr, status){
					Materialize.toast("Unable to load results.");
				})
				.always(() => {
					this.loading = false;
				});
            }else{
                this.results = [];
            }
        }
    }

};