export default {
	template: `<div>
                    <div v-if="displayCourses">
                        <div class="col s8 offset-s2">
                            <div class="card-panel white" style="border-radius:20px;">
                                <div class="input-field col s12" style="margin-top:-8px;">
                                <i class="material-icons prefix" style="margin-top:-5px;">search</i>
                                <input v-bind:id="coursesType+'_searchBox'" type="text" class="validate" style="height: 20px;" placeholder="Search by Course Code or Course Name" v-on:keyup="searchCourse($event)">
                                </div>
                            </div>
                        </div>
                        <div style="margin-left:17%;">
                            <courses-row v-for="(courseGroup, groupIndex) in coursesGroups"
                                v-bind:courseGroup="courseGroup.GroupedCourses"
                                v-bind:coursesType="coursesType"
                                :key="courseGroup.random_id"
                            >
                            </courses-row>
                        </div>
                    </div>
                    <div class="material" v-else>
                        <h6 class="grey-text center-align">
                            No Courses to display at the moment.
                        </h6>
                    </div>
                </div>`,
    data: function(){
        return{
            originalList: []
        };
    },
	props:["coursesGroups", "displayCourses", "coursesType"],
    created: function(){
        this.originalList = this.coursesGroups;
    },
    methods: {
        searchCourse: function(event){
            const valueToSearch = $(`#${this.coursesType}_searchBox`).val();
            if(valueToSearch !== ""){
                const joinedList = [];
                this.coursesGroups.forEach(function(group){
                    group.GroupedCourses.forEach(function(course){
                        joinedList.push(course);
                    });
                });
                const filtered = joinedList.filter(course => course.TRAINING_NAME.toLowerCase().includes(valueToSearch.toLowerCase()));
                this.coursesGroups = this.divideListIntoGroups(filtered, 3);
            }else{
                this.coursesGroups = this.originalList;
            }
            
        },

        divideListIntoGroups: function(list, groupSize){
			var size = groupSize;
			var groups = Math.ceil(list.length/size);
			let resultData: Array<object> = [];
			var ec = 0;

			for(var i = 0; i < groups; i++){
				var newG:any = {};
				let courses: Array<object> = []
				for (var j = i*size; j < (i+1)*size; j++){
					courses.push(list[j])
					ec++;
					if (ec === list.length){
						break;
					}
				}
				newG.GroupedCourses = courses;
				resultData.push({...newG, random_id: Math.floor(Math.random() * 10000)});
			}
			return resultData;
		},
    }
};