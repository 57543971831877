import * as Materialize from 'materialize-css';
import * as swal from 'sweetalert';
import * as Rx from 'rxjs';
import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import { GeneralSocket } from './ClassroomSocket';
import * as parser from 'xml2json-light';
import * as  convert from 'xml-js';
import * as Utils from "./Utils";

export default {
	template: `
		<div id="LocationModal" class="modal modal-fixed-footer" ref="modal">
			<loader v-if="Loading"></loader>
			<div class="modal-content">
				<a class="btn-flat small right modal-close" @click="clearLocation"><i class="material-icons">close</i></a>
				<h5>Where are you now?</h5>
				<p>Select the place where you will take the course.</p>
				<ul class="collapsible" ref="collapsible">
					<li :class="{ active: !FacilitySelected }">
						<div class="collapsible-header">
							<i class="material-icons">business</i>
							Facility
							<i class="material-icons green-text right" v-if="FacilitySelected">check_circle</i>
						</div>
						<div class="collapsible-body">
							<div class="collection">
								<a class="collection-item" v-if="Facilities.length" v-for="fac in Facilities" :class="{ active: fac.selected }" @click="loadLocations(fac)">
									{{fac.SITE_NAME}}
								</a>
								<a class="collection-item" v-if="!Facilities.length">
									No facilities available.
								</a>
							</div>
						</div>
					</li>
					<li :class="{ active: FacilitySelected }">
						<div class="collapsible-header">
							<i class="material-icons">location_on</i>
							Location
							<i class="material-icons green-text right" v-if="LocationSelected">check_circle</i>
						</div>
						<div class="collapsible-body right-align">
							<div class="collection left-align">
								<a class="collection-item" v-if="Locations.length" v-for="loc in Locations" :class="{ active: loc.selected }" @click="setLocation(loc)">
									{{loc.LOCATION_NAME}}
								</a>
								<a class="collection-item" v-if="Locations.length">
									<span>Other</span>
									<div class="input-field">
										<input type="text" v-model="OtherLocation" @change="setOtherLocation">
										<label>Specify Location</label>
									</div>
								</a>
								<a class="collection-item" v-if="!Locations.length">
									No locations available
								</a>
							</div>
						</div>
					</li>
					<li>
						<div class="collapsible-header">
							<i class="material-icons">location_city</i>
							Other
							<i class="material-icons green-text right" v-if="OtherPlace.length">check_circle</i>
						</div>
						<div class="collapsible-body grey lighten-5">
							<p>Please Specify your location below.</p>
							<div class="input-field">
								<input type="text" v-model="OtherPlace">
								<label>Current Location</label>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="modal-footer" v-if="OtherPlace || LocationSelected || OtherLocation.length">
				<a @click="storeLocation" class="btn blue modal-action modal-close waves-effect">Continue</a>
			</div>
		</div>`,
	props: ["context","resolve","reject"],
	data: function(){
		return {
			Loading: false,
			Facilities: [],
			Locations: [],
			OtherLocation: "",
			OtherPlace: "",
			FacilitySelected: null,
			LocationSelected: null
		};
	},
	created: function(){
		this.Loading = true;
		$.get({
			url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
			data: {
				method: "getFacilitiesbyCompany",
				company_code: this.context.state.UserData.COMPANY_CODE
			},
			dataType: "json",
			xhrFields: {
				withCredentials: true
			},
			crossDomain: true
		}).done((data: Array<Facility>) => {
			for (var i = data.length - 1; i >= 0; i--) {
				data[i].selected = false;
			}
			this.Facilities = data;
		}).fail(function(){
			Materialize.toast("Unable to load Facilities.", 3260);
		}).always(() => {
			this.Loading = false;
		});
	},
	mounted: function(){
		$(this.$refs.collapsible).collapsible()
		$(this.$refs.modal).modal({ dismissible: false }).modal("open");
	},
	methods: {
		clearLocation: function(){
			this.reject("User rejected location request.");
		},
		loadLocations: function(fac: Facility){
			for (var i = 0; i < this.Facilities.length; i++) {
				if(this.Facilities[i].selected){
					if(this.Facilities[i].SITE_CODE == fac.SITE_CODE){
						return;
					}
					this.Facilities[i].selected = false;
				}
			}
			fac.selected = true;
			this.FacilitySelected = fac;
			this.LocationSelected = null;
			this.Loading = true;
			$.get({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				data: {
					method: "getLocationsbyFacility",
					site_code: fac.SITE_CODE
				}
			}).done((data) => {
				for (var i = data.length - 1; i >= 0; i--) {
					data[i].selected = false;
				}
				this.Locations = data;
				$(this.$refs.collapsible).collapsible("open", 1);
			}).fail(function(){
				Materialize.toast("Unable to load Locations", 3260);
			}).always(() => {
				this.Loading = false;
			});
		},
		setLocation: function(loc: Location){
			if(loc.selected){ return; }
			let currentLoc = this.Locations.filter(function(l){ return l.selected; })[0];
			if(currentLoc){
				currentLoc.selected = false;
			}
			loc.selected = true;
			this.LocationSelected = loc;
		},
		setOtherLocation: function(){
			let currentLoc = this.Locations.filter(function(l){ return l.selected; })[0];
			if(currentLoc){
				currentLoc.selected = false;
			}
			this.LocationSelected = this.OtherLocation;
		},
		storeLocation: function(){
			let detailed: any = {};
			if(this.OtherPlace){
				detailed.SITE_CODE = 0;
				detailed.SITE_NAME = this.OtherPlace;
				detailed.LOCATION_ID = 0;
				detailed.LOCATION_NAME = this.OtherPlace;
			}else{
				detailed.SITE_CODE = this.FacilitySelected.SITE_CODE;
				detailed.SITE_NAME = this.FacilitySelected.SITE_NAME;
				if(this.OtherLocation){
					detailed.LOCATION_ID = 0;
					detailed.LOCATION_NAME = this.OtherLocation;
				}else{
					detailed.LOCATION_ID = this.LocationSelected.LOCATION_ID;
					detailed.LOCATION_NAME = this.LocationSelected.LOCATION_NAME;
				}
			}
			this.resolve(detailed);
		}
	}
};