import { mapGetters } from "vuex";
export default{
	template: `
		<ul id="side-nav" class="side-nav" :class="{ 'open-drawer': drawer }"">
			<li id="side-nav-header" class="white-text center-align">
				<img class="center-align responsive-img circle user-picture" :src="userpic" onerror="this.src='/images/image-placeholder.png'">
				<p class="name">{{userName}}</p>
				<p class="code">({{userCode}})</p>
			</li>
			<li>
				<ul id="side-nav-options">
					<li class="classroom-option" >
						<router-link class="waves-effect" :to="{ path: '/classrooms' }" style="line-height:12px !important;">
							<i class="material-icons">home</i> <span style="display:block;margin-top:12px;">Course Library</span>
						</router-link>
					</li>
					<li class="classroom-option">
						<router-link class="waves-effect" :to="{ path: '/requiredTraining' }" style="line-height:12px !important;">
							<i class="material-icons">pie_chart</i> <span style="display:block;margin-top:12px;">Required Training</span>
						</router-link>
					</li>
					<li class="classroom-option">
						<router-link class="waves-effect" :to="{ path: '/trainingHistory' }" style="line-height:12px !important;">
							<i class="material-icons">format_list_bulleted</i> <span style="display:block;margin-top:12px;">Training History </span>
						</router-link>
					</li>
					<li class="classroom-logout grey lighten-5 center-align">
						<a class="log-out-menu btn-flat" @click="logout"> 
							<span>LOGOUT</span> 
							<i class="material-icons">settings_power</i>
						</a>
	 				</li>
				</ul>
			</li>
		</ul>
	`,
	data: function(){ return {}; },
	computed: {
		...mapGetters(["drawer", "userpic"]),
		userName: function(){
			if(this.$store.state.UserData){
				return this.$store.state.UserData.EMP_NAME;
			}
			return "";
		},
		userCode: function(){
			if(this.$store.state.UserData){
				return this.$store.state.UserData.EMP_CODE;
			}
			return "";
		}
	},
	methods: {
		logout: function(){
			this.$store.dispatch('logout').then(() => {
				this.$router.push({ path: '/' });
			});
		}
	}
}