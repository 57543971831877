export default {
	template: ` <div class="row facility-row">
                    <h5>{{site.SITE_NAME}} ({{site.SESSIONS.length}})</h5>
                    <session-card v-for="session in site.SESSIONS"
                        v-bind:session="session"
                    >
                    </session-card>
                </div>`,

    data: function(){
        return{
            
        };
    },

	props:["site"],

	created: function(){
	},

	mounted: function(){

	},
	methods: {
        
	}
};