import * as swal from 'sweetalert2';
import * as Vue from 'vue';
import * as $ from 'jquery';
import * as parser from 'xml2json-light';
import * as  convert from 'xml-js';
import 'jquery-ui-sortable-npm';
import * as datepickerFactory from 'jquery-datepicker';
import * as select2 from 'select2';
import * as axios from 'axios';
import { vueVimeoPlayer } from 'vue-vimeo-player';

const CLASSROOM_CFC = "https://locstatt.net/app/cfcChevron/classroom.cfc";

export default {
	components: {vueVimeoPlayer},
	template: `
		<div id="CourseContent" class="controller">
			<loader v-if="Loading"></loader>
			<div class="row blue-grey darken-4 white-text" style="margin-bottom:0px;">
  				<i data-activates="slide-out" class="material-icons left button-collapse" style="font-size: 50px;margin-left: 20px;cursor:pointer">menu</i>
				<h5 class="center" style="margin-top:0px;margin-bottom:0px;line-height:45px;">{{course_data.course_name}}</h5>
			</div>

			<div v-if="CourseSlide != undefined">
				<div v-if="CourseSlide._attributes.type === 'mp4'" class="course-slide row center" >
					<video id="courseVideo" v-if="CourseSlide.hasBeenViewed" class='slider-video' autoplay controls disablePictureInPicture controlsList="nodownload" v-on:ended="videoHasEnded" v-on:pause="videoHasPaused" v-on:play="videoIsPlaying" preload="auto">
						<source v-bind:src="this.VideosPath+CourseSlide._attributes.media" type='video/mp4'>
					</video>
					<video id="courseVideo" v-else class='slider-video' autoplay controls disablePictureInPicture controlsList="nodownload" v-on:ended="videoHasEnded" v-on:pause="videoHasPaused" v-on:play="videoIsPlaying" preload="auto">
						<source v-bind:src="this.VideosPath+CourseSlide._attributes.media" type='video/mp4'>
					</video>
				</div>

				<div v-if="CourseSlide._attributes.type === 'youtube'" class="course-slide row center" >
					<div class="video-container">
						<div id='player'></div>
					</div>
				</div>

				<div v-if="CourseSlide._attributes.type === 'vimeo' || CourseSlide._attributes.type === 'other'" class="course-slide row center" >
					<div class="video-container">
						<iframe style="border:none;" id="vimeoPlayer" v-bind:src="CourseSlide._attributes.media" v-on:load="loadVimeoPlayer" height="390" width="640" controls=true autoplay=true allowfullscreen></iframe>
					</div>
				</div>

				<div v-if="CourseSlide._attributes.type === 'png'" class="course-slide row center" >
					<img class='slider-image' v-bind:src="ImagesPath+CourseSlide._attributes.media" >
				</div>

			</div>

			<div class="row" style="margin-left:-11px;">
				<div class="col s12">
					<div class="card-panel blue-grey darken-4 course-controller-bar">
						<div class="row">
							<div class="col s2 center">
								<a v-if="test_type_id === 10000" href="#modalWarning" class="waves-effect waves-light btn red modal-trigger" style="width:135px"><i class="material-icons left">exit_to_app</i>Exit</a>				       		
								<a v-else href="#modalWarning2" class="waves-effect waves-light btn red modal-trigger" style="width:135px"><i class="material-icons left">exit_to_app</i>Exit</a>				       		
								</div>
							<div class="col s1 center">
								<a class="btn-floating btn-large waves-effect waves-light white previous_slide" v-on:click="previousSlide" style="margin-top:-10px;">
									<i class="material-icons center slider-controls blue-grey-text text-darken-4" >skip_previous</i>
								</a>
							</div>
							<div class="col s6" style="margin-top:-20px;">
								<label style="color: white;margin-top:-5px;">Slides</label>
								<select class="slide-select" v-on:change="goToSlide">
									<optgroup v-bind:label="section.name" v-for="(section,index) in CourseSlides">
										<option v-bind:value="slide._attributes.id" v-for="(slide,slideIndex) in section.section_slides" v-bind:data-sectionindex="index" v-bind:data-slideindex="slideIndex" v-if="slide.hasBeenViewed">
											{{index+1}}.{{slideIndex+1}}. {{slide._attributes.title}}
										</option>
										<option v-bind:value="slide._attributes.id" v-else disabled>
											{{index+1}}.{{slideIndex+1}}. {{slide._attributes.title}}
										</option>
									</optgroup>
								</select>
								
							</div>
							<div class="col s1 center">
								<a class="btn-floating btn-large waves-effect waves-light white next_slide" v-on:click="nextSlide" style="margin-top:-10px;">
										<i class="material-icons center slider-controls blue-grey-text text-darken-4" >skip_next</i>
								</a>
							</div>
							<div class="col s2 center" v-if="course_data.count_tests > 0 && allSlidesViewed && test_type_id === 10000">
								<a class="btn orange darken-3 modal-trigger" v-on:click="openCourseTests" style="padding: 0px 1rem;">
										TAKE THE TEST
								</a>
							</div>
							<div class="col s2 center" v-if="CourseSlide.hasBeenViewed && currentSlideHasTest && test_type_id === 10001 && (CourseSlide.EXAM_PASSED === false)">
								<a class="btn orange darken-3" v-on:click="openSlideTest" style="padding: 0px 1rem;" v-bind:data-slide="CourseSlide">
										TAKE THE TEST
								</a>
							</div>
							<div class="col s1" v-if="course_data.count_tests === 0 && allSlidesViewed && test_type_id === 10000">
								<a class="btn orange darken-4" v-on:click="approveCourse(100)">
										FINISH
								</a>
							</div>
							<div class="col s1" v-if="test_type_id === 10001 && allSlideTestsAnswered && allSlidesViewed">
							<a class="btn orange darken-4" v-on:click="approveSlideTestCourse">
									FINISH
							</a>
						</div>
						</div>
					</li>
				    <li style="border-bottom:1px solid #ebebeb">
				    	<ul class="collapsible" data-collapsible="expandable">
				    		<li class="section-title-side-nav" v-for="(section,index) in CourseSlides">
				    			<div class="collapsible-header blue-grey darken-2 white-text active" style="height:60px;"><span style="line-height: 15px;text-align: center;vertical-align: middle;margin-top: 12px;">{{section.name}}<span></div>
				    			<div class="collapsible-body">
				    				<ul class="collection">
				    					<a class="collection-item avatar" style="padding-left: 20px;padding-right:75px;" v-for="(slide, slideIndex) in section.section_slides" v-if="slide.hasBeenViewed && CourseSlide._attributes.id !== slide._attributes.id" v-on:click="goToSlide" v-bind:data-section="index" v-bind:data-slide="slideIndex" v-bind:current-id="CourseSlide._attributes.id" v-bind:slide-id="slide._attributes.id">
				    						<i class="material-icons circle" v-bind:data-section="index" v-bind:data-slide="slideIndex" style="background-color:#00CD9F;margin-left:270px;">check</i>
										   	<span v-bind:data-section="index" v-bind:data-slide="slideIndex" class="title">{{slideIndex+1}}. {{slide._attributes.title}}</span>
										   	<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-if="slide._attributes.type === 'mp4'">
										   		<i class="tiny material-icons">videocam</i> Video	
										   	</p>
										   	<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else-if="slide._attributes.type === 'png'">
										   		<i class="tiny material-icons">photo_camera</i> Picture	
										   	</p>
										   	<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else>
										   		<i class="tiny material-icons">videocam</i> Youtube
										   	</p>
				    					</a>
				    					<a class="collection-item avatar" v-else-if="slide.hasBeenViewed || CourseSlide._attributes.id === slide._attributes.id" v-bind:data-section="index" v-bind:data-slide="slideIndex" style="background-color: #00CD9F;padding-left: 20px;padding-right:75px;" v-bind:current-id="CourseSlide._attributes.id" v-bind:slide-id="slide._attributes.id">
				    						<i class="material-icons circle white" v-bind:data-section="index" v-bind:data-slide="slideIndex" style="color:#00CD9F;margin-left:270px;">check</i>
										   	<span v-bind:data-section="index" v-bind:data-slide="slideIndex" class="title" style="color:white;">{{slideIndex+1}}. {{slide._attributes.title}}</span>
										   	
										   	<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-if="slide._attributes.type === 'mp4'" >
										   		<i class="tiny material-icons" style="color:white;">videocam</i> <span style="color:white;">Video</span>
										   		<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #00CD9F;width:65px;" v-if="videoStatus === 0">
											   		Playing
											   	</span>
											   	<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #ffcc00;width:65px;" v-else-if="videoStatus === 1">
											   		Paused
											   	</span>	
											   	<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #990000;width:65px;" v-else-if="videoStatus === 2">
											   		Stopped
											   	</span>		
										   	</p>
										   	<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else-if="slide._attributes.type === 'png'">
										   		<i class="tiny material-icons" style="color:white;">photo_camera</i> <span style="color:white;">Picture</span>	
										   		<span class="chip btn btn-floating pulse" style="width:80px;font-size:10px;background-color: white;color: #00CD9F"> 
											   		Watching
											   	</span>	
										   	</p>
										   	<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else-if="slide._attributes.type === 'youtube'">
										   		<i class="tiny material-icons" style="color:white;">videocam</i> <span style="color:white;">Youtube</span>
										   		<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #00CD9F;width:65px;" v-if="videoStatus === 0">
											   		Playing
											   	</span>
											   	<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #ffcc00;width:65px;" v-else-if="videoStatus === 1">
											   		Paused
											   	</span>	
											   	<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #990000;width:65px;" v-else-if="videoStatus === 2">
											   		Stopped
											   	</span>	
										   	</p>
											<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else>
										   		<i class="tiny material-icons" style="color:white;">videocam</i> <span style="color:white;">Vimeo</span>
										   		<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #00CD9F;width:65px;" v-if="videoStatus === 0">
											   		Playing
											   	</span>
											   	<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #ffcc00;width:65px;" v-else-if="videoStatus === 1">
											   		Paused
											   	</span>	
											   	<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #990000;width:65px;" v-else-if="videoStatus === 2">
											   		Stopped
											   	</span>	
										   	</p>
										   	
				    					</a>
				    					<a class="collection-item avatar" v-else style="background-color: #e1e1e1;pointer-events:none;padding-left: 20px;padding-right:75px;">
										   	<span class="title">{{slideIndex+1}}. {{slide._attributes.title}}</span>
										   	<p v-if="slide._attributes.type === 'mp4'">
										   		<i class="tiny material-icons">videocam</i> Video	
										   	</p>
										   	<p v-else-if="slide._attributes.type === 'png'">
										   		<i class="tiny material-icons">photo_camera</i> Picture	
										   	</p>
										   	<p v-else>
										   		<i class="tiny material-icons">videocam</i> Youtube
										   	</p>
				    					</a>
				    				</ul>
				    			</div>
				    		</li>
				    	</ul>
				    </li>
				</ul>
			</div>
			<ul id="slide-out" class="side-nav">
				<li :style="{'background-color': course_data.course_color}">
					<div class="row" style="margin-top:0px;">
						<i v-on:click="closeSideNav" class="material-icons white-text right" style="cursor:pointer;">close</i>
					</div>
					<div class="row center" style="margin-bottom:0px;">
						<img class="responsive-img" v-bind:src="'https://locstatt.net/app/classroomCovers/' + course_data.course_icon" style="width: 130px;height: 130px;margin-top: 10px;">
					</div>
					<div class="row center" style="line-height: 20px; margin-left: 10px; margin-right: 10px;margin-bottom:0px;">
						<span style="font-weight: bold;font-size: 16px;color:white;">{{course_data.course_name}}</span>
						<br>
						<span style="display: block;margin-top: 10px;margin-bottom: 10px;color:white;">Code: {{course_data.course_code}} </span>
					</div>
				</li>
				<li style="border-bottom:1px solid #ebebeb">
					<ul class="collapsible" data-collapsible="expandable">
						<li class="section-title-side-nav" v-for="(section,index) in CourseSlides">
							<div class="collapsible-header blue-grey darken-2 white-text active" style="height:60px;"><span style="line-height: 15px;text-align: center;vertical-align: middle;margin-top: 12px;">{{section.name}}<span></div>
							<div class="collapsible-body">
								<ul class="collection">
									<a class="collection-item avatar" style="padding-left: 20px;padding-right:75px;" v-for="(slide, slideIndex) in section.section_slides" v-if="slide.hasBeenViewed && CourseSlide._attributes.id !== slide._attributes.id" v-on:click="goToSlide" v-bind:data-section="index" v-bind:data-slide="slideIndex" v-bind:current-id="CourseSlide._attributes.id" v-bind:slide-id="slide._attributes.id">
										<i class="material-icons circle" v-bind:data-section="index" v-bind:data-slide="slideIndex" style="background-color:#00CD9F;margin-left:270px;">check</i>
										<span v-bind:data-section="index" v-bind:data-slide="slideIndex" class="title">{{slideIndex+1}}. {{slide._attributes.title}}</span>
										<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-if="slide._attributes.type === 'mp4'">
											<i class="tiny material-icons">videocam</i> Video	
										</p>
										<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else-if="slide._attributes.type === 'png'">
											<i class="tiny material-icons">photo_camera</i> Picture	
										</p>
										<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else>
											<i class="tiny material-icons">videocam</i> Youtube
										</p>
									</a>
									<a class="collection-item avatar" v-else-if="slide.hasBeenViewed || CourseSlide._attributes.id === slide._attributes.id" v-bind:data-section="index" v-bind:data-slide="slideIndex" style="background-color: #00CD9F;padding-left: 20px;padding-right:75px;" v-bind:current-id="CourseSlide._attributes.id" v-bind:slide-id="slide._attributes.id">
										<i class="material-icons circle white" v-bind:data-section="index" v-bind:data-slide="slideIndex" style="color:#00CD9F;margin-left:270px;">check</i>
										<span v-bind:data-section="index" v-bind:data-slide="slideIndex" class="title" style="color:white;">{{slideIndex+1}}. {{slide._attributes.title}}</span>
										
										<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-if="slide._attributes.type === 'mp4'" >
											<i class="tiny material-icons" style="color:white;">videocam</i> <span style="color:white;">Video</span>
											<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #00CD9F;width:65px;" v-if="videoStatus === 0">
												Playing
											</span>
											<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #ffcc00;width:65px;" v-else-if="videoStatus === 1">
												Paused
											</span>	
											<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #990000;width:65px;" v-else-if="videoStatus === 2">
												Stopped
											</span>		
										</p>
										<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else-if="slide._attributes.type === 'png'">
											<i class="tiny material-icons" style="color:white;">photo_camera</i> <span style="color:white;">Picture</span>	
											<span class="chip btn btn-floating pulse" style="width:80px;font-size:10px;background-color: white;color: #00CD9F"> 
												Watching
											</span>	
										</p>
										<p v-bind:data-section="index" v-bind:data-slide="slideIndex" v-else>
											<i class="tiny material-icons" style="color:white;">videocam</i> <span style="color:white;">Youtube</span>
											<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #00CD9F;width:65px;" v-if="videoStatus === 0">
												Playing
											</span>
											<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #ffcc00;width:65px;" v-else-if="videoStatus === 1">
												Paused
											</span>	
											<span class="chip btn btn-floating pulse" style="font-size:10px;background-color: white;color: #990000;width:65px;" v-else-if="videoStatus === 2">
												Stopped
											</span>	
										</p>
										
									</a>
									<a class="collection-item avatar" v-else style="background-color: #e1e1e1;pointer-events:none;padding-left: 20px;padding-right:75px;">
										<span class="title">{{slideIndex+1}}. {{slide._attributes.title}}</span>
										<p v-if="slide._attributes.type === 'mp4'">
											<i class="tiny material-icons">videocam</i> Video	
										</p>
										<p v-else-if="slide._attributes.type === 'png'">
											<i class="tiny material-icons">photo_camera</i> Picture	
										</p>
										<p v-else>
											<i class="tiny material-icons">videocam</i> Youtube
										</p>
									</a>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
			
			
			<div id="modalWarning" class="modal modal-fixed-footer" style="height:200px;">
				<div class="modal-content">
					<h4>Warning</h4>
					<p>You are about to exit this course. Your progress will be saved until the last slide you have reached. Do you want to continue?</p>
				</div>
				<div class="modal-footer">
					<a class="modal-action modal-close waves-effect waves-green btn red ">No</a>
					<a class="modal-action modal-close waves-effect waves-green btn blue" v-on:click="exitCourseFromButton">Yes</a>
				</div>
			</div>

			<div id="modalWarning2" class="modal modal-fixed-footer" style="height:200px;">
				<div class="modal-content" style="padding:0px !important;background-color:#FFB900;color:black;">
					<div>
						<h4 style="line-height: 60px;font-size: 30px;display: block;padding-left: 20px;"><i class="material-icons">warning</i> Warning </h4> </div>
					<p style="padding-left:25px;">You are about to exit this course. You will lose all progress and will have to take the whole course again. Do you want to continue?</p>
				</div>
				<div class="modal-footer">
					<a class="modal-action modal-close waves-effect waves-green btn blue ">No</a>
					<a class="modal-action modal-close waves-effect waves-green btn" v-on:click="exitCourseFromButton" style="background-color: #FF4D4D">Yes</a>
				</div>
			</div>

			<div id="missing-questions-modal" class="modal">
				<div class="modal-content">
					<h4>Warning</h4>
					<p>Some questions remain unanswered! Please answer all questions for this test so that it can be evaluated.</p>
				</div>
				<div class="modal-footer">
					<a class="modal-action modal-close waves-effect waves-green btn blue lighten-1">Got It</a>
				</div>
			</div>

			<div id="submit-test-modal" class="modal">
				<div class="modal-content">
					<h4>Submit Test</h4>
					<div v-if="hasAttempts === 0 && test_type_id === 10000">
						<span>
							Are you sure you want to submit your test? Have you revised all your answers? Once the test has been saved, your score will be set. A failed test may affect your employee training records.
						</span>
					</div>
					<div v-else-if="hasAttempts === 1 && test_type_id === 10000">
						<span>
							Are you sure you want to submit your test? Have you revised all your answers? Once the test has been saved, your score will be set. A failed test may affect your employee training records.
						</span>
						<div style="background-color: #ffea00;text-align:center;border-radius: 20px;">
							<span style="font-size: 12px;">
								This is the second and last time to take this test. if you fail you will need to take the course again
							</span>
						</div>
					</div>
				</div>
				<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn red">No</a>
					<a v-if="test_type_id === 10000" v-on:click="calculateScoreAndSubmit" class="modal-action modal-close waves-effect waves-green btn blue">Yes</a>
					<a v-if="test_type_id === 10001" v-on:click="calculateScoreSlideTest" class="modal-action modal-close waves-effect waves-green btn blue">Yes</a>
				</div>
			</div>

			<div id="close-test-modal" class="modal">
				<div class="modal-content">
					<h4>Close Test</h4>
					<p>Are you sure you want to close the test? You will lose all answered questions and will have to start again from the beginning next time you open the test. Do you want to exit?</p>
				</div>
				<div class="modal-footer">
					<a class="modal-action modal-close waves-effect waves-green btn red">No</a>
					<a v-on:click="closeTestModal" class="modal-action modal-close waves-effect waves-green btn blue">Yes</a>
				</div>
			</div>

			<div id="CourseTest" class="modal modal-fixed-footer" style="height:500px !important;min-height:500px !important;">
				
				<div class="row blue-grey darken-4 white-text" style="height: 40px;position: fixed;width: 100%;"> 
					<h5 class="center" style="margin-bottom: 0px; line-height: 36px; margin-top: 0px;">{{course_data.name}} Tests </h5>
				</div>
				<div class="testTitle" v-if="CourseTest.test_header && !failWarning && !successMessage && hasAttempts < 2 && test_type_id === 10000" style="background-color: rgb(255, 215, 58); height: 50px; margin-top: 40px;position: fixed;marker: ;width: 100%;z-index: 100000000;">
					<h6 style="margin-left:30px;line-height: 20px;">This is the test for course {{course_data.code}}. Please answer all the questions and select “Submit” at the bottom. The minimun score required for this course is {{course_data.passingScore}}%.</h6>
				</div>

				<div class="row" v-if="selectYourTest && !failWarning && !successMessage" style="margin-top:50px;">
					<div v-for="(group, index) in testGroups">
						<div v-for="(test,testIndex) in group.GroupedCourses">
							<div class="col s12 m6 l3 xl3">
								<div class="card-panel white" style="margin-bottom:0px;padding: 0px;height:250px;">
									<div class="test-card-header" style="background-color: rgb(255, 204, 2); height: 65px;text-align: center;padding: 10px;line-height: 15px;font-weight:bold;">
										{{test.TEST_TITLE}}
									</div>

									<div class="test-info" style="padding:15px;">
										<span><i class="tiny material-icons">person</i> {{test.EMP_NAME}} ({{test.EMP_CODE}})</span><br>
										<span><i class="tiny material-icons">date_range</i> {{test.CREATION_DATE}}</span><br>
										<div style="height:70px;overflow-y:scroll;">
											<span><i class="tiny material-icons">description</i> {{test.TEST_DESCRIPTION}}</span>
										</div>
									</div>

									<div class="test-footer right" style="margin-right:10px;">
										<a @click="selectTest" v-bind:data-headerid="test.CLASSROOM_TEST_HEADER_ID" class="btn orange darken-4" style="margin-right: 15px;padding-left: 5px;padding-right: 5px;font-size: 12px;margin-top:-5px;">Take Test</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col s12 center-align">
						<div class="shelf"></div>
					</div>
				</div>


				<div v-if="hasAttempts < 2 && !failWarning && !successMessage && !selectYourTest && test_type_id === 10000" class="modal-content" style="padding:0px;margin-top:90px;position: absolute;height: calc(100% - 144px);max-height: 100%;width: 100%;overflow-y: auto;">
					<loader v-if="Loading"></loader>
					<div class="testsInfo" style="margin-top: -82px;">
						<div>
							<coursetest 
								v-bind:CourseTest="CourseTest" 
								ref="test"
							>
							</coursetest>
						</div>
					</div>
				</div>

				<div v-if="hasAttempts < 2 && !failWarning && !successMessage && !selectYourTest && test_type_id === 10001" class="modal-content" style="padding:0px;margin-top:40px;position: absolute;height: calc(100% - 144px);max-height: 100%;width: 100%;overflow-y: auto;">
					<loader v-if="Loading"></loader>
					<div class="testsInfo" style="margin-top: -90px;">
						<div>
							<coursetest 
								v-bind:CourseTest="CourseTest" 
								ref="test"
							>
							</coursetest>
						</div>
					</div>
				</div>

				<div v-if="hasAttempts < 2 && !failWarning && successMessage && !selectYourTest" class="modal-content" style="padding:0px;">
					<div class="row blue-grey darken-4 white-text" style="height: 40px;position: fixed;width: 100%;z-index: 100000000;"> 
						<h5 class="center" style="margin-bottom: 0px; line-height: 36px; margin-top: 0px;">{{course_data.name}} Tests </h5>
					</div>
					<div class="warning-info">
						<div class="row center">
							<img class="responsive-img" src="images/success.png" style="height: 300px;margin-top: 120px;">
						</div>
						<div class="row center" style="margin-top:30px;">
							<h5 style="margin-left:10%;margin-right:10%;">The Course was updated successfully. You can check the training record in your training history</h5>
						</div>
					</div>
				</div>

				<div v-if="hasAttempts < 2 && !failWarning && successMessage && !selectYourTest" class="modal-footer">
					<a v-on:click="goToTrainingHistory" class="modal-action modal-close white-text btn greend" style="margin-right:10px;">Review My Training History</a>
					<a v-on:click="exitCourseAfterFailOrSuccess" class="modal-action modal-close white-text btn red" style="margin-right:10px;">Exit</a>
				</div>

				<div v-if="hasAttempts < 2 && failWarning && !successMessage && !selectYourTest" class="modal-content" style="padding:0px;">
					<div class="row blue-grey darken-4 white-text" style="height: 40px;position: fixed;width: 100%;z-index: 100000000;"> 
						<h5 class="center" style="margin-bottom: 0px; line-height: 36px; margin-top: 0px;">{{course_data.name}} Tests </h5>
					</div>
					<div class="warning-info">

						<div class="row center">
							<div style="margin-top:100px;border: 5px solid #c62828; border-radius:100px;width:200px;height:200px;margin-left:39%;margin-right:40%;">
								<h1 style="margin-top: 60px;color:#c62828">{{userScore}}%</h1>
							</div>
						</div>
						<div class="row center" style="margin-top:30px;">
							<h5 style="margin-left:10%;margin-right:10%;">You have failed the test. You scored a {{userScore}}% and the minimum is {{course_data.passingScore}}%. You have <b>one</b> more opportunity to take it once again. Would you like to take the test again right now?</h5>
						</div>
					</div>
				</div>

				<div v-if="hasAttempts === 2 && !failWarning && !successMessage && !selectYourTest" class="modal-content" style="padding:0px;">
					<div class="row blue-grey darken-4 white-text" style="height: 40px;position: fixed;width: 100%;z-index: 100000000;"> 
						<h5 class="center" style="margin-bottom: 0px; line-height: 36px; margin-top: 0px;">{{course_data.name}} Tests </h5>
					</div>
					<div class="fail-info">
						<div class="row center">
							<div style="margin-top:100px;border: 5px solid #c62828; border-radius:100px;width:200px;height:200px;margin-left:39%;margin-right:40%;">
								<h1 style="margin-top: 60px;color:#c62828">{{userScore}}%</h1>
							</div>
						</div>
						<div class="row center" style="margin-top:30px;">
							<h5 style="margin-left:10%;margin-right:10%;">You have failed the test. You scored a {{userScore}}% and the minimum is {{course_data.passingScore}}%. You don't have more opportunities to take it again and you must repeat the course. Would you like to start all over right now?</h5>
						</div>
					</div>
				</div>

				<div v-if="selectYourTest && !failWarning && !successMessage" class="modal-footer">
					<a class="modal-action modal-close red btn" style="margin-right:10px;">Close</a>
				</div>

				<div v-if="hasAttempts < 2 && failWarning && !successMessage && !selectYourTest" class="modal-footer">
					<a class="modal-action modal-close amber accent-2 black-text btn" style="margin-right:10px;">No, I want to review the content again</a>
					<a v-on:click="openCourseTests" class="green white-text btn" style="margin-right:10px;">Yes</a>
				</div>

				<div v-if="hasAttempts < 2 && !failWarning && !successMessage && !selectYourTest" class="modal-footer">
					<span class="left" style="font-size:19px;margin-top: 7px;margin-left: 10px;">Answered: {{questionsAnswered}}/{{totalQuestions}}</span>
					<a v-on:click="openCloseWarning" class="red white-text btn" style="margin-right:10px;">Close</a>
					<a v-on:click="checkTest" class="blue lighten-1 white-text btn" style="margin-right:10px;">Submit Test</a>
				</div>

				<div v-if="hasAttempts === 2 && !failWarning && !successMessage && !selectYourTest" class="modal-footer">
					<div v-if="course_token === 'normal-web-course'">
						<a v-on:click="exitCourseAfterFailOrSuccess" class="modal-action modal-close red white-text btn" style="margin-right:10px;">No</a>
						<a v-on:click="startCourseAgain" class="green white-text btn blue lighten-1 modal-action modal-close" style="margin-right:10px;">Yes</a>
					</div>
					<div v-else>
						<a v-on:click="startCourseAgain" class="green white-text btn blue lighten-1 modal-action modal-close" style="margin-right:10px;">Yes</a>
					</div>
					
				</div>
			</div>
		</div>
	</div>`,

	data: function(){
		return {
			courseToken: "",
			currentWindow: null, 
			course_data: {},
			CourseSlides: [],
			CourseSlide: {_attributes:{}},
			currentSlideIndex: 0,
			currentSectionIndex: 0,
			TotalSlides: 0,
			ImagesPath: "",
			VideosPath: "",
			videoDone: false,
			player: null,
			multipleTests: false,
			CourseTest: {},
			userScore: 0,
			allSlidesViewed: false,
			hasAttempts: 0,
			failWarning: false,
			successMessage: false,
			Loading: false,
			multipleTests: false,
			totalQuestions: 0,
			questionsAnswered: 0,
			totalSlidesViewed: 0,
			selectYourTest: false,
			testGroups: [],
			videoStatus: 0, //0 is Playing, 1 is Paused, 2 is Ended
			startingDate: "",
			endingdate: "",
			test_header: {},
			test_sections: {},
			currentSlideHasTest: false,
			test_type_id: 0,
			answers_section_tests: [],
			totalTestQuestions: 0,
			allSlideTestsAnswered: false,
			sortableCache: "",
			vimeoVideoID: 0,
			vimeoHeight: 640,
			playerReady: false
		};
	},

	created: async function(){
	},
	
	mounted: async function(){
		const c = this;
		datepickerFactory($);
		select2($);
		$(window).off('blur');
		$(window).off('focus');
		$(window).off('beforeunload');
		let $el = $(this.$el);
		$el.find('.tooltipped').tooltip();
		$('#modalWarning').modal();
		$('#modalWarning2').modal();
		$('#CourseTest').modal({
			dismissible: false,
			opacity: 0.8,
		});
		$el.find('.button-collapse').sideNav({
			onOpen: function(el){
				$('.collapsible').collapsible();
			},
			onClose: function(el){
			}
		});


		$('#missing-questions-modal').modal();
		$('#submit-test-modal').modal();
		$('#close-test-modal').modal();
		
		
		const id: number = this.$route.params.id;
		this.course_token = this.$route.params.token;

		console.log("COURSE TOKEN: ", this.course_token);
		const detail_id: number = this.$route.params.detailid;
		const openedFromWebApp: boolean = (this.$route.params.openedFromWebApp == "true" || this.$route.params.openedFromWebApp == true) ? true : false;
		this.course_data = (this.course_token === 'normal-web-course') ? c.loadCourseFromWebApp() : await c.loadCourseFromEmail(id,this.course_token,detail_id);
		
		localStorage.setItem("course_data", JSON.stringify(this.course_data));
		this.ImagesPath = "https://classroom.locstatt.net/courseLibrary/" + this.course_data.id + "/img/";
		this.VideosPath = "https://classroom.locstatt.net/courseLibrary/" + this.course_data.id +"/video/";
		this.YoutubePath = "https://www.youtube.com/watch?v=";
		this.testGroups = [];


		if (this.course_token === 'normal-web-course'){
			//Get Exam Structure
			if(this.course_data.firstTimeOpened){
				this.hasAttempts = 0;
				this.getLocation();
			}else{
				if (this.course_data.count_tests){
					this.getExamFromServer();
				}else{
					this.test_type_id = 10000;
					this.getXMLContentFromServer();
				}
			}
		}else{
			//We need to grab now the exam data, if the course has a test, to see what modal to display
			if (this.course_data.count_tests){
				//Course has at least one exam
				const courseTestsResult = await axios.get('https://locstatt.net/app/cfcChevron/classroom.cfc', { params: {method: 'getClassroomTestsByTrainingId', training_code_id: this.course_data.id} });
				const classroom_test_header_id = courseTestsResult.data[0].CLASSROOM_TEST_HEADER_ID;
				const completeTestResult = await axios.get('https://locstatt.net/app/cfcChevron/classroom.cfc', { params: {method: 'getCompleteTestByTestHeaderIdWebApp', CLASSROOM_TEST_HEADER_ID: classroom_test_header_id}});
				const test_header = completeTestResult.data.test_header;
				const test_sections = completeTestResult.data.test_ections;
				this.test_type_id = test_header[0].CLASSROOM_TEST_TYPE_ID;
				this.test_header = test_header;
				this.test_sections = test_sections;

				if (!this.course_data.start_date && !this.course_data.end_date){
					if (this.test_type_id === 10000){
						if(!openedFromWebApp){
							swal.fire({
								title: 'Are you sure you want to take this course?',
								text: "You are about to take a course that has a test!",
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: 'Yes',
								cancelButtonText: 'No',
								reverseButtons: true
							}).then(async function(result){
								if (result.isConfirmed) {
									//Change Course Status
									const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 1}})
									c.setCourseAsInProgressForFirstTime(0.0,0.0);
									//this.getXMLContentFromServer();
								} else if (result.dismiss === swal.DismissReason.cancel) {
									//close browser windo
									this.currentWindow.close();
								}
							})
						}else{
							const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 1}})
							c.setCourseAsInProgressForFirstTime(0.0,0.0);
						}
					}else{
						if(!openedFromWebApp){
							swal.fire({
								title: 'Are you sure you want to take this course?',
								text: "You are about to take a course that has a sectional test!",
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: 'Yes',
								cancelButtonText: 'No',
								reverseButtons: true
							}).then(async function(result){
								if (result.isConfirmed) {
									const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 1}})
									c.setCourseAsInProgressForFirstTime(0.0,0.0);
									//this.getXMLContentFromServer();
								} else if (result.dismiss === swal.DismissReason.cancel) {
									//close browser windo
									this.currentWindow.close();
								}
							})
						}else{
							const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 1}})
							c.setCourseAsInProgressForFirstTime(0.0,0.0);
						}
					}

				}else if (this.course_data.start_date && !this.course_data.end_date){
					if (this.course_data.count_tests){
						this.getExamFromServer();
					}else{
						this.test_type_id = 10000;
						this.getXMLContentFromServer();
					}
				} else if (this.course_data.start_date && this.course_data.end_date){
					swal.fire({
						icon: 'error',
						title: 'Course Ended',
						text: 'The link has expired because the course was taken or cancelled by the supervisor!',
					}).then(function(result){
						if (result.isConfirmed){
							window.location.href = 'https://classroom.locstatt.net/';
						}
					});
				}
			}else{
				if (!this.course_data.start_date && !this.course_data.end_date){
					//Course for the first time
					//show message that you are about to start a course without a test
					if(!openedFromWebApp){
						swal.fire({
							title: 'Are you sure you want to take this course?',
							text: "You won't be able to revert this!",
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: 'Yes',
							cancelButtonText: 'No',
							reverseButtons: true
						}).then(async function(result){
							if (result.isConfirmed) {
								const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 1}})
								c.setCourseAsInProgressForFirstTime(0.0,0.0);
							} else if (result.dismiss === swal.DismissReason.cancel) {
								//close browser windo
								this.currentWindow.close();
							}
						});
					}else{
						const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 1}})
						c.setCourseAsInProgressForFirstTime(0.0,0.0);
					}
				}else if (this.course_data.start_date && !this.course_data.end_date){
					c.test_type_id = 10000;
					c.getXMLContentFromServer();
				} else if (this.course_data.start_date && this.course_data.end_date){
					//Course Ended
				}
			}
		}
	},

	computed: {
		player: function(){
	      return this.$refs.youtube.player;
	    }
	},

	methods: {
		loadCourseFromWebApp: function(){
			//Get data if it returns null, check if next parameter is null (color in this case), as the id parameter will always have data
			if(this.$route.params.course_color){
				return {...this.$route.params, lastSlide: this.$route.params.lastSlide ? this.$route.params.lastSlide : 0};
			}
			return JSON.parse(localStorage.getItem("course_data"));			
		},

		loadCourseFromEmail: function(id,token, detail_id){
			const c = this;
			return new Promise(async function(resolve){
				//Course comes directly from an email
				//get info from server
				const courseInfoData = await axios.get('https://locstatt.net/app/cfcChevron/scheduled-training-emails.cfc', { params: {method: 'getCourseTokenStatusAndInfo', classroom_token: token, scheduled_training_detail_id: detail_id} });
				//Check for the logged user
				if(courseInfoData.data.length){
					const data = courseInfoData.data[0];
					//Check if method returns data
					if (Object.keys(data).length === 0){
						alert(" The link is not available or there is no training associated to the link")
					}else{
						//If data is returned, check for user data
						const logged_user = c.$store.state.UserData.EMP_CODE;
						const user_course = data.EMP_CODE;
						var course_data = {};

						if(logged_user != user_course){
							swal.fire({
								title: 'Login Form',
								html: `	<input type="text" id="login" class="swal2-input" placeholder="Username">
										<input type="password" id="password" class="swal2-input" placeholder="Password">`,
								confirmButtonText: 'Sign in',
								focusConfirm: false,
								preConfirm: async () => {
									const login = swal.getPopup().querySelector('#login').value
									const password = swal.getPopup().querySelector('#password').value	
									if (!login || !password) {
										swal.showValidationMessage(`Please enter login and password`)
									}		
									if (login != user_course){
										swal.showValidationMessage(`You do not have access to this course. Please login with the correct credentials!`);
									}else{
										const loginResult = await axios.get('https://locstatt.net/app/cfcChevron/classroom.cfc', {params:{method:"getClassroomWebAccess",username: login, password: password}});
										c.$store.dispatch("setUserData", loginResult.data.USER_INFORMATION[0]);
										course_data = {
											id,
											course_color: data.COVER_COLOR,
											course_icon: data.COVER_IMAGE_NAME,
											course_name: data.TRAINING_NAME,
											course_code: data.TRAINING_CODE,
											firstTimeOpened: data.START_DATE ? false : true,
											count_tests: data.COUNT_TESTS || data.CPUNT_TESTS,
											header_id: data.TRAINING_ONLINE_HEADER_ID,
											lastSlide: data.LAST_SLIDE ? data.LAST_SLIDE : 0,
											passingScore: data.TRAINING_SCORE,
											recurrance_id: data.TRAINING_RECURRANCE_ID,
											valid_for: data.TRAINING_VALID_FOR,
											start_date: data.START_DATE,
											end_date: data.END_DATE
										}
										resolve(course_data);
									}
								}
							});	
						}else{
							course_data = {
								id,
								course_color: data.COVER_COLOR,
								course_icon: data.COVER_IMAGE_NAME,
								course_name: data.TRAINING_NAME,
								course_code: data.TRAINING_CODE,
								firstTimeOpened: data.START_DATE ? false : true,
								count_tests: data.COUNT_TESTS || data.CPUNT_TESTS,
								header_id: data.TRAINING_ONLINE_HEADER_ID,
								lastSlide: data.LAST_SLIDE ? data.LAST_SLIDE : 0,
								passingScore: data.TRAINING_SCORE,
								recurrance_id: data.TRAINING_RECURRANCE_ID,
								valid_for: data.TRAINING_VALID_FOR,
								start_date: data.START_DATE,
								end_date: data.END_DATE
							}
							resolve(course_data);
						}
					}
				}else{
					swal.fire({
						title: "Course Cancelled",
						text: "This link is not available anymore. The scheduled training has been cancelled by the supervisor.",
						icon: "error",
						showDenyButton: false,
						showCancelButton: false,
						confirmButtonText: 'Understood',
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							window.location.href = 'https://classroom.locstatt.net/';
						} 
					});
				}
			});
		},

		vimeoReady: function(){
			this.playerReady = true;
		},

		setQuestionAsAnswered: function(index, questionIndex){
            var section = this.CourseTest.test_sections[index];
            var question = this.CourseTest.test_sections[index].QUESTIONS[questionIndex];
            if !(question.ANSWERED){
                question.ANSWERED = true;
                section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) + 1;
                this.questionsAnswered = this.questionsAnswered + 1;
            }

            if (question.QUESTION_TYPE_ID === 10422 || question.QUESTION_TYPE_ID === 12110){
                var checked = $(`input.question-${index}-${questionIndex}:checked`);
                if(checked.length === 0){
                    if (question.ANSWERED){
                        question.ANSWERED = false;
                        section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
                        this.questionsAnswered = this.questionsAnswered - 1;
                    }
                }
            }

            //Barcode
            if(question.QUESTION_TYPE_ID === 11688){
                if ($(`#barcode-question-${index}-${questionIndex}`).val().length === 0){
                    if (question.ANSWERED){
                        question.ANSWERED = false;
                        section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
                        this.questionsAnswered = this.questionsAnswered - 1;
                    }

                }
            }

            //Integer
            if(question.QUESTION_TYPE_ID === 10211){
                if ($(`#integer-question-${index}-${questionIndex}`).val().length === 0){
                    if (question.ANSWERED){
                        question.ANSWERED = false;
                        section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
                        this.questionsAnswered = this.questionsAnswered - 1;
                    }

                }
            }

            //Short Answer
            if(question.QUESTION_TYPE_ID === 10000 || question.QUESTION_TYPE_ID === 11899){
                if ($(`#short-answer-question-${index}-${questionIndex}`).val().length === 0){
                    if (question.ANSWERED){
                        question.ANSWERED = false;
                        section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
                        this.questionsAnswered = this.questionsAnswered - 1
                    }

                }
            }
        },

        selectTest: function(event){
        	this.selectYourTest = false;
        	var headerID = parseInt(event.target.dataset.headerid);
        	this.getNumberOfTimesTestHasBeenTaken(headerID);
		},
		
		openSlideTest: function(el,ev){
			var test_object = {
				test_header: [this.test_header[0]],
				test_sections: [{
					SECTION_NAME: this.CourseSlide.SECTION_NAME,
					QUESTIONS: this.CourseSlide.QUESTIONS,
					EMP_NAME: this.CourseSlide.EMP_NAME,
					HAS_SCORE: this.CourseSlide.HAS_SCORE,
					CLASSROOM_TEST_SECTION_ID: this.CourseSlide.CLASSROOM_TEST_SECTION_ID,
					SECTION_ORDER: this.CourseSlide.SECTION_ORDER,
					SECTION_DESCRIPTION: this.CourseSlide.SECTION_DESCRIPTION,
					CREATION_DATE: this.CourseSlide.CREATION_DATE,
					EMP_CODE: this.CourseSlide.EMP_CODE,
					CLASSROOM_TEST_HEADER_ID: this.CourseSlide.CLASSROOM_TEST_HEADER_ID
				}]
			}

			if (this.CourseSlide.HAS_EXAM){
				this.openModalWithCompleteTest(test_object);
			}
			
		},

		openCourseTests: function(){
			this.Loading = true;
			this.CourseTest = {};
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: "getClassroomTestsByTrainingId",
					training_code_id: this.course_data.id,
				}
			}).done( (data) => {
				if (data.length > 1){
					var groupedTests = this.divideListIntoGroups(data,4);
					this.testGroups = groupedTests;
					this.selectYourTest = true;
				}else if(data.length === 1){
					this.getNumberOfTimesTestHasBeenTaken(data[0].CLASSROOM_TEST_HEADER_ID);
				}
				$('#CourseTest').modal('open');
			}).fail((status, errorDesc) => {
				
			}).always(() =>{
				this.Loading = false;
			});
		},

		getNumberOfTimesTestHasBeenTaken: function(classroom_header_id){
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: "getEmployeeOnlineCourseStatus",
					training_online_header_id: this.course_data.header_id,
				}
			}).done((data) => { 
				if !(data.length){
					this.loadCourseTest(classroom_header_id);
					this.hasAttempts = 0;
				}
				if(data.length === 1){
					if(this.failWarning){
						this.failWarning = false;
					}
					this.loadCourseTest(classroom_header_id);
					this.hasAttempts = 1;
				}
				if(data.length === 2){
					this.failCourse();
					$('#CourseTest').modal('open');
					this.hasAttempts = 2;
				}
			}).fail((status, errorDesc) => {
				
			}).always(() => {
				this.Loading = false;
			});
		},


		divideListIntoGroups: function(list, groupSize){
			var size = groupSize;
			var groups = Math.ceil(list.length/size);
			let resultData: Array<object> = [];
			var ec = 0;

			for(var i = 0; i < groups; i++){
				var newG:any = {};
				let courses: Array<object> = []
				for (var j = i*size; j < (i+1)*size; j++){
					courses.push(list[j])
					ec++;
					if (ec === list.length){
						break;
					}
				}
				newG.GroupedCourses = courses;
				resultData.push(newG);
			}
			return resultData
		},

		loadCourseTest: function(headerID){
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: "getCompleteTestByTestHeaderIdWebApp",
					CLASSROOM_TEST_HEADER_ID: headerID,
				}
			}).done( (data) => {
				this.openModalWithCompleteTest(data);
			}).fail((status, errorDesc) => {
				
			}).always(() =>{
				this.Loading = false;
			});
		},

		openModalWithCompleteTest: function(data){
			this.startingDate = this.getTodaysDate();
			this.totalQuestions = 0;
			this.questionsAnswered = 0;			
			if(this.player !== null){
				this.pauseVideo();
			}
			$('#CourseTest').modal('open');
			$(".sortable-lists").empty();
			setTimeout(() => {
					$('.collapsible').collapsible();
					for(var sectionIndex = 0; sectionIndex < data.test_sections.length; sectionIndex++){
						Vue.set(data.test_sections[sectionIndex], 'QUESTIONS_ANSWERED', 0);
						for(var questionIndex = 0; questionIndex < data.test_sections[sectionIndex].QUESTIONS.length; questionIndex++){
							this.totalQuestions += 1;
							var typeID = data.test_sections[sectionIndex].QUESTIONS[questionIndex].QUESTION_TYPE_ID;
							var structure = data.test_sections[sectionIndex].QUESTIONS[questionIndex].QUESTION_STRUCTURE;

							if (this.test_type_id === 10000){
								Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex], "section_index", sectionIndex);
								Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex], "question_index", questionIndex);
							}
							
							Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex], 'ANSWERED', false);
							if (typeID === 10844 || typeID === 10633 || typeID === 10422 || typeID === 12110 || typeID === 12532){
								var structure = JSON.parse(structure)
								structure = JSON.parse(structure);
								var choices = structure[0].CHOICES;
								if (typeID === 12532){
									for (var i = choices.length -1; i > 0; i--){
										var j = Math.floor(Math.random() * (i+1));
										var temp = choices[i];
										choices[i] = choices[j];
										choices[j] = temp;
									}
								}
								structure.CHOICES = choices;
								Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex],'STRUCTURE_PARSED', structure);
							}
							if(typeID === 11477){
								var struct = JSON.parse(data.test_sections[sectionIndex].QUESTIONS[questionIndex].QUESTION_STRUCTURE);
								struct = JSON.parse(struct);
								Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex],'STRUCTURE_PARSED', struct[0]);
							}
						}
					}
			},100);

			this.CourseTest = data;
			setTimeout(() => {
					for(var sIndex in this.CourseTest.test_sections){
						for(var qIndex in this.CourseTest.test_sections[sIndex].QUESTIONS){
							var typeID = this.CourseTest.test_sections[sIndex].QUESTIONS[qIndex].QUESTION_TYPE_ID;
							if(typeID === 12532){
								var id1 = "#matching-question-"+sIndex+"-"+qIndex+"-1";
								var id2 = "#matching-question-"+sIndex+"-"+qIndex+"-2";
								var connect = ".connect-lists-"+sIndex+"-"+qIndex;
								$(id1).sortable({
						      		connectWith: connect,
						      		remove: (event, ui) => {
						      			var sectionIndex = ui.item.attr('data-sindex');
						      			var questionIndex = ui.item.attr('data-qindex');
						      			var section = this.CourseTest.test_sections[sectionIndex];
						      			var question = section.QUESTIONS[questionIndex];
						      			var structure = question.QUESTION_STRUCTURE;
						      			structure = JSON.parse(structure);
						      			structure = JSON.parse(structure);
						      			var choices = structure[0].CHOICES;

						      			if ($(id1)[0].childNodes.length < choices.length){
						      				if(question.ANSWERED){
						      					question.ANSWERED = false;
						      					section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
						      					this.questionsAnswered = this.questionsAnswered - 1;
						      				}
						      			}
						      		}
								}).disableSelection();
								if (this.sortableCache === ""){
									$(id2).sortable({
										connectWith: connect,
										remove: (event, ui) => {
											var sectionIndex = ui.item.attr('data-sindex');
											var questionIndex = ui.item.attr('data-qindex');
  
											var section = this.CourseTest.test_sections[sectionIndex];
											var question = section.QUESTIONS[questionIndex];
											if ($(id2)[0].childNodes.length === 0){
												if !(question.ANSWERED){
													question.ANSWERED = true;
													section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) + 1;
													this.questionsAnswered = this.questionsAnswered + 1;
												
												}
											}
											
										}
									}).disableSelection();
									this.sortableCache = $(id2).html();
								}else{
									$(id2).html(this.sortableCache).sortable("refresh");
								}
							}
						}
					}
					$('.datepick').datepicker({
						onSelect: function(text, ui){
							
						}
					});
			},500);
		},

		exitCourseAfterFailOrSuccess: function(){
			this.$router.push({ 
            	name: `classrooms`,
            	params: {
            		fromCourse: true
            	}
           	});
		},

		getTotalScoreFromTest: function(){
			var sections = this.CourseTest.test_sections;
			var totalScore = 0;
			for(var sectionIndex in sections){
				var section = sections[sectionIndex];
				var questions = section.QUESTIONS;
				for (var qIndex in questions){
					var question = questions[qIndex];
					var typeID = question.QUESTION_TYPE_ID;
					switch(typeID){
						case 10422: //Multiple Choice
						case 12110: //Multiple Choice
						case 10633: // Yes No Or Single Choice
						case 10844: //Yes No Or Single Choice
						case 12532: //Matching 
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var choices = structure[0].CHOICES;
							
							for(var choiceIndex in choices){
								var choice = choices[choiceIndex];
								totalScore += parseInt(choice.SCORE);
							}
							break;
						default: 
					}
				}
				
			}
			return totalScore;
		},

		calculateScoreSlideTest: function(){
			

			var totalScore = 0;
			var userScore = 0;
			//First we calculate the total score for the questions
			var questions = this.CourseSlide.QUESTIONS;

			for(var i = 0; i < questions.length; i++){
				var structure = questions[i].STRUCTURE_PARSED;
				var type_id = questions[i].QUESTION_TYPE_ID;
				if (type_id === 10633){ //Single Choice
					//single choice have different choices
					for(var j = 0; j < structure.CHOICES.length; j++){
						var score = structure.CHOICES[j].SCORE;
						if (score > 0){
							totalScore += score;
							break;
						}
					}
				}

				if(type_id === 12532){ //Matching
					
					

					//check if the matching question has global score
					if(structure[0].HAS_GLOBAL_SCORE) {
						totalScore += parseInt(structure[0].GLOBAL_SCORE);
					}

				}
			}
			
			//Then we calculate the score based on selected answers
			for(var i = 0; i < questions.length; i++){
				var structure = questions[i].STRUCTURE_PARSED;
				var type_id = questions[i].QUESTION_TYPE_ID;

				if (type_id === 10633){ //Single Choice
					var choiceIndex = parseInt($(`input[name=question-0-${i}]:checked`).attr("data-choiceindex"));
					//single choice have different choices
					var choices = structure.CHOICES;
					var choice_score = "";
					if(!isNaN(choiceIndex)){
						if (choices[choiceIndex].SCORE !== undefined){
							choice_score = parseInt(choices[choiceIndex].SCORE);
							userScore += choice_score;
						}
					}
				}

				if(type_id === 12532){
					
					var structure = questions[i].QUESTION_STRUCTURE;
					var structure2 = questions[i].STRUCTURE_PARSED;
					structure = JSON.parse(structure);
					structure = JSON.parse(structure);
					var answers_array = [];
					var originalChoices = structure[0].CHOICES;
					var choicesSelected = []
					var choicesSelectedDOM = $(`#matching-question-${0}-${i}-1`).children().toArray();
					var allValid = true;
					for(var index in choicesSelectedDOM){
						
						
						if (!(originalChoices[index].VAL === $(choicesSelectedDOM[index]).attr("data-answer"))){
							allValid = false;
							break;
						}
					}

					//check if question has global score*/
					if (structure2[0].HAS_GLOBAL_SCORE){
						//Now check if all choices are in the correct order, if 1 fails, then the test has failed
						if (allValid){
							userScore += parseInt(structure2[0].GLOBAL_SCORE);
						}
					}
				}
				
			}

			if (userScore === totalScore){
				swal.fire({
					title: "Great!",
					text: "Great! You have answered all the questions of this section.",
					icon: "success"
				});
				$(".next_slide").removeClass('disabled');
				//store the questions and answers in an array for them to be sent later
				this.CourseSlide.EXAM_PASSED = true;
				if(this.CourseSlide._attributes.type === "mp4"){
					this.CourseSlide.hasBeenViewed = true;
				}

				for (var i = 0; i < this.CourseSlide.QUESTIONS.length; i++){
					var question = this.CourseSlide.QUESTIONS[i];
					var typeId = question.QUESTION_TYPE_ID;
					switch(typeId){
						case 10422: // Multiple Choice
						case 12110: //Multiplce Choice
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var choices = structure[0].CHOICES;
							var order = 0;
							$(`input:checkbox[name=question-${0}-${i}]:checked`).each(function(){
								var choiceIndex = parseInt($(this).attr("data-choiceindex"));
								var choice_score = "";
								if (!isNaN(choiceIndex)){
									
									this.answers_section_tests.push(
										{
											"value": choices[choiceIndex].VAL,
											"score": choice_score,
											"order": order,
											"index": parseInt(choices[choiceIndex].I),
											"sectionIndex": this.CourseSlide.SECTION_ORDER,
											"questiontype": typeId
										}
									);
								}
								order++;
							});
							//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(choices));
							break;
						case 10633: //Yes No or Single Choice
						case 10844: //Yes No or Single Choice
							var choiceIndex = parseInt($(`input[name=question-${0}-${i}]:checked`).attr("data-choiceindex"));
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var choices = structure[0].CHOICES;
	
							var choice_score = "";
							if (!isNaN(choiceIndex)){
								this.answers_section_tests.push(
									{
										"value": choices[choiceIndex].VAL,
										"score": choice_score,
										"order": 0,
										"index": parseInt(choices[choiceIndex].I),
										"sectionIndex": this.CourseSlide.SECTION_ORDER,
										"questiontype": typeId
									}
								);
								//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(choices));
							}
							break;
						case 12532: //Matching Questions
							
							var userChoices = [];
							var structure = questions[i].QUESTION_STRUCTURE;
							var structure2 = questions[i].STRUCTURE_PARSED;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var answers_array = [];
							var originalChoices = structure[0].CHOICES;
							var choicesSelected = []
							var choicesSelectedDOM = $(`#matching-question-${0}-${i}-1`).children().toArray();
							for(var index in choicesSelectedDOM){
								var choice_index = parseInt($(choicesSelectedDOM[index]).attr("data-choiceindex"));
								var original_index = parseInt($(choicesSelectedDOM[index]).attr("data-originalindex"));
								var choice_object = {
									"value": $(choicesSelectedDOM[index]).attr("data-answer"),
									"score": parseInt(originalChoices[index].SCORE),
									"order": parseInt(original_index),
									"index": parseInt(index),
								};
								userChoices.push(choice_object);
							}

							this.answers_section_tests.push(
								{
									"value": userChoices,
									"score": "",
									"order": 0,
									"index": 0,
									"sectionIndex": this.CourseSlide.SECTION_ORDER,
									"questiontype": typeId
								}
							);

							break;
						default:
					}
				}

				//Check if all questions have been answered

				if (this.totalTestQuestions === this.answers_section_tests.length){
					this.allSlideTestsAnswered = true;
				}else{
					this.nextSlide();
				}

				this.closeTestModal();

			}else if (userScore < totalScore){
				
				this.closeTestModal();
				swal.fire({
					title: "Test Failed",
					text: "You have fail this section's test. Please review the content and retry the section test to continue.",
					icon: "error"
				}).then((result) => {
					console.log(this.CourseSlide.LAST_SLIDE_WITH_EXAM);
					if (this.CourseSlide.LAST_SLIDE_WITH_EXAM !== 0){
						if(!(this.CourseSlide.LAST_SLIDE_WITH_EXAM === this.currentSlideIndex-1)){
							this.currentSlideHasTest = false;
						}
					}
					$(':radio').prop('checked', false);
					if(this.CourseSlide.LAST_SLIDE_WITH_EXAM === 0){
						this.goToStartOfSectionAfterFailing(0);
					}else{
						this.goToStartOfSectionAfterFailing(this.CourseSlide.LAST_SLIDE_WITH_EXAM);
					}
				});
			}else if (userScore > totalScore){
				
			}
		},

		calculateScoreAndSubmit: function(){
				this.Loading = true;
				var maximumTestScore = parseInt(this.getTotalScoreFromTest()); // This calculates the total maximum score
				var sections = this.CourseTest.test_sections
				var totalScore = 0;
				var test_array = [];
				if (this.course_data.passingScore !== 0){
					for(var sectionIndex in sections){
						var section = sections[sectionIndex];
						var questions = section.QUESTIONS;
						var questions_array = [];
						for (var questionIndex in questions){
							var answers_array = [];
							var question = questions[questionIndex];
							var typeId = question.QUESTION_TYPE_ID;
							switch(typeId){
								case 10000: //Short Answer
								case 11899: //Short Answer
									//var structure = question.QUESTION_STRUCTURE;
									//structure = JSON.parse(structure);
									//structure = JSON.parse(structure);
									var answer_object = {
										"value": $(`#short-answer-question-${sectionIndex}-${questionIndex}`).val(),
										"score": "",
										"order": 0,
										"index": 0
									}
									answers_array.push(answer_object);
									//structure[0].USER_ANSWER = $(`#short-answer-question-${sectionIndex}-${questionIndex}`).val();
									//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(structure));
									break;
								case 10211: //Integer

									var answer_object = {
										"value": $(`#integer-question-${sectionIndex}-${questionIndex}`).val(),
										"score": "",
										"order": 0,
										"index": 0
									}

									answers_array.push(answer_object);
									break;
								case 10422: // Multiple Choice
								case 12110: //Multiplce Choice
									var structure = question.QUESTION_STRUCTURE;
									structure = JSON.parse(structure);
									structure = JSON.parse(structure);
									var choices = structure[0].CHOICES;
									var order = 0;
									$(`input:checkbox[name=question-${sectionIndex}-${questionIndex}]:checked`).each(function(){
										var choiceIndex = parseInt($(this).attr("data-choiceindex"));
										var choice_score = "";
										if (!isNaN(choiceIndex)){
											if (choices[choiceIndex].SCORE !== undefined){
												choice_score = parseInt(choices[choiceIndex].SCORE);
												totalScore += (parseInt(choices[choiceIndex].SCORE)/maximumTestScore)*100;
											}else{
												choice_score = "";
											}
											answers_array.push(
												{
													"value": choices[choiceIndex].VAL,
													"score": choice_score,
													"order": order,
													"index": parseInt(choices[choiceIndex].I)
												}
											);
										}
										order++;
									});
									//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(choices));
									break;
								case 10633: //Yes No or Single Choice
								case 10844: //Yes No or Single Choice
									var choiceIndex = parseInt($(`input[name=question-${sectionIndex}-${questionIndex}]:checked`).attr("data-choiceindex"));
									var structure = question.QUESTION_STRUCTURE;
									structure = JSON.parse(structure);
									structure = JSON.parse(structure);
									var choices = structure[0].CHOICES;

									var choice_score = "";
									if (!isNaN(choiceIndex)){
										if (choices[choiceIndex].SCORE !== undefined){
											choice_score = parseInt(choices[choiceIndex].SCORE);
											totalScore += (parseInt(choices[choiceIndex].SCORE)/maximumTestScore)*100;
											//choices[choiceIndex].USER_SELECTED = true;
										}else{
											choice_score = "";
										}

										answers_array.push(
											{
												"value": choices[choiceIndex].VAL,
												"score": choice_score,
												"order": 0,
												"index": parseInt(choices[choiceIndex].I)
											}
										);
										//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(choices));
									}
									break;
								case 11055: //Enumeration
								case 12321: //Enumeration
									break;
								case 11477: //Date
									var structure = question.QUESTION_STRUCTURE;
									structure = JSON.parse(structure);
									structure = JSON.parse(structure);

									if(structure[0].IS_RANGE){
										structure[0].USER_ANSWER_MIN_DATE = $(`#start-date-range-question-${sectionIndex}-${questionIndex}`).val();
										structure[0].USER_ANSWER_MAX_DATE = $(`#end-date-range-question-${sectionIndex}-${questionIndex}`).val();
									}else{
										structure[0].USER_ANSWER_DATE = $(`#date-question-${sectionIndex}-${questionIndex}`).val()
									}

									question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(structure));

									break;
								case 11688: //Barcode
									var structure = question.QUESTION_STRUCTURE;
									structure = JSON.parse(structure);
									structure = JSON.parse(structure);
									structure[0].USER_ANSWER = $(`#barcode-question-${sectionIndex}-${questionIndex}`).val();
									question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(structure));

									break;
								case 12532:
									
									var structure = question.QUESTION_STRUCTURE;
									structure = JSON.parse(structure);
									structure = JSON.parse(structure);
									var originalChoices = structure[0].CHOICES;
									var choicesSelected = []
									var choicesSelectedDOM = $(`#matching-question-${sectionIndex}-${questionIndex}-1`).children().toArray();
									for(var index in choicesSelectedDOM){
										var choice_index = parseInt($(choicesSelectedDOM[index]).attr("data-choiceindex"));
										var original_index = parseInt($(choicesSelectedDOM[index]).attr("data-originalindex"));
										var choice_object = {
											"value": $(choicesSelectedDOM[index]).attr("data-answer"),
											"score": parseInt(originalChoices[index].SCORE),
											"order": parseInt(original_index),
											"index": parseInt(index)
										};
										answers_array.push(choice_object);
										choicesSelected.push(originalChoices[choice_index]);
									}
									if (structure[0].GLOBAL_SCORE !== 0){
										var allValid = true;
										if (choicesSelected.length){
											for(index in choicesSelected){
												if !(JSON.stringify(choicesSelected[index]) === JSON.stringify(originalChoices[index]))){
													allValid = false;
													break;
												}
											}
											if (allValid){
												totalScore += (parseInt(structure[0].GLOBAL_SCORE)/maximumTestScore)*100;
											} else {
												totalScore += 0;
											}
										}else{
											totalScore += 0;
										}
										
									}else{
										for(index in choicesSelected){
											if (JSON.stringify(choicesSelected[index]) === JSON.stringify(originalChoices[index])){
												totalScore += (parseInt(choicesSelected[index].SCORE)/maximumTestScore)*100;
											}
										}

									}
									var selected = {CHOICES:choicesSelected};
									question.USER_INPUT_STRUCTURE = JSON.stringify(JSON.stringify(selected));
									break;
								default:
							}

							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);

							var isDefault = false;
				            var globalScore = 0;
				            var hasGlobalScore = false;
				            var hasScore = false;
							var isScorable = false;
							
							if(structure[0].DEFAULT){
								isDefault = true;
							}

							if(structure[0].GLOBAL_SCORE){
								globalScore = structure[0].GLOBAL_SCORE
							}

							if(structure[0].HAS_GLOBAL_SCORE){
								hasGlobalScore = true;
							}

							if(structure[0].HAS_SCORE){
								hasScore = true;
							}

							if(structure[0].SCORABLE){
								isScorable = true;
							}

							questions_array.push(
								{	
									"structure": {
										"answers": answers_array,
										"isDefault": isDefault,
							            "globalScore": globalScore,
							            "hasGlobalScore": hasGlobalScore,
							            "hasScore": hasScore,
							            "isScorable": isScorable
									},
									"required": question.REQUIRED,
							        "id": question.CLASSROOM_TEST_QUESTION_ID,
							        "type": question.QUESTION_TYPE_ID,
							        "order": question.QUESTION_ORDER,
							        "sectionId": question.CLASSROOM_TEST_SECTION_ID
								}
							);
						}

						test_array.push(
							{
								"id": section.CLASSROOM_TEST_SECTION_ID,
								"questions": questions_array,
								"hasScore": section.HAS_SCORE,
								"order": section.SECTION_ORDER
							}
						);
					}

					var complete_structure = {
						"id": this.CourseTest.test_header[0].CLASSROOM_TEST_HEADER_ID,
						"training_code": this.course_data.id,
						"sections": test_array
					}

					totalScore = Math.round(totalScore);
					this.userScore = totalScore;
					var hasPassed = 0;
					if !(totalScore < this.course_data.passingScore || totalScore === 0){
						hasPassed = 1;
					}
					$.ajax({
						url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
						method: 'POST',
						data: {
							method: "setNewEmployeeOnlineTestInfo",
							training_online_header_id: this.course_data.header_id,
							test_answer_structure: JSON.stringify(complete_structure),
							score: totalScore,
							creation_date: this.startingDate,
							end_date: this.getTodaysDate(), 
							approved: hasPassed
						}
					}).done( (data) => {
						if (hasPassed){
							this.approveCourse(totalScore);
						}else{
							if(this.hasAttempts === 0){
								this.failWarning = true;
							}
							if(this.hasAttempts  === 1){
								this.failCourse();
							}
							this.hasAttempts++;
						}
					}).fail((status, errorDesc) => {
						
					}).always(() =>{
						this.Loading = false;
					});
				} else {
					$.ajax({
						url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
						method: 'POST',
						data: {
							method: "setNewEmployeeOnlineTestInfo",
							training_online_header_id: this.course_data.header_id,
							test_answer_structure: "",
							score: 100,
							creation_date: this.startingDate,
							end_date: this.getTodaysDate(), 
							approved: 1
						}
					}).done( (data) => {
						this.approveCourse(100);
					}).fail((status, errorDesc) => {
						
					}).always(() => {
						this.Loading = false;
					});
				}
		},

		getTodaysDate: function(){
			var today = new Date();
			var dd = today.getDate().toString();
			var mm = (1 + today.getMonth()).toString(); //January is 0!
			var yyyy = today.getFullYear();

			var hours = today.getHours();
			var minutes = today.getMinutes().toString();
			minutes = minutes.length > 1 ? minutes : '0' + minutes;
			var seconds = today.getSeconds().toString();
			seconds = seconds.length > 1 ? seconds : '0' + seconds;
			var milliSeconds = today.getMilliseconds();

			if(dd<10) {
			    dd = '0'+dd
			} 
			if(mm<10) {
			    mm = '0'+mm
			} 
			today = mm + '/' + dd + '/' + yyyy + ' ' + hours + ':' + minutes + ':' + seconds;
			return today;
		},

		approveSlideTestCourse: function(){
			var sections_array = [];
			var section = [];
			var section_index = -1;
			for(var i = 0; i < this.answers_section_tests.length; i++){
				//put first question in the section;
				if(i === 0){
					section_index = this.answers_section_tests[i].sectionIndex;
					section.push(this.answers_section_tests[i]);
				}else if(i > 0 && i < this.answers_section_tests.length-1 ){
					//check if last question is the same section as the current one
					if (this.answers_section_tests[i].sectionIndex === section_index){
						section.push(this.answers_section_tests[i]);
					}else{
						//here the section changes
						sections_array.push(section);
						section = [];
						section_index = this.answers_section_tests[i].sectionIndex;
						section.push(this.answers_section_tests[i]);
					}
				}else if(i === this.answers_section_tests.length-1){
					section.push(this.answers_section_tests[i]);
					sections_array.push(section);
				}
			}

			
			

			
			

			//build the structure

			var test_array = [];
			for(var i = 0; i < this.test_sections.length; i++){
				var section = this.test_sections[i];
				var questions_array = [];
				for(var j = 0; j < section.QUESTIONS.length; j++){
					var question = section.QUESTIONS[j];
					//detect some properties
					var globalScore = 0;
					var hasGlobalScore = false;
					var isDefault = false;
					var hasScore = false;
					var isScorable = false;

					if (question.STRUCTURE_PARSED[0].DEFAULT){
						isDefault = true;
					}

					if (question.STRUCTURE_PARSED[0].globalScore){
						globalScore = question.STRUCTURE_PARSED[0].globalScore;
					}

					if (question.STRUCTURE_PARSED[0].hasGlobalScore){
						hasGlobalScore = true;
					}

					if (question.STRUCTURE_PARSED[0].hasScore){
						hasScore = true;
					}

					if (question.STRUCTURE_PARSED[0].isScorable){
						isScorable = true;
					}

					//check if question is matching question
					var question = sections_array[i][j];
					var answers = [];
					
					if (question.questiontype === 12532){ //matching
						answers = question.value;
					}else{
						answers = [{
							"value": question.value,
							"score": question.score,
							"order": question.order,
							"index": question.index,
							"sectionIndex": question.sectionIndex,
						}];
					}

					var question_object = {
						"structure": {
							"answers": answers,
							"isDefault": isDefault,
							"globalScore": globalScore,
							"hasGlobalScore": hasGlobalScore,
							"hasScore": hasScore,
							"isScorable": isScorable
						}
						"required": question.REQUIRED,
						"id": question.CLASSROOM_TEST_QUESTION_ID,
						"type:": question.QUESTION_TYPE_ID,
						"order": question.QUESTION_ORDER,
						"sectionId": question.CLASSROOM_TEST_SECTION_ID
					}
					questions_array.push(question_object);
				}

				var section_object = {
					"id": section.CLASSROOM_TEST_SECTION_ID,
					"questions": questions_array,
					"hasScore": section.HAS_SCORE,
					"order": section.SECTION_ORDER
				}

				test_array.push(section_object);

			}

			var complete_structure = {
				"id": this.test_header[0].CLASSROOM_TEST_HEADER_ID,
				"training_code": this.course_data.id,
				"sections": test_array
			}

			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'POST',
				data: {
					method: "setNewEmployeeOnlineTestInfo",
					training_online_header_id: this.course_data.header_id,
					test_answer_structure: JSON.stringify(complete_structure),
					score: 100,
					creation_date: this.startingDate,
					end_date: this.getTodaysDate(), 
					approved: 1
				}
			}).done( (data) => {
				this.approveCourse(100);
			}).fail((status, errorDesc) => {
				
			}).always(() =>{
				this.Loading = false;
			});


		},

		approveCourse: function(test_score){
			const c = this;
			if(this.player !== null){
				this.pauseVideo();
			}

			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'POST',
				data: {
					method: "setNewPostTrainingOnlineCourse",
					training_code_id: this.course_data.id,
					emp_code: this.$store.state.UserData.EMP_CODE,
					train_recurrence_id: this.course_data.passingScore,
					date_approved: this.getTodaysDate(),
					score: test_score,
					training_valid_for: this.course_data.valid_for,
					instructor_or_provider: 1,
					training_company_code: this.$store.state.UserData.COMPANY_CODE,
					training_online_header_id: this.course_data.header_id,
					classroom_training_source_id: 126101,
					scheduled_training_detail_id: this.$route.params.detailid || 0,
					company_code: this.$store.state.UserData.COMPANY_CODE || 0

				}
			}).done( (data) => {
				swal.fire({
					title: "Passed!",
					text: "The course was updated successfully. You can check the training record in your training history",
					icon: "success",
					cancelButtonColor: '#DD3333',
					confirmButtonColor: "#21B644",
					showCancelButton: true,
					confirmButtonText: 'Review My Training History',
					cancelButtonText: 'Exit',
				}).then(async (result) => {
					if(this.course_token !== 'normal-web-course'){
						const changeCourseStatus = await axios.get("https://locstatt.net/app/cfcChevron/classroom.cfc", { params: {method: "setUpdateTokenStatus", classroom_token: c.course_token, option: 2}})
					}
					
					if (result.dismiss === "cancel") {
						this.exitCourseAfterFailOrSuccess();
					}else{
						this.goToTrainingHistory();
					}
				  });
				$('#CourseTest').modal('close');
				
			}).fail((status, errorDesc) => {
				
			}).always(() => {
				this.Loading = false;
			});
		},

		failCourse: function(){
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'POST',
				data: {
					method: "setUpdateOnlineCourseStatus",
					training_online_header_id: this.course_data.header_id,
					training_status_id: 4367,
					classroom_training_source_id: 126101
				}
			}).done( (data) => {
				
			}).fail((status, errorDesc) => {
				
			}).always(() => {
				this.Loading = false;
			});
		},

		closeSideNav: function(){
			$('.button-collapse').sideNav('hide');
		},		
		
		onYouTubeIframeAPIReady: function(videoID) {
			const c = this;
			window.YT.ready(function(){
				c.player = new YT.Player('player',{
					height: '390',
					width: '640',
					videoId: videoID,
					playerVars: {
						'autoplay':1,
						'controls':1,
						'rel':0,
						'fs':1,
						'showinfo': 0,
						'modestbranding': 1
	
					},
					events: {
						'onStateChange': c.onPlayerStateChange,
						'onError': c.onPlayerError
					}
				});
				c.Loading = false;
			})
			
		},

		onYouTubeIframeAPIReadyWithControls: function(videoID) {
			const c = this;
			window.YT.ready(function(){
				c.player = new YT.Player('player',{
					height: '390',
					width: '640',
					videoId: videoID,
					playerVars: {
						'autoplay':1,
						'controls':1,
						'rel':0,
						'fs':1,
						'showinfo': 0,
						'modestbranding': 1
					},
					events: {
						'onStateChange': c.onPlayerStateChange,
						'onError': c.onPlayerError
					}
				});
				c.Loading = false;
			})
			
		},

		onPlayerReady: function(event){
			event.target.playVideo();
		},

		onPlayerStateChange: function(event){
			
			if(event.data == YT.PlayerState.ENDED){ //Youtube video has ended for first time
				console.log(this);
				this.totalSlidesViewed++;
				this.videoStatus = 2;
				var slidesViewed = 0;
				if (this.currentSlideIndex === -1){ //-1 means that the section will be changed to the next one
					var slides_length = this.CourseSlides[this.currentSectionIndex-1].section_slides.length;
					this.CourseSlides[this.currentSectionIndex-1].section_slides[slides_length-1].hasBeenViewed = true;
					if (this.test_type_id === 10000){
					}else{
						if(this.CourseSlide.HAS_EXAM){
							this.currentSlideHasTest = true;
						}else{
							this.currentSlideHasTest = false;
						}
					}
				}else{
					this.CourseSlides[this.currentSectionIndex].section_slides[this.currentSlideIndex].hasBeenViewed = true;
					if (this.test_type_id === 10000){
					
					}else{
						if(this.CourseSlide.HAS_EXAM){
							this.currentSlideHasTest = true;
						}else{
							this.currentSlideHasTest = false;
						}
					}
				}
				//Check if all slides have been viewed
				if(this.CourseSlides.length === 1){
					if(this.CourseSlides[0].section_slides.length === 1){
						this.allSlidesViewed = true;
					}else{
						for(var i = 0; i < this.CourseSlides[0].section_slides.length; i++){
							if(this.CourseSlides[0].section_slides[i].hasBeenViewed){
								slidesViewed++;
							}
						}
					}
				}else{
					for(var i = 0; i < this.CourseSlides.length; i++){
						for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
							if(this.CourseSlides[i].section_slides[j].hasBeenViewed){
								slidesViewed++;
							}
						}
					}
				}

				if(slidesViewed === this.TotalSlides){
					this.allSlidesViewed = true;
				}

				setTimeout(()=>{
					$(".slide-select").select2("destroy");
					$(".slide-select").select2({width: '100%'});
					//$('.slide-select').prop('selectedIndex', this.currentSlideIndex).change();
					if (this.test_type_id === 10000){
						$(".next_slide").removeClass('disabled');
					}else{
						if(this.CourseSlide.HAS_EXAM){
							this.currentSlideHasTest = true;
						}else{
							$(".next_slide").removeClass('disabled');
							this.currentSlideHasTest = false;
						}
					}

				},100)
			}

			if(event.data == YT.PlayerState.PLAYING){
				this.videoStatus = 0;
			}

			if(event.data == YT.PlayerState.PAUSED){
				this.videoStatus = 1;
			}


		},

		playVideo: function(){
			this.player.playVideo();
		},
		stopVideo: function(){
			this.player.stopVideo();
		},

		pauseVideo: function(){
			this.player.pauseVideo();
		},

		showLocation: function(position){
			var latitude = position.coords.latitude;
			var longitude = position.coords.longitude;
			this.setCourseAsInProgressForFirstTime(latitude,longitude);
		},

		errorHandler: function(err){
			if(err.code == 1){
				
			}else if (err.code == 2){
				
			}
			this.setCourseAsInProgressForFirstTime(0.0,0.0);
		},

		getLocation: function(){
			if("geolocation" in navigator){
				var options = {timeout:30000};
				navigator.geolocation.getCurrentPosition(this.showLocation, this.errorHandler, options);
			} else {
				this.setCourseAsInProgressForFirstTime(0.0,0.0);
			}
		},

		startCourseAgain: function(){
			$(".next_slide").show();
			this.allSlidesViewed = false;
			this.course_data.firstTimeOpened = true;

			if(this.player !== null){
				this.player.destroy();
			}

			this.currentSlideIndex = 0;
			this.currentSectionIndex = 0;
			this.getLocation();
		},

		setNewTrainingOnlineHeaderRecord: function(latitude, longitude){
			var hasTests = 0;
			if (this.course_data.count_tests){
				hasTests = 1;
			}
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'POST',
				data: {
					method: "setNewTrainingOnlineHeaderRecord",
					company_code: this.$store.state.UserData.COMPANY_CODE,
					emp_code: this.$store.state.UserData.EMP_CODE,
					train_code_id: this.course_data.id,
					training_status: 2000,
					lat: latitude,
					lng: longitude,
					classroom_devide_type_id: 1002,
					apply_test: hasTests
				}
			}).done( (data) => {
				this.course_data.header_id = data[0].TRAINING_ONLINE_HEADER_ID;

				if (this.course_data.count_tests){
					this.getExamFromServer();
				}else{
					this.test_type_id = 10000;
					this.getXMLContentFromServer();
				}
				
				
			}).fail((status, errorDesc) => {
				
			}).always(() =>{
				this.Loading = false;
			});

		},


		setCourseAsInProgressForFirstTime: function(latitude, longitude){
			this.Loading = true;
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: "getInProgressTrainingByEmployee",
					emp_code: this.$store.state.UserData.EMP_CODE
				}	
			}).done( (data) => {
				var counter = 0;
				for(var i = 0; i < data.length; i++){
					if (this.course_data.id === data[i].TRAINING_CODE_ID){
						counter++;
					}
				}
				if(counter === 0){
					this.setNewTrainingOnlineHeaderRecord(latitude, longitude);
				}else{
					this.getXMLContentFromServer();
				}
			}).fail((status,errorDesc)=>{

			}).always(() => {

			});
			
		},
		leaving: function(){
			alert("Leaving");
		},
		
		changeSlide: function(event){
			var index = event.target.selectedIndex
			if (this.CourseSlides.length > 0){
				this.CourseSlide = this.CourseSlides[index];

				if(this.CourseSlide.hasBeenViewed){
					$(".next_slide").removeClass('disabled');
				}
				if(this.CourseSlide._attributes.type === "mp4"){
					setTimeout(()=>{
						$("video")[0].load();
					},100)
					
				}
				if(this.CourseSlide._attributes.type === "youtube"){
					if (this.player !== null){
						this.player.destroy();
						this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media);
					}else{
						var script = document.createElement("script");
						script.src = "https://www.youtube.com/iframe_api";
						script.type = 'text/javascript';
						script.onload = () => {
							if((typeof YT !== "undefined") && YT && YT.Player){
								this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
							}else{
								setTimeout( () => {
									this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
								}, 1000);
							}
						};
						document.getElementsByTagName("head")[0].appendChild(script);
					}
				}
				this.currentSlideIndex = index;
			}
		},

		goToTrainingHistory: function(){

			this.$router.push({ 
            	name: `TrainingHistory`,
           	});

		},

		goToStartOfSectionAfterFailing: function(last_slide_with_test){
			var section = 0;
			if(last_slide_with_test === this.currentSlideIndex){
			}else{
				this.CourseSlide = this.CourseSlides[section].section_slides[last_slide_with_test];
			}
			if(parseInt(this.CourseSlide._attributes.id) === 1){
				$(".previous_slide").hide();
			}else{
				$(".previous_slide").show();
			}

			if(this.CourseSlides.length === 1){
				if(parseInt(this.CourseSlide._attributes.id) === this.CourseSlides[0].section_slides.length){
					$(".next_slide").hide();
				}else{
					$(".next_slide").show();
				}
			}else{
				var totalSlides = 0;
				for(var i = 0; i < this.CourseSlides.length; i++){
					for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
						totalSlides++;
					}
				}

				if(parseInt(this.CourseSlide._attributes.id) === totalSlides){
					$(".next_slide").hide();
				}else{
					$(".next_slide").show();
				}
			}			

			if(this.CourseSlide._attributes.type === "mp4"){
				this.CourseSlide.hasBeenViewed = false;
				setTimeout(()=>{
					$("video")[0].load();
				},100)
			}else if (this.CourseSlide._attributes.type === "youtube"){
				this.CourseSlide.hasBeenViewed = false;
				if (this.player !== null){
					this.player.destroy();
					this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media);
				}else{
					var script = document.createElement("script");
					script.src = "https://www.youtube.com/iframe_api";
					script.type = 'text/javascript';
					script.onload = () => {
						if((typeof YT !== "undefined") && YT && YT.Player){
							this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
						}else{
							setTimeout( () => {
								this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
							}, 1000);
						}
					};
					document.getElementsByTagName("head")[0].appendChild(script);
				}

			}else{
				if(last_slide_with_test === this.currentSlideIndex){
					if(this.CourseSlide.EXAM_PASSED){
						$(".next_slide").removeClass('disabled');
					}
				}else{
					if(this.CourseSlide.hasBeenViewed){
						$(".next_slide").removeClass('disabled');
					}
				}
			}

			this.currentSectionIndex = section;

			if(last_slide_with_test === this.currentSlideIndex){

			}else{
				this.currentSlideIndex = last_slide_with_test;
			}
			
			$('.button-collapse').sideNav('hide');

			$("select").val(this.CourseSlide._attributes.id).change();
		},

		goToSlide: function(event){
			var section = -1;
			var slide = -1;
			
			if(event.type === "select2:select"){
				section = parseInt(jQuery.makeArray(event.target.selectedOptions)[0].dataset.sectionindex);
				slide = parseInt(jQuery.makeArray(event.target.selectedOptions)[0].dataset.slideindex);
			}else{
				section = parseInt(event.target.dataset.section);
				slide = parseInt(event.target.dataset.slide);
			}

			this.CourseSlide = this.CourseSlides[section].section_slides[slide];

			if(parseInt(this.CourseSlide._attributes.id) === 1){
				$(".previous_slide").hide();
			}else{
				$(".previous_slide").show();
			}

			if(this.CourseSlides.length === 1){
				if(parseInt(this.CourseSlide._attributes.id) === this.CourseSlides[0].section_slides.length){
					$(".next_slide").hide();
				}else{
					$(".next_slide").show();
				}
			}else{
				var totalSlides = 0;
				for(var i = 0; i < this.CourseSlides.length; i++){
					for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
						totalSlides++;
					}
				}

				if(parseInt(this.CourseSlide._attributes.id) === totalSlides){
					$(".next_slide").hide();
				}else{
					$(".next_slide").show();
				}
			}

			if(this.CourseSlide.hasBeenViewed){
				$(".next_slide").removeClass('disabled');
			}

			if(this.CourseSlide._attributes.type === "mp4"){
				setTimeout(()=>{
					$("video")[0].load();
				},100)
			}

			if(this.CourseSlide._attributes.type === "youtube"){
				if (this.player !== null){
					this.player.destroy();
					this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media);
				}else{
					var script = document.createElement("script");
					script.src = "https://www.youtube.com/iframe_api";
					script.type = 'text/javascript';
					script.onload = () => {
						if((typeof YT !== "undefined") && YT && YT.Player){
							this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
						}else{
							setTimeout( () => {
								this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
							}, 1000);
						}
					};
					document.getElementsByTagName("head")[0].appendChild(script);
				}

			}
			this.currentSectionIndex = section;
			this.currentSlideIndex = slide;
			$('.button-collapse').sideNav('hide');
			$("select").val(this.CourseSlide._attributes.id).change();
		},


		getLastViewedSlideID: function(){
			
			var lastViewedID = 0;
			if (this.CourseSlides.length > 1){
				for(var i = 0; i < this.CourseSlides.length; i++){
					for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
						var slide = this.CourseSlides[i].section_slides[j];
						if(slide.hasBeenViewed){
							lastViewedID = parseInt(slide._attributes.id);
						}		
					}
				}
			}else if (this.CourseSlides.length === 1){

				var slides = slide = this.CourseSlides[0].section_slides;

				if(slides.length === 1){
					if(slide[0].hasBeenViewed){
						lastViewedID = 1;
					}else{
						lastViewedID = 0;
					}
				}else{
					for(var i = 0; i < slides.length; i++){
						var slide = slides[i];
						if(slide.hasBeenViewed){
							lastViewedID = parseInt(slide._attributes.id);							
						}
					}
				}
				
			}
			return lastViewedID;
		},
		
		exitCourseFromButton: function(){

			var last_slide = 0;

			if (this.test_type_id === 10001){
				last_slide = 0;
			}else{
				last_slide = parseInt(this.getLastViewedSlideID());
			}

			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'POST',
				data:{
					method: "setNewTrainingOnlineDetailRecord",
					training_online_header_id: this.course_data.header_id,
					last_slide: last_slide,
					comments: 0,
					lat: 0.0,
					lng: 0.0
				}
			}).done( (data) => {
				this.$router.push({ 
                	name: `classrooms`,
	            	params: {
	            		fromCourse: true
	            	} 
           		});
			}).fail((status, errorDesc) => {
				
			}).always(() =>{

			});
		},

		videoHasEnded: function(event){
			this.totalSlidesViewed++;
			this.videoStatus = 2;
			var slidesViewed = 0;
			event.target.controls = true;
			if (this.currentSlideIndex === -1){ //-1 means that the section will be changed to the next one
				var slides_length = this.CourseSlides[this.currentSectionIndex-1].section_slides.length;
				this.CourseSlides[this.currentSectionIndex-1].section_slides[slides_length-1].hasBeenViewed = true;
				if (this.test_type_id === 10000){
					
				}else{
					if(this.CourseSlide.HAS_EXAM){
						this.currentSlideHasTest = true;
					}else{
						this.currentSlideHasTest = false;
					}
				}
			}else{
				this.CourseSlides[this.currentSectionIndex].section_slides[this.currentSlideIndex].hasBeenViewed = true;
				if (this.test_type_id === 10000){
				}else{
					if(this.CourseSlide.HAS_EXAM){
						this.currentSlideHasTest = true;
					}else{
						this.currentSlideHasTest = false;
					}
				}
				
			}

			//this.CourseSlides[this.currentSectionIndex].section_slides[this.currentSlideIndex].hasBeenViewed = true;
			//Check if all slides have been viewed
			if(this.CourseSlides.length === 1){
				if(this.CourseSlides[0].section_slides.length === 1){
					this.allSlidesViewed = true;
				}else{
					for(var i = 0; i < this.CourseSlides[0].section_slides.length; i++){
						if(this.CourseSlides[0].section_slides[i].hasBeenViewed){
							slidesViewed++;
						}
					}
				}
			}else{
				for(var i = 0; i < this.CourseSlides.length; i++){
					for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
						if(this.CourseSlides[i].section_slides[j].hasBeenViewed){
							slidesViewed++;
						}
					}
				}
			}

			if(slidesViewed === this.TotalSlides){
				this.allSlidesViewed = true;
			}
			
			setTimeout(()=>{
				$('select.select-box').prop('selectedIndex', this.currentSlideIndex).change();

				if (this.test_type_id === 10000){
					$(".next_slide").removeClass('disabled');
				}else{
					if(this.CourseSlide.HAS_EXAM){
						this.currentSlideHasTest = true;
					}else{
						$(".next_slide").removeClass('disabled');
						this.currentSlideHasTest = false;
					}
				}
			},100)
			
			if (this.test_type_id === 10000){
				$(".next_slide").removeClass('disabled');
			}else{
				if(this.CourseSlide.HAS_EXAM){
					this.currentSlideHasTest = true;
				}else{
					$(".next_slide").removeClass('disabled');
					this.currentSlideHasTest = false;
				}
			}
		},

		videoHasPaused: function(event){
			this.videoStatus = 1;
		},

		videoIsPlaying: function(event){
			this.videoStatus = 0;
		},

		startCourse: function() {
			if (this.CourseSlides.length !== undefined){
				this.currentSlideIndex = 0
				this.CourseSlide = this.CourseSlides[0];
			}else{
				this.CourseSlide = this.CourseSlides;
			}

			if (this.CourseSlide._attributes.type === "mp4"){
				setTimeout(() =>{
					$(".next_slide").addClass('disabled');
				},100);
				this.Loading = false;
			} else if (this.CourseSlide._attributes.type === "youtube"){
				var script = document.createElement("script");
				script.src = "https://www.youtube.com/iframe_api";
				script.type = 'text/javascript';
				script.onload = () => {
					if((typeof YT !== "undefined") && YT && YT.Player){
						this.onYouTubeIframeAPIReady(this.CourseSlide._attributes.media)
					}else{
						setTimeout( () => {
							this.onYouTubeIframeAPIReady(this.CourseSlide._attributes.media)
						}, 1000);
					}
					//this.Loading = false;
					$(".next_slide").addClass('disabled');
				};
				document.getElementsByTagName("head")[0].appendChild(script);
			} else{
				this.Loading = false;
				Vue.set(this.CourseSlide, 'hasBeenViewed', true);
			}
			this.currentSlideIndex = 0

			setTimeout(() => { 
				$('select.select-box').prop('selectedIndex', this.currentSlideIndex).change();
			}, 100);
		},

		nextSlide: function(){
			const c = this;
			var t0 = performance.now();

			this.Loading = true;

			if (this.currentSectionIndex <= this.CourseSlides.length-1){

				var lastSlide = this.CourseSlide;
				
				if (this.currentSlideIndex < this.CourseSlides[this.currentSectionIndex].section_slides.length-1){
					this.currentSlideIndex = this.currentSlideIndex + 1;
				}else{
					this.currentSectionIndex++;
					this.currentSlideIndex = 0;
				}

				this.CourseSlide = this.CourseSlides[this.currentSectionIndex].section_slides[this.currentSlideIndex];
				if(lastSlide._attributes.type === "youtube"){
					c.player.destroy();
				}

				$("select").val(this.CourseSlide._attributes.id).change();			

				this.checkSlideType();

				$(".previous_slide").show();

				if(this.currentSlideIndex >= this.CourseSlides[this.currentSectionIndex].section_slides.length-1){
					if(this.CourseSlides.length === 1){ //Only has one section
						$(".next_slide").hide();
					}else{
						this.currentSlideIndex = -1; //-1 because once a section has been changed, slideIndex must be set to 0 so when the button is pressed, 1 will be added to the counter and the counter will be 0
						this.currentSectionIndex = this.currentSectionIndex+1; 
						if(this.currentSectionIndex >= this.CourseSlides.length){ //Multiple sections but reached the end
							$(".next_slide").hide();
							this.currentSectionIndex--;
							this.currentSlideIndex = this.CourseSlides[this.currentSectionIndex].section_slides.length-1;
						}
					}
				}

				if (this.test_type_id === 10000){ //normal
					if !(this.CourseSlide.hasBeenViewed){
						$(".next_slide").addClass('disabled');
					}
				}else if (this.test_type_id === 10001){
					//Check if slide has a test
					console.log(this.CourseSlide);
					console.log(this.test_type_id);
					if (this.CourseSlide.HAS_EXAM){
						console.log("HAS EXAM: ", this.CourseSlide.HAS_EXAM)
						//check if it has been passed
						if (!this.CourseSlide.EXAM_PASSED){
							console.log("EXAM PASSED: ", this.CourseSlide.EXAM_PASSED)
							$(".next_slide").addClass('disabled');
						}else{
							$(".next_slide").removeClass('disabled');
						}
					}else{
						if !(this.CourseSlide.hasBeenViewed){
							
							$(".next_slide").addClass('disabled');
						}
					}
				}
			}

			var t1 = performance.now();
			

		},
		previousSlide: function(){
			this.Loading = true;
			if(this.currentSectionIndex >= 0){ //0 is the least possible section index
				if(this.currentSlideIndex >= 0){
					var lastSlide = this.CourseSlide;
					this.currentSlideIndex--;
					this.CourseSlide = this.CourseSlides[this.currentSectionIndex].section_slides[this.currentSlideIndex];

					$('.next_slide').removeClass('disabled');
					$("select").val(this.CourseSlide._attributes.id).change();
					$(".next_slide").show();

					if(this.CourseSlide._attributes.type === "mp4"){
						setTimeout(()=> {
							if(this.player !== null){
								this.player.destroy();
								this.player = null;
							}
							$("video")[0].load();
							this.Loading = false;
						},100)
					} else if (this.CourseSlide._attributes.type === "youtube"){
						setTimeout( () =>{
							if(this.player !== null){
								if(lastSlide._attributes.type === "youtube"){
									this.player.destroy();
								}
								setTimeout( () => {
									this.onYouTubeIframeAPIReady(this.CourseSlide._attributes.media);
								},1000);
							}else{
								var script = document.createElement("script");
								script.src = "https://www.youtube.com/iframe_api";
								script.type = 'text/javascript';
								script.onload = () => {
									if((typeof YT !== "undefined") && YT && YT.Player){
										this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
									}else{
										setTimeout( () => {
											this.onYouTubeIframeAPIReadyWithControls(this.CourseSlide._attributes.media)
										}, 1000);
									}
									//this.Loading = false;
								};
								document.getElementsByTagName("head")[0].appendChild(script);
							}
						},500);
					} else {
						this.Loading = false;
						
						if(this.CourseSlide.HAS_EXAM){
							this.currentSlideHasTest = true;
						}else{
							this.currentSlideHasTest = false;
						}
						if(this.player !== null){
							this.player.destroy();
							this.player = null;
						}
					}

					if (this.currentSlideIndex === 0 && this.currentSectionIndex === 0){
						$('.previous_slide').hide();
					}

					//Check if it has reached the first slide and change section
					if (this.currentSlideIndex === 0 && this.currentSectionIndex > 0){
						this.currentSectionIndex--;
						this.currentSlideIndex = this.CourseSlides[this.currentSectionIndex].section_slides.length;
					}
				}
				
			}
		},

		openCloseWarning: function(){
			var message = "";
			if (this.test_type_id === 10001){
				message = "You will lose all answered questions and will need to restart the test. Do you still want to exit?";
			}else{
				message =
				 "Are you sure you want to close the test? You will lose all answered questions and will have to start again from the beginning next time you open the test. Do you want to exit?";
			}
			swal.fire({
				title: 'Close Test',
				text: message,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#d33',
				cancelButtonColor: '#3085d6',
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.closeTestModal();
				}
			})
		},

		closeTestModal: function(){
			$('input[type="radio"]').prop('checked', false); 
			$('#CourseTest').modal('close');
		},

		checkIfAllIsAnswered: function(){
			var totalQuestions = 0;
			var totalAnsweredQuestions = 0;
			for(var sectionIndex in this.CourseTest.test_sections){
				var section = this.CourseTest.test_sections[sectionIndex];
				for (var questionIndex in section.QUESTIONS){
					totalQuestions++;
					if (section.QUESTIONS[questionIndex].ANSWERED){
						totalAnsweredQuestions++;
					}
				}
			}

			console.log({totalQuestions, totalAnsweredQuestions});
			return totalQuestions === totalAnsweredQuestions;
		},

		checkTest: function(){
			if (this.test_type_id === 10000){
				if (this.checkIfAllIsAnswered()){
					$('#submit-test-modal').modal('open');
				}else{
					$('#missing-questions-modal').modal('open');
				}
			}else{
				if (this.checkIfAllIsAnswered()){
					this.calculateScoreSlideTest();
				}else{
					$('#missing-questions-modal').modal('open');
				}
			}
		},

		loadVimeoPlayer: function(){
			this.Loading = false;
			const c = this;
			const vimeoiFrame = document.getElementById("vimeoPlayer");
			const vimeoPlayer = new Vimeo.Player(vimeoiFrame);

			vimeoPlayer.on('play', function() {
				c.videoStatus = 0;
			});

			vimeoPlayer.on('pause', function() {
				c.videoStatus = 1;
			});

			vimeoPlayer.on('ended', function(event) {
				c.videoStatus = 2;
				var slidesViewed = 0;

				if (c.currentSlideIndex === -1){ //-1 means that the section will be changed to the next one
					var slides_length = c.CourseSlides[c.currentSectionIndex-1].section_slides.length;
					c.CourseSlides[c.currentSectionIndex-1].section_slides[slides_length-1].hasBeenViewed = true;
					if (c.test_type_id === 10000){
					}else{
						if(c.CourseSlide.HAS_EXAM){
							c.currentSlideHasTest = true;
						}else{
							c.currentSlideHasTest = false;
						}
					}
				}else{
					c.CourseSlides[c.currentSectionIndex].section_slides[c.currentSlideIndex].hasBeenViewed = true;
					if (c.test_type_id === 10000){
					
					}else{
						if(c.CourseSlide.HAS_EXAM){
							c.currentSlideHasTest = true;
						}else{
							c.currentSlideHasTest = false;
						}
					}
				}
				//Check if all slides have been viewed
				if(c.CourseSlides.length === 1){
					if(c.CourseSlides[0].section_slides.length === 1){
						c.allSlidesViewed = true;
					}else{
						for(var i = 0; i < c.CourseSlides[0].section_slides.length; i++){
							if(c.CourseSlides[0].section_slides[i].hasBeenViewed){
								slidesViewed++;
							}
						}
					}
				}else{
					for(var i = 0; i < c.CourseSlides.length; i++){
						for(var j = 0; j < c.CourseSlides[i].section_slides.length; j++){
							if(c.CourseSlides[i].section_slides[j].hasBeenViewed){
								slidesViewed++;
							}
						}
					}
				}

				if(slidesViewed === c.TotalSlides){
					c.allSlidesViewed = true;
				}

				setTimeout(()=>{
					$(".slide-select").select2("destroy");
					$(".slide-select").select2({width: '100%'});
					$('.slide-select').prop('selectedIndex', c.currentSlideIndex).change();

					if (c.test_type_id === 10000){
						$(".next_slide").removeClass('disabled');
					}else{
						if(c.CourseSlide.HAS_EXAM){
							c.currentSlideHasTest = true;
						}else{
							$(".next_slide").removeClass('disabled');
							c.currentSlideHasTest = false;
						}
					}

				},100)
			});
		},

		checkSlideType: function(){
			const c = this;
			this.player = null;
			if(this.CourseSlide._attributes.type === "mp4"){
				if !(this.CourseSlide.hasBeenViewed){
					$(".next_slide").addClass('disabled');
				}
				setTimeout(()=>{
					if($("video")[0] !== undefined){
						$("video")[0].load();
						this.Loading = false;
					}
				},100);
			}else if (this.CourseSlide._attributes.type === "youtube"){
				if !(this.CourseSlide.hasBeenViewed){
					$(".next_slide").addClass('disabled');
				}
				this.youtubeVideoPlayerInitialize();
			}else if (this.CourseSlide._attributes.type === "vimeo" || this.CourseSlide._attributes.type === "other"){
				var media = this.CourseSlide._attributes.media.split("/");
				Vue.set(this.CourseSlide._attributes, "media", `https://player.vimeo.com/video/${media[media.length-1]}`)
			}else{
				this.Loading = false;
				if(this.CourseSlide.HAS_EXAM){
					this.currentSlideHasTest = true;
					if(!(this.CourseSlide.EXAM_PASSED))){
						$(".next_slide").addClass('disabled');
					}
				}else{
					this.currentSlideHasTest = false;
				}

				if (this.CourseSlide.hasBeenViewed === undefined){
					this.totalSlidesViewed++;
					Vue.set(this.CourseSlide, 'hasBeenViewed', true);
					$(".slide-select").select2("destroy");
					$(".slide-select").select2({width: '100%'});
				}

				if (this.totalSlidesViewed === this.TotalSlides){
					this.allSlidesViewed = true;
					//Check if course has multiple sections
					if(this.CourseSlides.length > 1){
						var totalSlides = 0;
						for(var i = 0; i < this.CourseSlides.length; i++){
							for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
								totalSlides++;
							}	
						}

						if(this.currentSlideIndex === totalSlides-1){
							$('.next_slide').hide();
						}

					}else{
						if(this.currentSlideIndex === this.CourseSlides[this.currentSectionIndex].section_slides.length-1){
							$('.next_slide').hide();
						}
					}
				}				
			}
			if(this.CourseSlide.HAS_EXAM){
				Vue.set(this.CourseSlide, "LAST_SLIDE_WITH_EXAM", this.currentSlideIndex);
			}			
		},

		youtubeVideoPlayerInitialize: function(){
			if (this.player !== null){
				setTimeout( () => {
					this.onYouTubeIframeAPIReady(this.CourseSlide._attributes.media);
				},1000);
			}else{

				var script = document.createElement("script");
				script.src = "https://www.youtube.com/iframe_api";
				script.type = 'text/javascript';
				script.onload = () => {
					if((typeof YT !== "undefined") && YT && YT.Player){
						this.onYouTubeIframeAPIReady(this.CourseSlide._attributes.media)
					}else{
						setTimeout( () => {
							this.onYouTubeIframeAPIReady(this.CourseSlide._attributes.media)
						}, 1000);
					}
				}
				document.getElementsByTagName("head")[0].appendChild(script);
			}
		},


		getExamFromServer: async function(){
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: "getClassroomTestsByTrainingId",
					training_code_id: this.course_data.id,
				}
			}).done( (data) => {
				if (data.length){
					$.ajax({
						url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
						method: 'GET',
						data: {
							method: "getCompleteTestByTestHeaderIdWebApp",
							CLASSROOM_TEST_HEADER_ID: data[0].CLASSROOM_TEST_HEADER_ID,
						}
					}).done( (data2) => {
						this.test_type_id = data2.test_header[0].CLASSROOM_TEST_TYPE_ID;
						this.test_header = data2.test_header;
						this.test_sections = data2.test_sections;
						//this.openModalWithCompleteTest(data);
						this.getXMLContentFromServer();
					}).fail((status, errorDesc) => {
						
					}).always(() => {
						this.Loading = false;
					});
				}
			});
		},

		preloadImages(array){
			for(var i = 0; i < array.length; i++){
				var preloadImage = new Image().src = array[i];
			}
		},

		preloadVideos(array){
			// Let's create a video pre-cache and store all first segments of videos inside.
			window.caches.open('video-pre-cache')
			.then(cache => Promise.all(array.map(videoFileUrl => this.fetchAndCache(videoFileUrl, cache))));
		},

		fetchAndCache(videoFileUrl, cache) {
			// Check first if video is in the cache.
			return cache.match(videoFileUrl)
			.then(cacheResponse => {
			  // Let's return cached response if video is already in the cache.
			  if (cacheResponse) {
				return cacheResponse;
			  }
			  // Otherwise, fetch the video from the network.
			  return fetch(videoFileUrl)
			  .then(networkResponse => {
				// Add the response to the cache and return network response in parallel.
				cache.put(videoFileUrl, networkResponse.clone());
				return networkResponse;
			  });
			});
		},

		getXMLContentFromServer: async function(){
			var c = this;
			this.Loading = true;
			this.CourseSlides = [];
			var x = new XMLHttpRequest();
			var fileURL = "https://classroom.locstatt.net/courseLibrary/" + this.course_data.id + "/" + this.course_data.id + ".xml";
			x.onreadystatechange = () => {
				if (x.readyState == 4 && x.status == 200){
					var doc = x.responseXML;
					var XMLString = doc.documentElement.innerHTML;
					var json = {};
					var slides = [];
					try {
						json = convert.xml2json(XMLString, {compact: true, spaces: 4});
						json = jQuery.parseJSON(json);
						var sections = json.TRAINING.SECTION;
						if (sections instanceof Array){
							var slide_number = 0;
							for(var i = 0; i < sections.length;i++){
								var section_slides = [];
								for(var j = 0; j < sections[i].CONTENT.FRAME.length; j++){
									var slide = sections[i].CONTENT.FRAME[j];
									slide.SLIDE_NUMBER = slide_number;
									slide_number += 1;
									section_slides.push(slide);
								}
								slides.push(
									{
										name: sections[i]._attributes.name,
										section_slides: section_slides
									}
								)
							}
						}else{
							if(sections.CONTENT.FRAME instanceof Array){
								var slide_number = 0;
								var section_slides = [];
								for(var i = 0; i < sections.CONTENT.FRAME.length; i++){
									var slide = sections.CONTENT.FRAME[i];
									slide.SLIDE_NUMBER = slide_number;
									slide_number += 1;
									section_slides.push(slide);

								}
								slides.push(
									{
										name: sections._attributes.name,
										section_slides: section_slides
									}
								);
							}else{
								sections.CONTENT.FRAME.SLIDE_NUMBER = 0;
								slides.push(
									{
										name: sections._attributes.name,
										section_slides: [sections.CONTENT.FRAME]
									}
								);
							}
						}
					} catch(err) {
						slides = []
						var slides_section = [];
						json = parser.xml2json(XMLString);
						var sections = json.TRAINING.SECTION;
						var slides_before = json.TRAINING.SECTION.CONTENT.FRAME;
						
						if (slides_before.length){
							var slide_index = 0;
							for(var i in slides_before){
								var object = {
									_attributes: {
										id: slides_before[i].id,
										media: slides_before[i].media,
										video: slides_before[i].video,
										type: slides_before[i].type,
										have_question: slides_before[i].have_question,
										title: slides_before[i].title
									},
									SLIDE_NUMBER: slide_index
								}
								slides_section.push(object);
								slide_index += 1;
							}

							slides.push(
								{
									name: sections.name,
									section_slides: slides_section
								}
							);

						}else{
							var object= {
								_attributes: {
									id: slides_before.id,
									media: slides_before.media,
									video: slides_before.video,
									type: slides_before.type,
									have_question: slides_before.have_question,
									title: slides_before.title
								},
								SLIDE_NUMBER: 0,

							}
							slides_section.push(object);
							slides.push(
								{
									name: sections.name,
									section_slides: slides_section
								}
							);
						}
					}

					this.CourseSlides = slides;
					var imagesArray = [];
					var videosArray = [];
					for(var i = 0; i < this.CourseSlides.length; i++){
						var section_slides = this.CourseSlides[i].section_slides;
						for(var j = 0; j < section_slides.length; j++){
							var slide = section_slides[j]._attributes;
							if (slide.type === "png"){
								var image = this.ImagesPath+slide.media;
								imagesArray.push(image);
							}
							if(slide.type == "mp4"){
								videosArray.push(this.VideosPath+slide.media);
							}
						}
					}
					this.preloadImages(imagesArray);
					this.preloadVideos(videosArray);
					//If test type is 1001 divide questions in groups by slide
					var question_groups = [];
					var question_group = [];
					if (this.test_type_id === 10001){

						for(var i = 0; i < this.test_sections.length; i++){
							for(var j = 0; j < this.test_sections[i].QUESTIONS.length; j++){
								//First question always have to be added
								this.totalTestQuestions += 1;
								var question = this.test_sections[i].QUESTIONS[j];
								Vue.set(question, "section_index", i);
								Vue.set(question, "question_index", j);
								//question.question_index = j;
								//question.section_index = i;
								if (j === 0){
									question_group.push(question);
								}else{
									//Check previous question to see if it applies to the same group as the last or a new one
									var prev_question = this.test_sections[i].QUESTIONS[j-1];

									if(question.SLIDE_NUMBER === prev_question.SLIDE_NUMBER){
										question_group.push(question);
									}else{
										question_groups.push(
											{ 
												QUESTIONS:question_group,
												SECTION_NAME: this.test_sections[i].SECTION_NAME,
												EMP_NAME:this.test_sections[i].EMP_NAME,
												HAS_SCORE:this.test_sections[i].HAS_SCORE,
												CLASSROOM_TEST_SECTION_ID:this.test_sections[i].CLASSROOM_TEST_SECTION_ID,
												SECTION_ORDER:this.test_sections[i].SECTION_ORDER,
												SECTION_DESCRIPTION:this.test_sections[i].SECTION_DESCRIPTION,
												CREATION_DATE:this.test_sections[i].CREATION_DATE,
												EMP_CODE:this.test_sections[i].MP_CODE,
												CLASSROOM_TEST_HEADER_ID:this.test_sections[i].CLASSROOM_TEST_HEADER_ID

											}
										);
										question_group = [];
										question_group.push(question);
									}
									if (j === this.test_sections[i].QUESTIONS.length-1){
										question_groups.push({ 
											QUESTIONS:question_group,
											SECTION_NAME: this.test_sections[i].SECTION_NAME,
											EMP_NAME:this.test_sections[i].EMP_NAME,
											HAS_SCORE:this.test_sections[i].HAS_SCORE,
											CLASSROOM_TEST_SECTION_ID:this.test_sections[i].CLASSROOM_TEST_SECTION_ID,
											SECTION_ORDER:this.test_sections[i].SECTION_ORDER,
											SECTION_DESCRIPTION:this.test_sections[i].SECTION_DESCRIPTION,
											CREATION_DATE:this.test_sections[i].CREATION_DATE,
											EMP_CODE:this.test_sections[i].MP_CODE,
											CLASSROOM_TEST_HEADER_ID:this.test_sections[i].CLASSROOM_TEST_HEADER_ID
										});
										question_group = [];
									}
								}
							}
						}
					}
					var last_slide_with_exam = 0;
					for(var i = 0; i < question_groups.length; i++){
						var group = question_groups[i].QUESTIONS;
						var slide = question_groups[i].QUESTIONS[0].SLIDE_NUMBER;
						var section_name = question_groups[i].SECTION_NAME;
						var emp_name = question_groups[i].EMP_NAME;
						var has_score = question_groups[i].HAS_SCORE;
						var classroom_test_section_id = question_groups[i].CLASSROOM_TEST_SECTION_ID;
						var section_order = question_groups[i].SECTION_ORDER;
						var section_description = question_groups[i].SECTION_DESCRIPTION;
						var creation_date = question_groups[i].CREATION_DATE;
						var emp_code = question_groups[i].EMP_CODE;
						var classroom_test_header_id = question_groups[i].CLASSROOM_TEST_HEADER_ID;
						for(var j = 0; j < this.CourseSlides.length; j++){
							for (var k = 0; k < this.CourseSlides[j].section_slides.length; k++){
								if (slide == this.CourseSlides[j].section_slides[k].SLIDE_NUMBER){
									var slide_with_exam = this.CourseSlides[j].section_slides[k];
									Vue.set(slide_with_exam, "SECTION_NAME", section_name);
									Vue.set(slide_with_exam, "EMP_NAME", emp_name);
									Vue.set(slide_with_exam, "HAS_SCORE", has_score);
									Vue.set(slide_with_exam, "CLASSROOM_TEST_SECTION_ID", classroom_test_section_id);
									Vue.set(slide_with_exam, "SECTION_ORDER", section_order);
									Vue.set(slide_with_exam, "SECTION_DESCRIPTION", section_description);
									Vue.set(slide_with_exam, "CREATION_DATE", creation_date);
									Vue.set(slide_with_exam, "EMP_CODE", emp_code);
									Vue.set(slide_with_exam, "CLASSROOM_TEST_HEADER_ID", classroom_test_header_id);
									Vue.set(slide_with_exam, "QUESTIONS", group);
									Vue.set(slide_with_exam, "HAS_EXAM", true);
									Vue.set(slide_with_exam, "EXAM_PASSED", false);
									Vue.set(slide_with_exam, "LAST_SLIDE_WITH_EXAM", last_slide_with_exam);
									last_slide_with_exam = slide;
								}
							}
						}
					}


					setTimeout(()=>{
						if !(this.course_data.firstTimeOpened){
							if(this.CourseSlides.length){
								for(var i = 0; i < this.CourseSlides.length; i++){
									for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
										this.TotalSlides++;
										if(this.CourseSlides.length === 1 && this.CourseSlides[0].section_slides.length === 1){
											this.CourseSlide = this.CourseSlides[0].section_slides[0];
											this.currentSlideIndex = j;
											this.currentSectionIndex = i;
											$('.next_slide').hide();
											$('.previous_slide').hide();
										}else{
											if(this.course_data.lastSlide === 0){
												$('.previous_slide').hide();
												this.CourseSlide = this.CourseSlides[0].section_slides[0];
												this.currentSlideIndex = 0;
												this.currentSectionIndex = 0;
											}
											if (parseInt(this.CourseSlides[i].section_slides[j]._attributes.id) <= this.course_data.lastSlide){
												Vue.set(this.CourseSlides[i].section_slides[j], 'hasBeenViewed', true);
											}
											if(this.CourseSlides.length === 1 && this.CourseSlides[0].section_slides.length === this.course_data.lastSlide){
												this.CourseSlide = this.CourseSlides[0].section_slides[this.CourseSlides[0].section_slides.length-1];
												this.currentSlideIndex = this.CourseSlides[0].section_slides.length-1;
												this.currentSectionIndex = 0;
											}else{
												if(parseInt(this.CourseSlides[i].section_slides[j]._attributes.id) === this.course_data.lastSlide+1){
													this.CourseSlide = this.CourseSlides[i].section_slides[j];
													this.currentSlideIndex = j;
													this.currentSectionIndex = i;
												}
											}
										}
									}									
								}

								if(this.course_data.lastSlide === this.TotalSlides){
									$('.next_slide').hide();
								}


								if (this.test_type_id === 10001){
									//Check which slides have test and make it available in the slides 


								}else{
									//Check if all slides have been viewed and enable the test or finish button, hide the next button 

									for(var i = 0; i < this.CourseSlides.length; i++){
										for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
											if (this.CourseSlides[i].section_slides[j].hasBeenViewed){
												this.totalSlidesViewed++;
											}
										}
									}
								}

								if(this.totalSlidesViewed === this.TotalSlides){

									this.allSlidesViewed = true;
									$('.next_slide').hide();
									setTimeout(() => {
										$(".slide-select").select2({width:'100%'});
									}, 500)
									
								}
							}

							$(".slide-select").select2({width:'100%'});

							$(".slide-select").on('select2:select', (e) => {
								this.goToSlide(e);
							});

							$("select").val(this.CourseSlide._attributes.id).change();
						}else{
							for(var i = 0; i < this.CourseSlides.length; i++){
								for(var j = 0; j < this.CourseSlides[i].section_slides.length; j++){
									this.TotalSlides++;
								}
							}

							$(".slide-select").select2({width:'100%'});

							$(".slide-select").on('select2:select', (e) => {
								this.goToSlide(e);
							});

							$("select").val("1").change();

							this.CourseSlide = this.CourseSlides[0].section_slides[0];
							$('.previous_slide').hide();

							if(this.CourseSlides[0].section_slides.length === 1){
								$('.next_slide').hide();
							}
						}
						this.Loading = false;
						this.checkSlideType();
					},500);
				}
			};
			x.open("GET", fileURL, true);
			x.send(); 
		}
	}
};