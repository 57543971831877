
require.register("@twilio/webrtc/lib/mediastreamtrack.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@twilio/webrtc");
  (function() {
    /* global MediaStreamTrack */
'use strict';

if (typeof MediaStreamTrack === 'function') {
  module.exports = MediaStreamTrack;
} else {
  module.exports = function MediaStreamTrack() {
    throw new Error('MediaStreamTrack is not supported');
  };
}
  })();
});