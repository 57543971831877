import * as Vue from 'vue';
import LocationModal from '../components/LocationModal';
import { LiveSocketModule as livesocket } from "./livesocket";
import { LiveModule as live } from "./live";
import { GeneralSocket } from "./generalsocket";
import * as Utils from "../controllers/Utils";
Vue.config.devtools = true;
export class State{
	Focus: boolean;
	Online: boolean;
	UserData: UserData;
	ShowMenuDrawer: boolean;
	LoginRedirect: string;
	GeneralSocket: GeneralSocket;
	LiveCourses: [{ SITE_CODE: number, SITE_NAME: string, SESSIONS:  VirtualSession[] }];
	LoadingLive: boolean;
	UserAgent: string;
	constructor(){
		this.Focus = true;
		this.Online = navigator.onLine;
		this.UserData = null;
		this.ShowMenuDrawer = true;
		this.LoginRedirect = "";
		this.GeneralSocket = null;
		this.LiveCourses = [];
		this.LoadingLive = false;
		this.UserAgent = navigator.userAgent
	}
}
export default {
	state: new State(),
	getters: {
		drawer: function(state: State){
			return state.ShowMenuDrawer;
		},
		userData: function(state: State){
			return state.UserData;
		},
		online: function(state: State){
			return state.Online;
		},
		focus: function(state: State){
			return state.Focus;
		},
		liveCourses: function(state: State){
			return state.LiveCourses;
		},
		loadingLive: function(state: State){
			return state.LoadingLive;
		},
		userpic: function(state: State){
			if (state.UserData != undefined){
				let empCode: string = state.UserData.EMP_CODE;
				if(empCode){
					let stringCode = empCode.toString();
					return "https://locstatt.net/app/employeePhotos/"+(stringCode.slice(-2))+"/"+(stringCode.slice(0,6))+".jpg";
				}else{
					return "/images/image-placeholder.png";
				}
			}
			
		},
		isMozilla: function(state: State){
			return state.UserAgent.includes("Mozilla") && !state.UserAgent.includes("Chrome") && !state.UserAgent.includes("Safari");
		},
		isChrome: function(state: State){
			return (state.UserAgent.includes("Chrome") || state.UserAgent.includes("Chromium")) && !state.UserAgent.includes("Mozilla") && !state.UserAgent.includes("Safari");
		},
		isSafari: function(state: State){
			return !state.UserAgent.includes("Mozilla") && !state.UserAgent.includes("Chrome") && state.UserAgent.includes("Safari");
		}

	},
	mutations: {
		setUserData: function(state: State, data: UserData){
			state.UserData = data;
		},
		setFocus: function(state: State, focus: boolean){
			state.Focus = focus;
		},
		setOnline: function(state: State, online: boolean){
			state.Online = online;
		},
		toggleMenuDrawer: function(state: State, isOpen: boolean){
			state.ShowMenuDrawer = isOpen;
		},
		setLoginRedirect: function(state: State, path: string){
			state.LoginRedirect = path;
		},
		setGeneralSocket: function(state: State, socket: GeneralSocket){
			state.GeneralSocket = socket;
		},
		setLoadingLive: function(state: State, loading: boolean){
			state.LoadingLive = loading;
		},
		setLiveCourses: function(state: State, sessions: [{ SITE_CODE: number, SITE_NAME: string, SESSIONS:  VirtualSession[] }]){
			state.LiveCourses = sessions;
		}
	},
	actions: {
		createGeneralSocket: function(context){
			let socket = new GeneralSocket(context);
			context.commit("setGeneralSocket", socket);
		},
		loadLiveCourses: function(context){
			context.commit("setLoadingLive", true);
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: 'getVirtualSessionsPresenter',
					company_code: context.state.UserData.COMPANY_CODE
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			}).done( (list: VirtualSession[]) => {
				this.Loading = false;
				let sites: Array<any> = []; 
				let siteCodes: Array<number> = [];
				for(let session of list){
					let date: Date = new Date(session.CLASS_DATE);
					let year: number = date.getFullYear();
					let month: string = (1 + date.getMonth())+"";
					let day: string = date.getDate()+"";

					let hours: string = date.getHours()+"";
					hours = hours.length > 1 ? hours : "0" + hours;
					let minutes: string = date.getMinutes()+"";
					minutes = minutes.length > 1 ? minutes : "0" + minutes;
					let seconds: string = date.getSeconds()+"";
					seconds = seconds.length > 1 ? seconds : "0" + seconds;

					let time: string = hours + ":" + minutes + ":" + seconds
					day = day.length > 1 ? day : '0' + day;
					let formattedDate: string = month + "/" + day + "/" + year;

					session.CLASS_DATE = Utils.dateFormat(formattedDate, context.state.UserData.LOCSTATT_DATE_FORMAT_X);
					session.CLASS_TIME = Utils.convert24toAMPM(time);
					let index: number = siteCodes.indexOf(session.SITE_CODE);
					if(index < 0){
						siteCodes.push(session.SITE_CODE);
						sites.push({
							SITE_CODE: session.SITE_CODE,
							SITE_NAME: session.SITE_NAME,
							SESSIONS: [session],
						});
					}else{
						sites[index].SESSIONS.push(session);
					}
				}
				context.commit("setLiveCourses", sites);
			}).fail( (status, errorDesc) => {
				//Materialize.toast("Unable to load facilities list", 3260);
				console.error("Unable to load facilities list", status, errorDesc);
			}).always(() => {
				setTimeout( () => { 
					context.commit("setLoadingLive", false); 
				}, 2000);
			});
		},
		getUserData: function(context){
			return $.ajax({
				method: 'POST',
				url: 'https://locstatt.net/UserSession.cfc?method=getUserInfo',
				dataType: 'json',
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true
			}).done(function(userdata){
				if(userdata && userdata.USER_INFORMATION){
					context.dispatch("setUserData", userdata.USER_INFORMATION[0]);
				}
			});
		},
		setUserData: function(context, userData: UserData){
			context.commit("setUserData", userData);
			context.dispatch("createGeneralSocket");
			context.dispatch("loadLiveCourses");
		},
		getUserLocation: function(context, component){
			return new Promise((resolve, reject) => {
				let locationModal = Vue.extend(LocationModal);
				let instance = new locationModal({
					propsData: { context, resolve, reject }
				});
				instance.$mount();
				document.body.appendChild(instance.$el);
			});
		},

		searchEmployeeByCode: function(context, code: string){
			return $.get({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				data: {
					method: "getEmployeeById",
					emp_code: code,
					company_code: context.state.UserData.COMPANY_CODE,
					emp_first_name: "",
					emp_last_name: ""
				}
			});
		},
		searchEmployeeByName: function(context, name: string){
			return $.get({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				data: {
					method: "getEmployeeByFirstName",
					emp_first_name: name,
					emp_code: "",
					company_code: context.state.UserData.COMPANY_CODE,
					emp_last_name: ""
				}
			});
		},
		searchEmployeeByLastName: function(context, lastName: string){
			return $.get({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				data: {
					method: "getEmployeeByLastName",
					company_code: context.state.UserData.COMPANY_CODE,
					emp_code: "",
					emp_first_name: "",
					emp_last_name: lastName
				}
			});
		},

		logout: function(context){
			return $.ajax({
				method: 'GET',
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				data: { method: 'setLogoutSession' }
			}).done(function(){
				context.commit("setUserData");
			});
		}
	},
	modules: {
		livesocket,
		live
	}
};