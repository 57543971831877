//Main Controllers
import Login from "../controllers/LoginController";
import ClassRooms from "../controllers/ClassRoomsController";
import Live from "../controllers/LiveController";
import History from "../controllers/HistoryController";
import Compliance from "../controllers/ComplianceController";
import Search from "../controllers/SearchController";
import Course from "../controllers/CourseController";
import CourseContent from "../controllers/CourseContentController";
import RequiredTraining from "../controllers/RequiredTrainingController";
import Download from "../controllers/DownloadController";
export default {
	routes: [
		{ path: "/", component: Login, name: "home" },
		{ path: "/trainingHistory", name: "TrainingHistory", component: History},
		{ path: "/compliance", component: Compliance },
		{ path: "/classrooms", name: "classrooms", component: ClassRooms },
		{ path: "/courseContent/:id/:token/:detailid/:openedFromWebApp", name: "CourseContent", component: CourseContent},
		{ path: "/download", component: Download },
		{ path: "/requiredTraining/", name: "RequiredTraining", component: RequiredTraining},
		{ 
			path: "/search", 
			component: Search,
			name: "Search",
			props: function(route){
				return { Query: route.query.q };
			}
		},{ 
			path: "/live/:ClassKey", 
			component: Live, 
			name: "Live", 
			props: function(route){
				return { ClassKey: route.params.ClassKey };
			}
		},{
			path: "/course/:CourseCode",
			component: Course,
			name: "Course",
			props: function(route){
				return { CourseCode: route.params.CourseCode };
			}
		}
	]
};