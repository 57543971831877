import { mapGetters } from "vuex";
export default {
	template: `
		<div id="nav-bar" class="navbar-fixed" :class="{ 'open-drawer': drawer }">
			<nav class="blue z-depth-2">
				<div class="nav-wrapper">
					<ul>
						<li>
							<a @click="ToggleDrawer"><i class="material-icons">menu</i></a>
						</li>
						<li>
							<router-link class="brand-logo" :to="{ path: '/classrooms' }">
								<img src="images/locstatt-logo.png" onerror="this.src='images/user-nopic.jpg';">
							</router-link>
						</li>
					</ul>
					<ul class="right">
						<li>
							<search-bar></search-bar>
						</li>
						<li>
							<a @click="openEmployeeProfileModal">
								<i class="material-icons">account_box</i>
							</a>
						</li>
						<li>
							<a @click="openContactInformationModal">
								<i class="material-icons">help</i>
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	`,
	data: function(){ return { }; },
	methods: {
		ToggleDrawer: function(){
			this.$store.commit("toggleMenuDrawer", !this.$store.state.ShowMenuDrawer);
		},
		openContactInformationModal: function(){
			$("#contact-info-modal").modal("open");
		},
		openEmployeeProfileModal: function(){
			this.$parent.openEmployeeProfileModal();
		}
	},
	computed: {
		...mapGetters(["drawer"])
	}
};