import * as Hammer from "hammerjs";
window.Hammer = Hammer;
import * as $ from "jquery";
window.$ = $;
import Rx from "rxjs";
import * as Vue from "vue";
import * as Vuex from "vuex";
import * as VueRouter from "vue-router";
import * as moment from "moment";

//Setup
import Router from "../components/Router";
import Store from "../store/store";

//Components
import SearchBar from "../components/SearchBar";
import NavBar from "../components/NavBar";
import SideNav from "../components/SideNav"
import CourseTest from "../components/CourseTest";
import Loaders from "../components/Loaders";
import Contact from "../components/Contact";
import Profile from "../components/Profile";
import ImageCropper from "../components/ImageCropper";
import EmployeeSearch from "../components/EmployeeSearch";

import LiveTrainingMenu from "../components/ClassroomMainMenu/Live-Training/LiveTrainingMenu";
import SiteList from "../components/ClassroomMainMenu/Live-Training/SiteList";
import SessionCard from "../components/ClassroomMainMenu/Live-Training/SessionCard";


import CourseLibraryComponent from "../components/ClassroomMainMenu/Online-Training/CourseLibraryComponent";
import CourseCard from "../components/ClassroomMainMenu/Online-Training/CourseCard";
import CoursesRow from "../components/ClassroomMainMenu/Online-Training/CourseRow";
import CourseMessageModal from "../components/ClassroomMainMenu/Online-Training/CourseMessageModal";


//Init setup
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(moment);

let router = new VueRouter(Router);
let store = new Vuex.Store(Store);

router.beforeEach(function(to, from, next){
	if(!store.state.UserData && to.path != "/"){
		store.commit("setLoginRedirect", to.path);
		next("/");
	}else if(store.state.UserData && to.path == '/'){
		next("/classrooms");
	}else{
		next();
	}
});

//Register global components
Object.keys(Loaders).forEach((name: string) => {
	Vue.component(name, Loaders[name]);
});
Vue.component("search-bar", SearchBar);
Vue.component("navbar", NavBar);
Vue.component("sidenav", SideNav)
Vue.component("coursetest", CourseTest);
Vue.component("contact", Contact);
Vue.component("profile", Profile);
Vue.component("image-cropper", ImageCropper);
Vue.component("employee-search", EmployeeSearch);

//Course Library Component
Vue.component("live-menu", LiveTrainingMenu);
Vue.component("site-list", SiteList);
Vue.component("session-card", SessionCard);
Vue.component("course-library", CourseLibraryComponent);
Vue.component("courses-row", CoursesRow);
Vue.component("course-card", CourseCard);
Vue.component("course-message-modal", CourseMessageModal);


//Run after setup
store.dispatch("getUserData").then(() => {
	let App = new Vue({
		router,
		store,
		created: function(){
			window.addEventListener("online", this.onlineEvent);
			window.addEventListener("offline", this.onlineEvent);
		},
		mounted: function(){
			$("#init-screen").remove();
		},
		beforeDestroy: function(){
			window.removeEventListener("online", this.onlineEvent);
			window.removeEventListener("offline", this.onlineEvent);
		},
		methods: {
			onlineEvent: function(ev: Event){
				this.$store.commit("setOnline", ev.type == "online");
			}
		}
	}).$mount("#app");
});