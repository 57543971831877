
require.register("@twilio/webrtc/lib/mediastream.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@twilio/webrtc");
  (function() {
    /* globals MediaStream */
'use strict';

if (typeof MediaStream === 'function') {
  module.exports = MediaStream;
} else {
  module.exports = function MediaStream() {
    throw new Error('MediaStream is not supported');
  };
}
  })();
});