export default {
	template: ` <div>
                    <br><br>
                    <p class="blue-grey-text" v-if="liveCourses.length">Classes by Facilities</p>
                    <div class="row facility-row" v-if="loadingLive">
                        <card-loader></card-loader>
                        <card-loader></card-loader>
                        <card-loader></card-loader>
                    </div>

                    <site-list v-for="site in liveCourses"
                        v-bind:site="site"
                    >

                    </site-list>

                    <div class="row" v-if="!liveCourses.length && !Loading">
                        <h5 class="empty center-align blue-grey-text">
                            <i class="ion-info"></i> No classes taking place at the moment.
                        </h5>
                    </div>
                    <div class="fixed-action-btn">
                        <a class="btn-floating btn-large red waves-effect waves-lighy" @click="reloadLiveSessions">
                            <i class="material-icons">refresh</i>
                        </a>
                    </div>
                </div>`,

    data: function(){
        return{
            
        };
    },

	props:["liveCourses", "loadingLive", "Loading"],

	created: function(){
	},

	mounted: function(){

	},
	methods: {
        reloadLiveSessions: function(){

        }
	}
};