export default {
	template: `<div class="row">
                    <course-card v-for="(course, courseIndex) in courseGroup"
                        v-bind:courseData="course"
                        v-bind:courseType="coursesType"
                    >
                    </course-card>
                    <div class="col s12 pull-s1 center-align" style="margin-top: -15px;">
                        <div class="shelf"></div>
                    </div>
                </div>`,

	props:["courseGroup", "coursesType"],
};