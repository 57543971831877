import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import * as $ from 'jquery';
import * as swal from 'sweetalert2';


export default {
	template: `
	<div id="Login" class="valign-wrapper">
		<div class="card" style="width: 430px; height: 570px;">
			<div class="card-image center-align" style="border-bottom: 1px solid #e1e1e1;">
				<img src="./images/login-screen.jpg">
			</div>
			<loader v-if="loading"></loader>
			<div class="card-content">
				<p class="input-field">
					<i class="material-icons prefix">account_circle</i>
					<input type="text" v-model="UserName">
					<label>User Name</label>
				</p>
				<p class="input-field">
					<i class="material-icons prefix">lock</i>
					<input type="password" v-model="Password" @keyup.enter="login">
					<label>Password</label>
				</p>
				<div class="card-action center-align">
					<button class="btn blue" @click="login">Login</button><br><br>
					<span>Copyright Locstatt © {{year}}</span>
				</div>
			</div>
		</div>
	</div>
	`,
	data: function(){
		return { UserName: "", Password: "", loading: false, year: new Date().getFullYear() };
	},
	methods: {
		login: function(){
			if(this.UserName && this.Password){
				this.loading = true;
				$.ajax({
					method: "POST",
					url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
					data: {
						method: "getClassroomWebAccess",
						username: this.UserName,
						password: this.Password
					},
					xhrFields: {
						withCredentials: true
					},
					crossDomain: true
				}).done((userdata) => {
					this.loading = false;
					

					if (userdata.USER_INFORMATION.length > 0){
						var info = userdata.USER_INFORMATION[0];
						var student_access = info.STUDENT_ACCESS;
						
						if (student_access){
							if(userdata && userdata.USER_INFORMATION.length){
								this.$store.dispatch("setUserData", userdata.USER_INFORMATION[0]);
								if(this.$store.state.LoginRedirect){
									this.$router.push({ path: this.$store.state.LoginRedirect });
									this.$store.commit("setLoginRedirect");
								}else{
									this.$router.push({ path: "classrooms" });	
								}
							}else{
								swal.fire({
									text: "Incorrect UserName/Password. Please try again.",
									icon: "error"
								});
							}
						}else{
							swal.fire({
									text: "You do not have access to enter this site. If you are a Locstatt system user and wish to access the Classroom application, please contact your Supervisor or send an email to contact@locstatt.com.",
									icon: "error"
							});
						}
					}else{
						swal.fire({
								text: "You do not have access to enter this site. If you are a Locstatt system user and wish to access the Classroom application, please contact your Supervisor or send an email to contact@locstatt.com",
								icon: "error"
						});
					}
				}).fail(() => {
					this.loading = false;
					swal.fire({
						text: "Incorrect UserName/Password. Please try again.",
						icon: "error"
					});
				});
			}else{
				Materialize.toast("UserName and Password required.", 3260);
			}
		}
	}
};