export default {
	loader: {
		template: `
			<div class="loader">
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
			</div>
		`
	},
	'card-loader': {
		template: `
			<div class="col s12 m6 l4">
				<div class="card course loading">
					<div class="card-content">
						<div></div><div></div>
						<br>
						<div></div><div></div><div></div>
						<br>
						<div></div><div></div>
					</div>
				</div>
			</div>
		`
	}
};