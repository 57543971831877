import * as Vue from "vue";
import { mapGetters } from "vuex";
import * as VueRouter from "vue-router";
import * as $ from "jquery";
import * as rx from "rxjs";
import { debounce, materialize } from "rxjs/operators";
import * as datepickerFactory from "jquery-datepicker";
import * as datepickerJAFactory from "jquery-datepicker/i18n/jquery.ui.datepicker-ja";
import { VoiceChat } from "../store/live";
import EmployeeSearch from "../components/EmployeeSearch";
import Snackbar from "../components/Snackbar";
import * as swal from "sweetalert2";
let Twilio = require('twilio-video');

export default {
	template: `
	<div id="Live" class="row blue-grey darken-1">

		<loader v-if="loading || !instructor"></loader>
		<employee-search 
			:showList="true"
			:list="employees"
			@add="addEmployee" 
			@remove="removeEmployee" 
			@close="showEmployeeSearch = false" 
			v-if="showEmployeeSearch">
		</employee-search>

		<div id="info-tag" class="col center-align white" v-if="classroom">
			<div class="row">
				<div class="header col s12 center-align" v-bind:style="{ 'background-color': classroom.COVER_COLOR }">
					<img :src="'https://locstatt.net/app/classroomCovers/'+classroom.COVER_IMAGE_NAME" 
					class="responsive-img" 
					error="this.src='images/image-placeholder.png';this.error='';"
					>
				</div>
				<div class="key col s12 orange darken-4 white-text center-align">
					<p class="">Class Key: {{ClassKey.toUpperCase()}}</p>
				</div>
				<div class="instructor col s12 blue darken-1 white-text">
					<p v-if="classroom.INSTRUCTOR_NAME">
						<b>Instructor</b>
						<br>
						{{classroom.INSTRUCTOR_NAME}}
					</p>
					<p v-else>Loading Instructor...</p>
				</div>
				<div class="course col s12">
					<p v-if="classroom.TRAINING_NAME">
						<b>Course</b>
						<br>
						{{classroom.TRAINING_NAME}}
					</p>
					<p v-else>Loading Training...</p>
				</div>
				<div class="evaluation col s12 black white-text center-align" v-if="evaluation && (InstructorScreen || InstructorAudio.TrackId)">
					<p>
						<i class="material-icons orange-text">info_outline</i> Evaluation {{evaluation.Status}}
					</p>
					<p v-if="evaluation.Loading">
						Evaluation Started
						<br>
						Loading details...
					</p>
					<p v-else>
						{{evaluation.test_header.TEST_TITLE}}
						<a class="btn orange darken-4" v-if="evaluation.Enabled" v-on:click="openCourseTests">Take the Test</a>	
					</p>
					
				</div>
				<div class="col s12">
					<p><b>Location</b>
					<br>
					{{currentLocation}}
				</p>
				</div>
				<img src="images/locstatt-logo-black.png" class="logo">
			</div>
		</div>

		<div id="issues-screen" v-if="!online" class="col valign-wrapper no-padding white-text">
			<h4 class="center-align">
				Disconnected
				<br>
				Lost connection with Locstatt servers, waiting to reconnect.
			</h4>
		</div>

		<div id="instructor-screen" ref="instructorScreen" class="col valign-wrapper no-padding" :class="[layout,{ hide: !online }]">
			
			<h4 v-if="connecting">Connecting...</h4>
			<h4 v-else-if="!instructor">Waiting Instructor to return.</h4>
			<div class="video-streams-wrapper" ref="videoStreams" v-else-if="InstructorScreen" key="video"></div>
			<div class="only-audio-wrapper center-align" v-else-if="InstructorAudio.TrackId" key="audio">
				<div class="circle" :style="{ height: (190+InstructorAudio.AudioLevel)+'px', width: (190+InstructorAudio.AudioLevel)+'px' }" v-if="!isMozilla"></div>
				<img class="light-blue lighten-3 center-align responsive-image circle user-picture" :src="getUserpic(classroom.EMP_CODE)">
				<h4 v-if="classroom.INSTRUCTOR_NAME">{{classroom.INSTRUCTOR_NAME}}</h4>
				<h4 v-else>Instructor Talking</h4>
			</div>
			<h4 v-else-if="evaluation">
				<span v-if="evaluation.Loading">
					Evaluation started
					<br>
					Loading details...
				</span>
				<span v-else>
					{{evaluation.test_header.TEST_TITLE}}
					<br>
					Evaluation {{evaluation.Status}}
					<br>	
					<a class="btn orange darken-4" v-if="evaluation.Enabled" v-on:click="openCourseTests">Take the Test</a>
				</span>
				
			</h4>
			<h4 v-else-if="classroom.Completed">
				Session has ended.
			</h4>
			<h4 v-else>
				Standby <img src="images/timer.png"> <br> Please wait for the instructor to start/continue the class.
			</h4>

			<p id="voice-chat-tab" class="white-text z-depth-4" v-if="!connecting && voice" @click="showVoiceChat = true; showMicrophone = true;" :class="microphoneStatus">
				<i class="material-icons">headset_mic</i>
				<span>Voice Chat</span>
			</p>

			<div id="voice-chat" class="card" v-if="!connecting && voice" :class="{ show: showVoiceChat }">
				<div class="card-content">
					<a class="btn-flat small right" @click="showVoiceChat = false">
						<i class="material-icons">close</i>
					</a>
					<span class="card-title">Voice Chat</span>
					<ul class="collection right-align" :class="{ expand: showMicrophone || !voice.Info }">
						<li class="collection-item" v-if="Instructor">
							<a class="btn-floating right z-depth-0" :class="{ 'transparent': !InstructorAudio.TrackId, 'red': InstructorAudio.TrackId && InstructorAudio.Muted, 'green': InstructorAudio.TrackId && !InstructorAudio.Muted }">
								<i class="material-icons black-text" v-if="!InstructorAudio.TrackId">volume_off</i>
								<i class="material-icons" v-else-if="InstructorAudio.Muted">volume_mute</i>
								<i class="material-icons" v-else>volume_up</i>
							</a>
							<p v-if="classroom">
								<span>{{classroom.INSTRUCTOR_NAME}}</span>
								<br>
								<label>Instructor</label>
							</p>
							<div class="audio-bar" v-if="!isMozilla && InstructorAudio.TrackId && !InstructorAudio.Muted">
								<div class="bar" :style="{ width: InstructorAudio.AudioLevel+'%' }"></div>
							</div>
						</li>
						<li class="collection-item" v-for="participant in voice.Participants">
							<a class="btn-floating right z-depth-0" :class="{ 'red': participant.Muted, 'green': !participant.Muted }" v-if="participant.TrackId">
								<i class="material-icons" v-if="participant.Muted">volume_off</i>
								<i class="material-icons" v-else>volume_up</i>
							</a>
							<a class="btn-floating right white z-depth-0" v-else>
								<i class="material-icons black-text">computer</i>
							</a>
							<p>
								<span>{{participant.EMP_NAME}}</span>
								<br>
								<label>{{participant.EMP_CODE}}</label>
							</p>
							<div class="audio-bar" v-if="!isMozilla && !participant.Muted">
								<div class="bar" :style="{ width: participant.AudioLevel+'%' }"></div>
							</div>
						</li>
					</ul>
					<div class="card-action center-align orange darken-4 z-depth-1" v-if="voice.Info">
						<p class="left-align white-text" @click="showMicrophone = !showMicrophone">
							<i class="material-icons" v-if="showMicrophone">keyboard_arrow_down</i>
							<i class="material-icons" v-else>keyboard_arrow_up</i>
							Microphone
						</p>
						<img class="center-align responsive-image circle user-picture" :src="userpic" :class="microphoneStatus">
						<a class="btn-floating small white z-depth-0 waves-effect tooltipped" @click="toggleMute"  v-if="voice.Track" data-position="top" data-tooltip="Toggle Mute">
							<i class="material-icons red-text" v-if="voice.Info.Muted">mic_none</i>
							<i class="material-icons green-text" v-else>mic</i>
						</a>
						<a class="btn-floating small white z-depth-0 waves-effect tooltipped" @click="startMicrophone" v-else data-position="top" data-tooltip="Start Microphone" >
							<i class="material-icons black-text">mic_off</i>
						</a>
						<div class="audio-bar" v-if="!isMozilla && showMicrophoneBar">
							<div class="bar" :style="{ width: voice.Info.AudioLevel+'%' }"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="fixed-action-click-btn" v-show="instructor" :class="{ open: options }" @click="options = !options" ref="options">
				<a class="btn-floating btn-large red">
					<i class="material-icons">more_vert</i>
				</a>
				<ul>
					<li>
						<a class="btn-floating red white-text tooltipped" data-position="left" data-tooltip="Exit Session" @click="exit" ref="exitBtn">
							<i class="material-icons">exit_to_app</i>
						</a>
					</li>
					<li>
						<a href="#add-employee-modal" class="btn-floating green white-text tooltipped modal-trigger" data-position="left" data-tooltip="Participants" @click="showEmployeeSearch = true" refs="employeeBtn">
							<i class="material-icons">group_add</i> 
						</a>
					</li>
				</ul>
			</div>
			<div class="fixed-action-btn" v-show="!instructor">
				<a class="btn-floating btn-large red tooltipped" data-position="left" data-tooltip="Exit Session" @click="exit">
					<i class="material-icons">exit_to_app</i>
				</a>
			</div>
		</div>

		<div id="missing-questions-modal" class="modal" ref="missingQuestionsModal">
			<div class="modal-content">
				<h4>Warning</h4>
				<p>Some questions remain unanswered! Please answer all questions for this test so that it can be evaluated.</p>
			</div>
			<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn blue lighten-1">Got It</a>
			</div>
		</div>


		<div id="submit-test-modal" class="modal" ref="submitTestModal">
			<div class="modal-content">
				<h4>Submit Test</h4>
				<p>Are you sure you want to submit your test? Please ensure all your answers have been revised. After the test has been saved, the score will be set and this may affect your employee training records.</p>
			</div>
			<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn red">No</a>
				<a v-on:click="calculateScoreAndSubmit" class="modal-action modal-close waves-effect waves-green btn blue">Yes</a>
			</div>
		</div>

		<div id="close-test-modal" class="modal" ref="closeTestModal">
			<div class="modal-content">
				<h4>Close Test</h4>
				<p>Are you sure you want to close the test? You will lose all answered questions and will have to start again from the beginning next time you open the test. Do you want to exit?</p>
			</div>
			<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn red">No</a>
				<a v-on:click="closeTestModal" class="modal-action modal-close waves-effect waves-green btn blue">Yes</a>
			</div>
		</div>

		<div id="failed-test-modal" class="modal" ref="failedTestModal">
			<div class="modal-content">
				<h4>Warning</h4>
				<p>You have not reached the minimum score to pass this class.
					Your score right now is {{currentScore}}%. 
					Please check the answers you have submitted and correct them. 
					If you have questions about the class content, please ask your instructor.
				</p>
			</div>
			<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn blue">Got It!</a>
			</div>
		</div>

		<div id="success-test-modal" class="modal" ref="successTestModal">
			<div class="modal-content">
				<h4>Success!</h4>
				<p>Good Job! Your test has been successfully submitted!
				</p>
			</div>
			<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn blue">Got It!</a>
			</div>
		</div>

		<div id="CourseTest" class="modal modal-fixed-footer" ref="courseTestModal">
			<div class="row blue-grey darken-4 white-text" style="height: 40px;position: fixed;width: 100%;z-index: 100000000;"> 
				<h5 class="center" style="margin-bottom: 0px; line-height: 36px; margin-top: 0px;" v-if="evaluation">{{evaluation.TRAINING_NAME}} Tests </h5>
			</div>
			<div class="testTitle" style="background-color: rgb(255, 215, 58); height: 50px; margin-top: 40px;position: fixed;marker: ;width: 100%;z-index: 100000000;">
				<h6 style="margin-left:30px;line-height: 20px;" v-if="evaluation">This is the test for course {{evaluation.TRAINING_CODE}}. Please answer all the questions and select “Submit” at the bottom. The minimun score required for this course is {{classroom.TRAINING_SCORE}}%.</h6>
			</div>

			<div class="modal-content" style="margin-top: 90px;height: calc(100% - 160px)">
				<loader v-if="Loading"></loader>
				<div class="testsInfo" style="margin-top: -80px;">
					<coursetest
						v-if="CourseTest"
						v-bind:CourseTest="CourseTest" 
						ref="test"
					>
					</coursetest>
				</div>
				
			</div>

			<div class="modal-footer">
				<a v-on:click="openCloseWarning" class="red white-text btn" style="margin-right:10px;">Close</a>
				<a v-on:click="checkTest" class="blue lighten-1 white-text btn" style="margin-right:10px;">Submit Test</a>
			</div>
		</div>

	</div>
	`,
	props: ["ClassKey"],
	data: function(){
		return {
			//Test Attributes
			currentScore: 0,
			CourseTest: {},

			//Essential Session Info
			Room: null,
			testEnabled: false,

			//Employee Register Modal
			showEmployeeSearch: false,

			//Twilio Instructor status
			Instructor: null,
			InstructorScreen: false,
			InstructorAudio: {
				AudioLevel: 0,
				EMP_CODE: "",
				EMP_NAME: "",
				Muted: false,
				TrackId: ""
			},

			//Video Layouts
			VideoTracks: {},
			VideoTracksObserver: null,

			//Voice Chat
			showVoiceChat: true,
			showMicrophone: true,
            AudioStatsTracker: false,

			//Whole Test
			test_string: "",
			test_score: 0,
			test_approved: 0,

			//Status
			Loading: false,
			options: false,
			connecting: true,
			loading: false,
			startingDate: "",
			endingdate: "",
			StoreUnWatchs: [],
			disconnectSnackbar: null
		}
	},
	created: function(){
		this.loading = true;

		if(!this.ClassKey){
			Materialize.toast('Class Key required to continue.', 3260);
			this.$router.push({ 
				path: '/classrooms'
			});
			return;
		}

		this.$store.dispatch("live/create", this.ClassKey)
		.then(() => {
			rx.Observable
			.create((obs) => {
				this.VideoTracksObserver = obs;
			})
			.debounceTime(750)
			.subscribe(() => {
				this.organizeScreens();
			});
			return this.connectTwilio();
		}).then(() => {
			this.loading = false;
			this.InstructorScreen = !!this.streams.length;
			this.StoreUnWatchs.push(this.$store.subscribe((mutation, state) => {
				if(
					mutation.type == "live/setInstructorStreams"
					|| mutation.type == "live/addInstructorStream"
					|| mutation.type == "live/removeInstructorStream"
				){
					this.InstructorScreen = !!state.live.InstructorStreams.length;
					this.VideoTracksObserver.next();
				}else if(
					(mutation.type == "livesocket/setError" && state.livesocket.Error)
					||	(mutation.type == "livesocket/setClosed" && state.livesocket.Closed && !state.live.Completed)
				){
					this.disconnect();
				}
			}));
			this.StoreUnWatchs.push(this.$store.watch(
				(state, getters) => { return getters["online"]; },
				(newValue, oldValue) => {
					if(newValue && !oldValue){
						this.$store.dispatch("live/reconnect")
						.then(() =>{
							return this.connectTwilio();
						})
						.then(() => {
							this.loading = false;
						})
						.catch(() => {
							this.$store.dispatch("live/end");
							if(this.disconnectSnackbar){
								this.disconnectSnackbar.hide();
								this.disconnectSnackbar = null;
							}
							this.$router.push({ path: "/classrooms" });	
						});
					}else if(!newValue && oldValue){
						this.disconnect();
					}
				}
			));
			this.StoreUnWatchs.push(this.$store.watch(
				(state, getters) => { return getters["live/completed"]; },
				(newValue, oldValue) => {
					if(newValue){
						swal.fire({
							title: "Session Ended",
							text: "The Instructor has ended the live session.",
							icon: "info",
							button: "Got it!",
						}).then( () => {
							this.$store.dispatch("live/changeSessionStatus", { status: 10453,  test: this.test_string, score: this.test_score});
							this.leave();
						});
					}
				}
			));
			this.$store.dispatch("live/changeSessionStatus", { status: 10000 });
		})
		.catch((msg: string) => {
			console.error(msg);
			Materialize.toast(msg, 3260);
			if(this.disconnectSnackbar){
				this.disconnectSnackbar.hide();
				this.disconnectSnackbar = null;
			}
			this.$router.push({ 
				path: "/classrooms"
			});
		});
	},
	beforeDestroy: function(){
		$(this.$el).find(".tooltipped").tooltip("remove");
		for(let func of this.StoreUnWatchs){
			func();
		}
		if(this.$store.state.live.Classroom){
			this.disconnect(true);
			this.$store.dispatch("live/end");
		}
	},
	computed: {
		...mapGetters(["online", "userpic", "isMozilla"]),
		...mapGetters("live", ["classroom","token","instructor","location","streams","evaluation","employees","voice","mainApp","location","completed"]),

		layout: function(){
			let layout: number = this.$store.getters["live/layout"];
			return [
				'primary-secondary', 'secondary-primary',
				'focus-primary', 'focus-secondary'
			][layout];
		},

		currentLocation: function(){
			let loc = this.location;
			if(loc){
				return loc.SITE_NAME+' / '+loc.LOCATION_NAME;
			}
			return null;
		},

		showMicrophoneBar: function(){
			return this.voice.Track && !this.voice.Info.Muted && this.showMicrophone;
		},

		microphoneStatus: function(){
			if(this.voice.Track){
				if(!this.voice.Info.Muted){
					return "talking";
				}else{
					return "muted";
				}	
			}
			return "disconnected";
		}

	},
	methods: {

		getUserpic: function(empCode){
			if(empCode){
				let stringCode = empCode.toString();
				return "https://locstatt.net/app/employeePhotos/"+(stringCode.slice(-2))+"/"+(stringCode.slice(0,6))+".jpg";
			}else{
				return "/images/image-placeholder.png";
			}
		},

		setQuestionAsAnswered: function(index, questionIndex){

			
			//var header_id = this.CourseTest.test_header.CLASSROOM_TEST_HEADER_ID;
			var section = this.CourseTest.test_sections[index];
			var question = this.CourseTest.test_sections[index].QUESTIONS[questionIndex];
			var section_id = this.CourseTest.test_sections[index].CLASSROOM_TEST_SECTION_ID;
			var question_id = this.CourseTest.test_sections[index].QUESTIONS[questionIndex].CLASSROOM_TEST_QUESTION_ID;

			var answers = [];

			
			
			
			if !(question.ANSWERED){
				question.ANSWERED = true;
				
				section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) + 1;
			}

			if (question.QUESTION_TYPE_ID === 10844 || question.QUESTION_TYPE_ID === 10633){ //Single Choice or Yes No Question
				var checked = $(`input[name='question-${index}-${questionIndex}']:checked`).val();
				answers = [{answer_value: `${checked}`}];

			}

			if (question.QUESTION_TYPE_ID === 12532){
				
			}

			if (question.QUESTION_TYPE_ID === 10422 || question.QUESTION_TYPE_ID === 12110){
				var checked = $(`input.question-${index}-${questionIndex}:checked`);
				if(checked.length === 0){
					if (question.ANSWERED){
						question.ANSWERED = false;
						section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
					}
				}
			}


			//Barcode
			if(question.QUESTION_TYPE_ID === 11688){
				if ($(`#barcode-question-${index}-${questionIndex}`).val().length === 0){
					if (question.ANSWERED){
						question.ANSWERED = false;
						section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
					}

				}
			}

			//Integer
			if(question.QUESTION_TYPE_ID === 10211){
				if ($(`#integer-question-${index}-${questionIndex}`).val().length === 0){
					if (question.ANSWERED){
						question.ANSWERED = false;
						section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
					}

				}
			}
			//Short Answer
			if(question.QUESTION_TYPE_ID === 10000 || question.QUESTION_TYPE_ID === 11899){
				if ($(`#short-answer-question-${index}-${questionIndex}`).val().length === 0){
					if (question.ANSWERED){
						question.ANSWERED = false;
						section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
					}

				}
			}
			
			this.$store.dispatch("livesocket/sendEvaluationAnswer", {
				section: section_id,
				question: question_id,
				answers: answers
			});
		},

		getTotalScoreFromTest: function(){
			var sections = this.CourseTest.test_sections;
			var totalScore = 0;
			for(var sectionIndex in sections){
				var section = sections[sectionIndex];
				var questions = section.QUESTIONS;
				for (var qIndex in questions){
					var question = questions[qIndex];
					var typeID = question.QUESTION_TYPE_ID;
					switch(typeID){
						case 10422: //Multiple Choice
						case 12110: //Multiple Choice
						case 10633: // Yes No Or Single Choice
						case 10844: //Yes No Or Single Choice
						case 12532: //Matching 
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var choices = structure[0].CHOICES;
							for(var choiceIndex in choices){
								var choice = choices[choiceIndex];
								totalScore += parseInt(choice.SCORE);
							}

							break;

						default: 


					}
				}
				
			}
			return totalScore;
		},

		calculateScoreAndSubmit: function(){
			this.Loading = true;
			var maximumTestScore = parseInt(this.getTotalScoreFromTest()); // This calculates the total maximum score
			var sections = this.CourseTest.test_sections
			var totalScore = 0;
			var test_object = {};
			var sections_array = [];
			for(var sectionIndex in sections){
				var section = sections[sectionIndex];
				var questions = section.QUESTIONS;
				var questions_array = [];
				for (var questionIndex in questions){
					var answers_array = [];
					var question = questions[questionIndex];
					var typeId = question.QUESTION_TYPE_ID;
					switch(typeId){
						case 10000: //Short Answer
						case 11899: //Short Answer
							//var structure = question.QUESTION_STRUCTURE;
							//structure = JSON.parse(structure);
							//structure = JSON.parse(structure);
							var answer_object = {
								"value": $(`#short-answer-question-${sectionIndex}-${questionIndex}`).val(),
								"score": "",
								"order": 0,
								"index": 0
							}
							answers_array.push(answer_object);
							//structure[0].USER_ANSWER = $(`#short-answer-question-${sectionIndex}-${questionIndex}`).val();
							//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(structure));
							break;
						case 10211: //Integer

							var answer_object = {
								"value": $(`#integer-question-${sectionIndex}-${questionIndex}`).val(),
								"score": "",
								"order": 0,
								"index": 0
							}

							answers_array.push(answer_object);
							break;
						case 10422: // Multiple Choice
						case 12110: //Multiplce Choice
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var choices = structure[0].CHOICES;
							var order = 0;
							$(`input:checkbox[name=question-${sectionIndex}-${questionIndex}]:checked`).each(function(){
								var choiceIndex = parseInt($(this).attr("data-choiceindex"));
								var choice_score = "";
								if (!isNaN(choiceIndex)){
									if (choices[choiceIndex].SCORE !== undefined){
										choice_score = parseInt(choices[choiceIndex].SCORE);
										totalScore += (parseInt(choices[choiceIndex].SCORE)/maximumTestScore)*100;
									}else{
										choice_score = "";
									}
									answers_array.push(
										{
											"value": choices[choiceIndex].VAL,
											"score": choice_score,
											"order": order,
											"index": parseInt(choices[choiceIndex].I)
										}
									);
								}
								order++;
							});
							//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(choices));
							break;
						case 10633: //Yes No or Single Choice
						case 10844: //Yes No or Single Choice
							var choiceIndex = parseInt($(`input[name=question-${sectionIndex}-${questionIndex}]:checked`).attr("data-choiceindex"));
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var choices = structure[0].CHOICES;

							var choice_score = "";
							if (!isNaN(choiceIndex)){
								if (choices[choiceIndex].SCORE !== undefined){
									choice_score = parseInt(choices[choiceIndex].SCORE);
									totalScore += (parseInt(choices[choiceIndex].SCORE)/maximumTestScore)*100;
									//choices[choiceIndex].USER_SELECTED = true;
								}else{
									choice_score = "";
								}

								answers_array.push(
									{
										"value": choices[choiceIndex].VAL,
										"score": choice_score,
										"order": 0,
										"index": parseInt(choices[choiceIndex].I)
									}
								);
								//question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(choices));
							}
							break;
						case 11055: //Enumeration
						case 12321: //Enumeration
							break;
						case 11477: //Date
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);

							if(structure[0].IS_RANGE){
								structure[0].USER_ANSWER_MIN_DATE = $(`#start-date-range-question-${sectionIndex}-${questionIndex}`).val();
								structure[0].USER_ANSWER_MAX_DATE = $(`#end-date-range-question-${sectionIndex}-${questionIndex}`).val();
							}else{
								structure[0].USER_ANSWER_DATE = $(`#date-question-${sectionIndex}-${questionIndex}`).val()
							}

							question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(structure));

							break;
						case 11688: //Barcode
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							structure[0].USER_ANSWER = $(`#barcode-question-${sectionIndex}-${questionIndex}`).val();
							question.QUESTION_STRUCTURE = JSON.stringify(JSON.stringify(structure));

							break;
						case 12532:
							var structure = question.QUESTION_STRUCTURE;
							structure = JSON.parse(structure);
							structure = JSON.parse(structure);
							var originalChoices = structure[0].CHOICES;
							var choicesSelected = []
							var choicesSelectedDOM = $(`#matching-question-${sectionIndex}-${questionIndex}-1`).children().toArray();
							choicesSelectedDOM.forEach((option, index) => {
								let originalIndex = parseInt(option.attributes["data-originalindex"].value);
								let answer = {
									value: option.attributes["data-answer"].value,
									score: parseInt(originalChoices[originalIndex].SCORE),
									order: originalIndex,
									index: index
								};
								answers_array.push(answer);
								choicesSelected.push(answer);
							});
							if (structure[0].GLOBAL_SCORE !== 0){
								let allValid = true;
								if (choicesSelected.length){
									for(let option of choicesSelected){
										if(option.order != option.index){
											allValid = false;
											break;
										}
									}
									if (allValid){
										totalScore += (parseInt(structure[0].GLOBAL_SCORE)/maximumTestScore)*100;
									}
								}
							}else{
								for(let index in choicesSelected){
									if (JSON.stringify(choicesSelected[index]) === JSON.stringify(originalChoices[index])){
										totalScore += (parseInt(choicesSelected[index].SCORE)/maximumTestScore)*100;
									}
								}

							}
							var selected = {CHOICES:choicesSelected};
							question.USER_INPUT_STRUCTURE = JSON.stringify(JSON.stringify(selected));
							break;
						default:
							console.error("Undefined question type");
					}


					var structure = question.QUESTION_STRUCTURE;
					structure = JSON.parse(structure);
					structure = JSON.parse(structure);

					var isDefault = false;
					var globalScore = 0;
					var hasGlobalScore = false;
					var hasScore = false;
					var isScorable = false;


					if(structure[0].DEFAULT){
						isDefault = true;
					}

					if(structure[0].GLOBAL_SCORE){
						globalScore = structure[0].GLOBAL_SCORE
					}

					if(structure[0].HAS_GLOBAL_SCORE){
						hasGlobalScore = true;
					}

					if(structure[0].HAS_SCORE){
						hasScore = true;
					}

					if(structure[0].SCORABLE){
						isScorable = true;
					}

					questions_array.push(
						{	
							"structure": {
								"answers": answers_array,
								"isDefault": isDefault,
								"globalScore": globalScore,
								"hasGlobalScore": hasGlobalScore,
								"hasScore": hasScore,
								"isScorable": isScorable
							},
							"required": question.REQUIRED,
							"id": question.CLASSROOM_TEST_QUESTION_ID,
							"type": question.QUESTION_TYPE_ID,
							"order": question.QUESTION_ORDER,
							"sectionId": question.CLASSROOM_TEST_SECTION_ID
						}
					);
				}

				sections_array.push({
					"hasScore": 0,
					"id": section.CLASSROOM_TEST_SECTION_ID,
					"order": section.SECTION_ORDER,
					"questions": questions_array
				})
			}

			test_object = {
					"id": this.evaluation.test_header.CLASSROOM_TEST_HEADER_ID,
					"training_code": this.classroom.TRAINING_CODE_ID,
					"sections": sections_array
			}

			totalScore = Math.round(totalScore);
			this.userScore = totalScore;
			var hasPassed = 0;
			if (totalScore >= this.classroom.TRAINING_SCORE){
				hasPassed = 1;
			}
			this.currentScore = totalScore;
			if (hasPassed){
				$.ajax({
					url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
					method: 'POST',
					data: {
						method: "setNewEmployeeOnlineTestInfo",
						training_online_header_id: this.evaluation.test_header.CLASSROOM_TEST_HEADER_ID,
						test_answer_structure: JSON.stringify(test_object),
						score: totalScore,
						creation_date: this.startingDate,
						end_date: this.getTodaysDate(), 
						approved: hasPassed
					}
				}).done( (data) => {
					this.test_string = JSON.stringify(test_object);
					this.test_score = totalScore;
					this.test_approved = hasPassed;
					this.$store.dispatch("live/changeSessionStatus", { status: 12718, test: JSON.stringify(test_object), score: totalScore });
					this.$store.dispatch("live/sendFinishEvaluation");
					$(this.$refs.courseTestModal).modal("close");
					$(this.$refs.successTestModal).modal("open");
				}).fail((status, errorDesc) => {
					console.error(status, errorDesc);
				}).always(() =>{
					this.Loading = false;
				}); 
			}else{
				$(this.$refs.failedTestModal).modal("open");
				this.Loading = false;
			}
		},

		getTodaysDate: function(){
			var today = new Date();
			var dd = today.getDate().toString();
			var mm = (1 + today.getMonth()).toString(); //January is 0!
			var yyyy = today.getFullYear();

			var hours = today.getHours();
			var minutes = today.getMinutes().toString();
			minutes = minutes.length > 1 ? minutes : '0' + minutes;
			var seconds = today.getSeconds().toString();
			seconds = seconds.length > 1 ? seconds : '0' + seconds;
			var milliSeconds = today.getMilliseconds();

			if(dd<10) {
			    dd = '0'+dd
			} 
			if(mm<10) {
			    mm = '0'+mm
			} 
			today = mm + '/' + dd + '/' + yyyy + ' ' + hours + ':' + minutes + ':' + seconds;
			return today;
		},

		checkIfAllIsAnswered: function(){
			var totalQuestions = 0;
			var totalAnsweredQuestions = 0;
			for(var sectionIndex in this.CourseTest.test_sections){
				var section = this.CourseTest.test_sections[sectionIndex];
				for (var questionIndex in section.QUESTIONS){
					totalQuestions++;
					if (section.QUESTIONS[questionIndex].ANSWERED){
						totalAnsweredQuestions++;
					}
				}
			}
			return totalQuestions === totalAnsweredQuestions;
		},

		checkTest: function(){
			if (this.checkIfAllIsAnswered()){
				$(this.$refs.submitTestModal).modal("open");
			}else{
				$(this.$refs.missingQuestionsModal).modal("open");
			}
		},

		//Course Info
		openCourseTests: function(){
			let testCopy: Evaluation = Object.assign({}, this.evaluation); 
			this.openModalWithCompleteTest(testCopy);
		},

		openModalWithCompleteTest: function(data){
			this.startingDate = this.getTodaysDate();
			let testModal: JQuery<Element> = $(this.$refs.courseTestModal);
			
			for(var sectionIndex = 0; sectionIndex < data.test_sections.length; sectionIndex++){
				Vue.set(data.test_sections[sectionIndex], 'QUESTIONS_ANSWERED', 0);
				for(var questionIndex = 0; questionIndex < data.test_sections[sectionIndex].QUESTIONS.length; questionIndex++){
					let typeID = data.test_sections[sectionIndex].QUESTIONS[questionIndex].QUESTION_TYPE_ID;
					let structure = data.test_sections[sectionIndex].QUESTIONS[questionIndex].QUESTION_STRUCTURE;
					Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex], "section_index", sectionIndex);
					Vue.set(data.test_sections[sectionIndex].QUESTIONS[questionIndex], "question_index", questionIndex);

					data.test_sections[sectionIndex].QUESTIONS[questionIndex].ANSWERED = false;
					if (
						typeID === 10844 
						|| typeID === 10633 
						|| typeID === 10422 
						|| typeID === 12110 
						|| typeID === 12532
					){
						let struct = JSON.parse(structure)
						struct = JSON.parse(struct);
						let choices = struct[0].CHOICES;
						if (typeID === 12532){
							for (let i = choices.length -1; i > 0; i--){
								let j = Math.floor(Math.random() * (i+1));
								let temp = choices[i];
								choices[i] = choices[j];
								choices[j] = temp;
							}
						}
						struct.CHOICES = choices;
						data.test_sections[sectionIndex].QUESTIONS[questionIndex].STRUCTURE_PARSED = struct;
					}

					if(typeID === 11477){
						let struct = JSON.parse(data.test_sections[sectionIndex].QUESTIONS[questionIndex].QUESTION_STRUCTURE);
						struct = JSON.parse(struct);
						data.test_sections[sectionIndex].QUESTIONS[questionIndex].STRUCTURE_PARSED = struct[0];
					}
				}
			}
			
			
			this.CourseTest = data;
			this.$store.dispatch("live/changeSessionStatus", { status: 12265 });
			this.$nextTick(() => {
				testModal.find(".collapsible").collapsible();
				for(let sIndex in this.CourseTest.test_sections){
					for(let qIndex in this.CourseTest.test_sections[sIndex].QUESTIONS){
						let typeID = this.CourseTest.test_sections[sIndex].QUESTIONS[qIndex].QUESTION_TYPE_ID;
						if(typeID === 12532){
							let id1 = "#matching-question-"+sIndex+"-"+qIndex+"-1";
							let id2 = "#matching-question-"+sIndex+"-"+qIndex+"-2";
							let connect = ".connect-lists-"+sIndex+"-"+qIndex;
							testModal.find(id1)
							.sortable({
					      		connectWith: connect,
					      		remove: (event, ui) => {
					      			let sectionIndex = ui.item.attr('data-sindex');
					      			let questionIndex = ui.item.attr('data-qindex');
					      			let section = this.CourseTest.test_sections[sectionIndex];
					      			let question = section.QUESTIONS[questionIndex];
					      			let structure = question.QUESTION_STRUCTURE;
					      			structure = JSON.parse(structure);
					      			structure = JSON.parse(structure);
					      			let choices = structure[0].CHOICES;
					      			if ($(id1)[0].childNodes.length < choices.length){
					      				if(question.ANSWERED){
					      					question.ANSWERED = false;
					      					section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) - 1;
					      				}
					      			}
					      		},
					      		update: (event, ui) => {
					      			let choices = $(id1)[0].childNodes;
					      			let sectionIndex = ui.item.attr('data-sindex');
					      			let questionIndex = ui.item.attr('data-qindex');
					      			let section = this.CourseTest.test_sections[sectionIndex];
					      			let question = section.QUESTIONS[questionIndex];
					      			let structure = question.QUESTION_STRUCTURE;
					      			structure = JSON.parse(structure);
					      			structure = JSON.parse(structure);
					      			let all_choices = structure[0].CHOICES;
					      			let answers = [];
					      			if (choices.length === all_choices.length){
					      				if !(question.ANSWERED){
					      					question.ANSWERED = true;
						      				section.QUESTIONS_ANSWERED = parseInt(section.QUESTIONS_ANSWERED) + 1;
						      			}
						      			for(var i = 0;i < choices.length; i++){
							      			answers.push({
							      				answer_value: choices[i].innerText,
							      				answer_order: i
							      			});
							      		}
							      		this.$store.dispatch("livesocket/sendEvaluationAnswer", {
											section: section.CLASSROOM_TEST_SECTION_ID,
											question: question.CLASSROOM_TEST_QUESTION_ID,
											answers: answers
							      		});
						      		}
					      		}
					    	}).disableSelection();
					    	testModal.find(id2)
					    	.sortable({
					      		connectWith: connect,
					      		
					    	}).disableSelection();
						}
					}
				}
				testModal.find(".datepick")
				.datepicker({
					onSelect: function(text, ui){ }
				});
			});
			testModal.modal("open");
		},

		openCloseWarning: function(){
			$(this.$refs.closeTestModal).modal("open");
		},

		closeTestModal: function(){
			this.CourseTest = null
			$(this.$refs.courseTestModal).modal("close");
			this.$store.dispatch("livesocket/cancelEvaluation");
			this.$store.dispatch("live/changeSessionStatus", { status: 10000 });
		},

		connectTwilio: function(){
			return Twilio.connect(
				this.token,
				{ 
					name: this.classroom.CLASSROOM_STRING_ID,
					audio: false,
					video: false
				}
			).then((room) => {
				this.loading = false;
				this.connecting = false;
				Materialize.toast("Connected",3260);
				this.Room = room;

				this.$nextTick(() => {
					datepickerFactory($);
					$(this.$el).find(".tooltipped").tooltip();
					$(this.$refs.courseTestModal).modal({
						dismissible: false,
						opacity: 1.0,
					});
					$(this.$refs.missingQuestionsModal).modal();
					$(this.$refs.submitTestModal).modal();
					$(this.$refs.closeTestModal).modal();
					$(this.$refs.failedTestModal).modal();
					$(this.$refs.successTestModal).modal();
				});

				room.participants.forEach((p) => {
					if(p.identity == this.classroom.INSTRUCTOR_ID){
						this.handleInstructor(p);
					}else{
						this.handleParticipant(p);
					}
				});
				room.on("participantConnected", (participant) => {
					if(participant.identity == this.classroom.INSTRUCTOR_ID){
						this.handleInstructor(participant);
					}else{
						this.handleParticipant(participant);
					}
				});
				room.on("participantDisconnected", (participant) => {
					if(participant.identity == this.classroom.INSTRUCTOR_ID){
						this.$set(this,"Instructor", null);
					}else{
						this.$store.commit("live/removeVoiceChatParticipant", participant.identity);
					}
					participant.tracks.forEach((t) => {
						t.detach();
						let ele: HTMLElement | null = document.getElementById(t.sid);
						if(ele) {
							ele.remove();
						}
					});
				});
				room.on("disconnected", function(room, error){
					if(error){
						console.error("Twilio Disconnect ", error.message);
						this.disconnect();
					}
				});

				this.startMicrophone();
				this.startAudioStatsTracker();
			}, (err) => {
				if(err.code == 20104 || err.message == "Access Token expired or expiration date invalid"){
					this.$store.dispatch("getToken")
					.then((token) => {
						this.$store.commit("live/setToken", token);
					})
					.catch((err) => {

					});
				}
			});
		},
		handleParticipant: function(participant){

			let voiceParticipant: VoiceParticipant = {
				AudioLevel: 0,
				EMP_CODE: participant.identity,
				EMP_NAME: ""+participant.identity,
				Muted: true,
				TrackId: ""
			};
			
			this.$store.dispatch("searchEmployeeByCode", participant.identity)
			.then((emps: Array<Employee>) => {
				if(emps.length){
					this.$store.commit("live/setVoiceChatParticipantName", {
						code: emps[0].EMP_CODE,
						name: emps[0].EMP_FIRST_NAME+" "+emps[0].EMP_LAST_NAME
					});
				}
			});

			if(participant.audioTracks.length){
				let track = participant.audioTracks[0];
				let te: HTMLMediaElement = track.attach();
				te.setAttribute("id", track.sid);
				this.$el.append(te);
				voiceParticipant.TrackId = track.sid;
				voiceParticipant.Muted = !track.isEnabled;
			}
			
			/*
			participant.on("trackPublished", (track) => {
				
			});
			*/
			participant.on("trackSubscribed", (track) => {
				if(track.kind == "audio"){
					let te: HTMLMediaElement = track.attach();
					te.setAttribute("id", track.sid);
					this.$el.append(te);
					this.$store.commit("live/setVoiceChatParticipantTrack", { 
						code: participant.identity,
						track: track.sid
					});
					this.$store.commit("live/setVoiceChatParticipantMute", {
						code: participant.identity,
						muted: false
					});
				}
			});
			participant.on("trackEnabled", (track) => {
				this.$store.commit("live/setVoiceChatParticipantMute", { 
					code: participant.identity, 
					muted: false 
				});
			});
			participant.on("trackDisabled", (track) => {
				this.$store.commit("live/setVoiceChatParticipantMute", { 
					code: participant.identity, 
					muted: true 
				});
			});
			participant.on("trackUnsubscribed", (track) => {
				track.detach();
				let trackElem: HTMLElement | null = document.getElementById(track.sid);
				if(trackElem){
					trackElem.remove();
				}
				this.$store.commit("live/setVoiceChatParticipantTrack", { 
					code: participant.identity, 
					track: "" 
				});
			});
			this.$store.commit("live/addVoiceChatParticipant", voiceParticipant);
		},
		handleInstructor: function(participant){
			this.$set(this, "Instructor", participant);
			participant.tracks.forEach(this.handleInstructorTrack);
			participant.on("trackSubscribed", this.handleInstructorTrack);
			participant.on("trackEnabled", (track) => {
				this.$set(this.InstructorAudio, "Muted", false);
			});
			participant.on("trackDisabled", (track) => {
				this.$set(this.InstructorAudio, "Muted", true);
			});
			participant.on("trackUnsubscribed", (track) => {
				let trackElem: HTMLElement | null = document.getElementById(track.name);
				if(trackElem != null) {
					trackElem.remove();
				}
				track.detach();
				if(track.kind == "video"){
					delete this.VideoTracks[track.name];
					this.VideoTracksObserver.next();
				}else{
					let enabledTracks: number = 0;
					participant.audioTracks.forEach(function(t){
						if(t.isEnabled){
							enabledTracks++;
						} 
					});
					if(enabledTracks == 0){
						this.InstructorAudio = {
							AudioLevel: 0,
							EMP_CODE: "",
							EMP_NAME: "",
							Muted: false,
							TrackId: ""
						};
					}
				}
			});
		},
		handleInstructorTrack: function(track: any){
			if(!track.isEnabled){ return; }
			let te: HTMLMediaElement = track.attach();
			te.setAttribute("id", track.name);
			if(track.kind == "video"){
				this.VideoTracks[track.name] = te;
				this.VideoTracksObserver.next();
			}else{
				this.$refs.instructorScreen.append(te);
				this.$set(this.InstructorAudio, "TrackId", track.sid);
				if(!this.AudioStatsTracker){
					this.startAudioStatsTracker();
				}
			}
		},
		organizeScreens: function(){
			if(this.streams.length){
				if(this.$refs.videoStreams){
					for(let stream of this.streams){
						if(!document.getElementById(stream.id)){
							this.$refs.videoStreams.append(this.VideoTracks[stream.id]);
						}
					}
				}else{
					return this.$nextTick(() => { this.VideoTracksObserver.next(); });
				}
			}
			if(this.streams.length == 1){
				if(this.VideoTracks[this.streams[0].id]){
					this.VideoTracks[this.streams[0].id].classList = ["only-screen"];
				}
			}else{
				for(let stream of this.streams){
					if(this.VideoTracks[stream.id]){
						this.VideoTracks[stream.id].classList = [stream.type == 1 ? "first-video-screen" : "second-video-screen"];
					}
				}	
			}
		},
		startAudioStatsTracker: function(){
			if(this.online && this.Room && !this.AudioStatsTracker){
				this.AudioStatsTracker = true;
				requestAnimationFrame(this.trackAudioStats);
			}
		},
		trackAudioStats: function(){
			if(this.online && this.Room){
				this.Room.getStats().then((stats) => {
					if(stats.length){
						this.$store.dispatch("live/handleAudioStats", stats[0]);
						if(this.InstructorAudio.TrackId && !this.InstructorAudio.Muted){
							for(let stat of stats[0].remoteAudioTrackStats){
								if(stat.trackSid == this.InstructorAudio.TrackId){
									this.$set(this.InstructorAudio, "AudioLevel", (stat.audioLevel/32767)*100);
									break;
								}
							}
						}
						requestAnimationFrame(this.trackAudioStats);
					}else{
						this.AudioStatsTracker = false;
					}
				}).catch(console.error);
			}
		},

		toggleMute: function(){
			Materialize.toast(this.voice.Info.Muted ? "Microphone unmuted" : "Microphone muted", 3260);
			this.$store.dispatch("live/setMicMute", !this.voice.Info.Muted);
		},
		startMicrophone: function(){
			this.$store.dispatch("live/startVoiceChat")
			.then((micTrack: MediaStreamTrack) => {
				let track = new Twilio.LocalAudioTrack(micTrack);
				this.$store.commit("live/setMicTrack", track);
				return this.Room.localParticipant.publishTrack(track);
			})
			.then((publication) => {
				Materialize.toast("Connected to voice chat.", 3260);
			})
			.catch((err) => {
				this.$store.dispatch("live/stopVoiceChat");
				this.showVoiceChat = false;
				console.error("Voice chat start error: ", err.message);
				let _ = new Snackbar({
					propsData: {
						message: "Unable to start voice chat",
						time: 3260,
						actionStr: "Retry",
						action: () => {
							this.startMicrophone();
						}
					});
			});
		},


		//Employee Search Events
		addEmployee: function(emp: Employee){
			this.$store.dispatch("live/addEmployee", emp)
			.then((msg) => {
				Materialize.toast(msg, 3260);
			})
			.catch((msg) => {
				console.error(msg);
				Materialize.toast(msg, 3260);
			});
		},
		removeEmployee: function(emp: Employee){
			this.$store
			.dispatch("live/removeEmployee", emp)
			Materialize.toast("Employee removed.", 3260);
		},

		disconnect: function(exit){
			this.loading = true;
			this.connecting = true;
			this.showEmployeeSearch = false;
			$(this.$refs.courseTestModal).modal("close");
			$(this.$refs.missingQuestionsModal).modal("close");
			$(this.$refs.submitTestModal).modal("close");
			$(this.$refs.closeTestModal).modal("close");
			$(this.$refs.failedTestModal).modal("close");
			$(this.$refs.successTestModal).modal("close");
			if(this.Instructor){
				this.Instructor.tracks.forEach((t) => {
					let trackElem: HTMLElement | null = document.getElementById(t.name);
					if(trackElem){
						trackElem.remove();
					}
					t.detach();
				});
				this.Instructor.removeAllListeners();
			}
			if(this.Room){
				this.Room.removeAllListeners();
				this.Room.participants.forEach((p) => {
					p.removeAllListeners();
					p.tracks.forEach((t) => {
						let trackElem: HTMLElement | null = document.getElementById(t.name);
						if(trackElem){
							trackElem.remove();
						}
						t.detach();
					});
				});
				this.Room.disconnect();
				this.Room = null;
			}
			if(this.streams.length){
				if(this.$refs.videoStreams){
					for(let stream of this.streams){
						let elem: HTMLElement | null = document.getElementById(stream.id);
						if(elem) {
							elem.remove();
						}
						if(this.VideoTracks[stream.id]) {
							this.VideoTracks[stream.id].remove();
						}
					}
				}
			}
			this.$store.commit("live/setInstructorStreams", []);
			this.VideoTracks = {};

			if(!exit){
				this.$store.dispatch("live/disconnect");
				this.disconnectSnackbar = new Snackbar({
					propsData: {
						message: "Disconnected from session.",
						action: () => {
							this.$store.dispatch("live/reconnect")
							.then(() =>{
								return this.connectTwilio();
							})
							.then(() => {
								this.disconnectSnackbar.hide();
								this.disconnectSnackbar = null;
								this.loading = false;
							})
							.catch((err) => {
								this.$store.dispatch("live/end");
								if(this.disconnectSnackbar){
									this.disconnectSnackbar.hide();
									this.disconnectSnackbar = null;
								}
								Materialize.toast(err, 3260);
								this.$router.push({ path: "/classrooms" });	
							});
						},
						actionStr: "Reconnect",
						time: -1
					}
				});
			}
		},
		exit: function(){
			swal.fire({
				title: "Leaving live Session",
				text: "Are you sure you want to leave the current seasion?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No",
				reverseButtons: true
			}).then( (result) => {
				if(result.value){
					this.$store.dispatch("live/changeSessionStatus", { status: 10906 });
					this.leave();
				}
			})
		},
		leave: function(){
			this.disconnect(true);
			this.$store.dispatch("live/end");
			if(this.disconnectSnackbar){
				this.disconnectSnackbar.hide();
				this.disconnectSnackbar = null;
			}
			this.$router.push({ path: "/classrooms" });	
		}
	}
};