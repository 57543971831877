import axios from "axios";
import { materialize } from "rxjs/operator/materialize";
const CLASSROOM_URL = "https://locstatt.net/app/cfcChevron/classroom.cfc";

export default {
	template: `
			<div id="user-profile-modal" class="modal modal-fixed-footer">
				<div class="modal-content">
                    <div class="user-profile">
                        <div class="material user-profile-info">
                            <div class="row user-profile-row">
                                <div class="user-profile-text-left col s7 m7 l7 user-profile-showborders">
                                    <div class="user-profile-img col s12 m12 l5">
                                        <div class="center-align user-propic-overlay" @click="openEditProfilePicture">
                                            <img class="responsive-img user-profile-picture" width="280" style="cursor:pointer;" v-bind:src="this.USER_PROFILE_PIC_PATH" onerror="this.src ='https://locstatt.net/app/employeePhotos/no_photo.png'">
                                            <div class="after">
                                                <span class="zoom">
                                                    <i class="large material-icons">camera_alt
                                                    </i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col s12 m12 l7" style="margin-bottom: 25px;">
                                        <h6 class="user-profile-title">Basic Information</h6>
                                        <br/>
                                        <p>
                                            <label class="user-profile-subtitle">Name: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.EMP_FIRST_NAME}} {{USER_DATA.EMP_LAST_NAME}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Employee ID: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.EMP_CODE}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Date Of Birth: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.EMP_DATEOFBIRTHH}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Gender: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.EMP_GENDER}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Employee Since: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.COMPANY_EMPLOYEE_BEGIN_DATE}}</span>
                                        </p>
                                    </div>
                                    <div class="col s12 m6" style="border-right: 1px solid gray;">
                                        <h6 class="user-profile-title">Company Information</h6>
                                        <br/>
                                        <p>
                                            <label class="user-profile-subtitle">Company: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.COMPANY_NAME}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Department: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.DEP_NAME}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Job Position: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.POS_NAME}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Facility: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.SITE_NAME}}</span>
                                        </p>

                                        <h6 class="user-profile-title">Contact Information</h6>
                                        <br/>
                                        <p>
                                            <label class="user-profile-subtitle">Email: </label>
                                            <span class="user-profile-subtext">{{USER_DATA.EMP_COMPANY_EMAIL}}</span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Phone: </label>
                                            <input disabled class="black-text user-profile-noboder" id="employee_phone" name="employee_phone" type="text" v-bind:value="this.USER_DATA.EMP_PHONE1">
                                        </p>
                                    </div>
                                    <div class="col s12 m6">
                                        <h6 class="user-profile-title" style="text-align: center;">Signature</h6>
                                        <br/>
                                        <img v-bind:src="this.USER_SIGNATURE_PATH" alt="signature" class="responsive-img" onerror="this.src ='https://locstatt.net/images/audits-nosignature.jpg'">
                                    </div>
                                </div>
                                <div class="user-profile-text-left col s5 l5 m5">
                                    <h6 class="user-profile-title">Login Information</h6>
                                    <br/>
                                    <p>
                                        <label class="user-profile-subtitle">UserName: </label>
                                        <span class="user-profile-subtext">{{USER_DATA.USER_NAME}}</span>
                                    </p>
                                    <p class="password-user-profile">
                                        <label class="user-profile-subtitle">Password: </label>
                                        <span class="user-profile-subtext" style="display:inline-block;">
                                            <input class="black-text username-disabled user-profile-noboder" disabled id="emp_password" name="emp_password" type="password" v-bind:value="this.USER_DATA.USER_PASSWORD">
                                        </span>

                                        <a v-if="CHANGE_PASSWORD" class="btn-floating red tooltipped" data-position="top" data-tooltip="Cancel" v-on:click="clickEditPassword">
                                            <i class="material-icons small edit-pass-user-profile">close</i>
                                        </a>

                                        <a v-else class="btn-floating blue tooltipped" data-position="top" data-tooltip="Edit Password" v-on:click="clickEditPassword">
                                            <i class="material-icons small edit-pass-user-profile">edit</i>
                                        </a>

                                        <div>
                                            <input type="checkbox" id="show-pass" @change="showPassword" />
                                            <label for="show-pass">Show Password</label></td>
                                        </div>
                                    </p>

                                    <div v-if="CHANGE_PASSWORD"> 
                                        <br />
                                        <p>
                                            <label class="user-profile-subtitle">New Password: </label>
                                            <span class="user-profile-subtext col s12 m12 l12">
                                                <input class="black-text edit-profile-input" id="new-password" type="password" autofocus="true">
                                            </span>
                                        </p>
                                        <p>
                                            <label class="user-profile-subtitle">Confirm New Password: </label>
                                            <span class="user-profile-subtext col s12 m12 l12">
                                                <input class="black-text edit-profile-input" id="confirm-password" type="password">
                                            </span>
                                        </p>
                                        <a @click="updatePassword" class="waves-effect waves-light btn btn-small red right save-pass-user-profile-set">Save</a>
                                        <br />
                                        <div id="pswd_info" class="hide">
                                            <h4>Please enter a new password:</h4>
                                            <ul>
                                                <li id="letter" class="invalid"><strong>Password must contain letters.</strong></li>
                                                <li id="capital" class="invalid"><strong>At least one capital letter must be used.</strong></li>
                                                <li id="number" class="invalid"><strong>Password must contain numbers.</strong></li>
                                                <li id="length" class="invalid"><strong>Password must contain at least 8 characters.</strong></li>
                                                <li id="characters" class="invalid"><strong>These symbols are not allowed </strong></li>
                                                <li id="space" class="invalid"><strong>Spaces are not allowed.</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div v-if="SHOW_PREFERENCES">
                                        <h6 class="user-profile-title">Preferences</h6>
                                        <br/>
                                        <label class="user-profile-subtitle">Date Format </label>
                                        <p class="row user-p-date-format">
                                            <p> For each formatdate </p>
                                            <label class="col s12 l6 m12">
                                            <input class="with-gap user-profile-date-mat" name="date-group" id="date-format-group-FORMAT-ID" type="radio" checked=true data-id="ID" data-format="format"/>
                                            <label class="black-text" for="date-format-group-FORMAT ID">
                                                True
                                            </label>
                                            </label>
                                        </p>
                                        <h6 class="user-profile-title">Language</h6>
                                        <br/>
                                        <p>
                                        <select class="Language-user-profile browser-default" id="Language-user-profile" >
                                            <option value="0" disabled>Choose your option</option>
                                            <option value="1" selected>English</option>
                                        </select>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
					<a class="modal-action modal-close waves-effect waves-green btn-flat ">Close</a>
				</div>
			</div>
	`,

    data: function(){ 
        return { 
            USER_DATA: {},
            CHANGE_PASSWORD: false,
            USER_PROFILE_PIC_PATH: "",
            USER_SGINATURE_PATH: "",
            LOCSTATT_PHOTO_PATH: "https://locstatt.net/app/employeePhotos/",
            LOCSTATT_SIGNATURE_PATH: "https://locstatt.net/app/employeeSignatures/",
            SHOW_PREFERENCES: false
        }; 
    },
	created: async function(){
        const userProfile = await axios.get
        (
            CLASSROOM_URL,
            { 
                params: {
                    method: "getEmployeeProfile", 
                    company_code: this.$store.state.UserData.COMPANY_CODE,
                    emp_code: this.$store.state.UserData.EMP_CODE
                } 
            }
        );
        this.USER_DATA = {...userProfile.data[0], USER_NAME:this.$store.state.UserData.USER_USERNAME};
        this.getUserProfilePictureAndSignature();
	},
	mounted: function(){

	},
	methods: {

        openEditProfilePicture: function(){
            this.$parent.openEditProfilePicture();
        },  

        updatePassword: async function(){

            const oldpass:string = $('.user-profile #new-password').val();
            const newpass:string = $('.user-profile #confirm-password').val();
            const isValidPassword:boolean = this.validatePassword(oldpass,newpass);
            if(isValidPassword){
                try{
                    
                    const updatePassword = axios.get(
                        CLASSROOM_URL,
                        {
                            params: {
                                method:'setUpdateUserProfileLocstatt3',
                                emp_code: this.USER_DATA.EMP_CODE,
                                company_code: this.USER_DATA.COMPANY_CODE,
                                user_code: this.$store.state.UserData.USER_CODE.toString(),
                                user_name: this.USER_DATA.USER_NAME,
                                password: newpass,
                                employee_address: this.USER_DATA.EMP_ADDRESS,
                                employee_phone: this.USER_DATA.EMP_PHONE1,
                                employee_email: this.USER_DATA.EMP_COMPANY_EMAIL,
                            }
                        }
                    );
                    $('#pswd_info').addClass('hide');
                    this.USER_DATA = {...this.USER_DATA, USER_PASSWORD: newpass};
                    this.CHANGE_PASSWORD = false;
                    Materialize.toast('Profile details have been edited successfully', 2000)
                    Materialize.toast('You will receive an email confirming that you have changed your password', 4000)
                }catch(error){
                    materialize.toast("An error occured while trying to update the password!", 3000);
                }
            }
        },

        validatePassword: function(oldpass,newpass){
            

            if (oldpass != "" && newpass != ""){
                if(oldpass === newpass){
                    $('#pswd_info').removeClass('hide')
                    if (newpass.length >= 8){
                        $('#length').removeClass('invalid').addClass('valid');
                    }else{
                        $('#length').removeClass('valid').addClass('invalid');
                        return false;
                    }

                    if(newpass.match(/[A-z]/)){
                        $('#letter').removeClass('invalid').addClass('valid');
                    }else{
                        $('#letter').removeClass('valid').addClass('invalid');
                        return false;
                    }
                        
                    if(newpass.match(/[A-Z]/)){
                        $('#capital').removeClass('invalid').addClass('valid');
                    }else{
                        $('#capital').removeClass('valid').addClass('invalid');
                        return false;
                    }

                    if(newpass.match(/\d/)){
                        $('#number').removeClass('invalid').addClass('valid');
                    }else{
                        $('#number').removeClass('valid').addClass('invalid');
                        return false;
                    }
                        
                    if(newpass.match(/^[@\w\s]*$/)){
                        $('#characters').removeClass('invalid').addClass('valid');
                    }else{                 
                        $('#characters').removeClass('valid').addClass('invalid');      
                        return false;
                    }
                    if(newpass.match(/\s/)){
                        $('#space').removeClass('valid').addClass('invalid');  
                        return false;
                    }else{         
                        $('#space').removeClass('invalid').addClass('valid');
                    }
                    return true;
                }else{
                    Materialize.toast('The current password does not match. Please try again', 4000)
                    $('#pswd_info').addClass('hide')
                    return false;
                }
            }else{
                Materialize.toast('Fields are empty. Please try again', 3000)
                $('#pswd_info').addClass('hide')
                return false;
            }
        },

        showPassword: function(){
            const SHOW_PASS:boolean = $('#show-pass').is(':checked');
            const passwordField = document.getElementById('emp_password');
            const value = passwordField.value;
            passwordField.type = SHOW_PASS ? 'text' : 'password';
            passwordField.value = value
        }, 
		formatDate: function(date){
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            return [month, day, year].join("/");
        },
        getUserProfilePictureAndSignature: function(){
            const empCodeAsString:string = this.USER_DATA.EMP_CODE.toString();
            const lastTwoDigitsFromEmpCode:string = empCodeAsString.slice(-2);
            const firstSixDigitsFromEmpCode:string = empCodeAsString.slice(0,6);
            this.USER_PROFILE_PIC_PATH = `${this.LOCSTATT_PHOTO_PATH}${lastTwoDigitsFromEmpCode}/${firstSixDigitsFromEmpCode}.jpg`;
            this.USER_SIGNATURE_PATH = `${this.LOCSTATT_SIGNATURE_PATH}${lastTwoDigitsFromEmpCode}/${firstSixDigitsFromEmpCode}.png`;
            console.log(this.USER_SIGNATURE_PATH);
        },
        clickEditPassword: function(){
            this.CHANGE_PASSWORD = !this.CHANGE_PASSWORD;
            console.log(this.CHANGE_PASSWORD)
            if(!this.CHANGE_PASSWORD){
                const SHOW_PASS:boolean = $('#show-pass').is(':checked');
                if(SHOW_PASS){
                    $("#show-pass").click();
                }
            }
        }
	},
	computed: {
		
	}
};