import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import * as $ from 'jquery';
import * as rx from 'rx';

export default {
	template: `
		<div id="DownloadController" class="container" style="height: 100%;">
			<loader v-if="loading"></loader>
			<div class="card-panel center-align" style="display:inline-block;width: calc(50% - 2rem);margin-right: 1rem;">
				<h4>Classroom</h4>
				<p>
					<a class="btn green" v-bind:href="classroom.url" target="_blank">
						Donwload for {{platform}}
					</a>
				</p>
				<p class="grey-text">Version: {{classroom.version}}</p>
				<p class="blue-grey-text">
					<a v-bind:href="classroom.secondUrl" target="_blank">Download version {{classroom.secondVersion}} for {{secondPlatform}}</a>
				</p>
			</div>
			<div class="card-panel center-align" style="display:inline-block;width: calc(50% - 2rem);margin-right: 1rem;">
				<h4>Facility Logbook</h4>
				<p>
					<a class="btn green" v-bind:href="logbook.url" target="_blank">
						Donwload for {{platform}}
					</a>
				</p>
				<p class="grey-text">Version: {{logbook.version}}</p>
				<p class="blue-grey-text">
					<a v-bind:href="logbook.secondUrl" target="_blank">Download version {{logbook.secondVersion}} for {{secondPlatform}}</a>
				</p>
			</div>
			<div class="card-panel center-align" style="display:inline-block;width: calc(50% - 2rem);margin-right: 1rem;">
				<h4>Remote Registration</h4>
				<p>
					<a class="btn green" v-bind:href="remote.url" target="_blank">
						Donwload for {{platform}}
					</a>
				</p>
				<p class="grey-text">Version: {{remote.version}}</p>
				<p class="blue-grey-text">
					<a v-bind:href="remote.secondUrl" target="_blank">Download version {{remote.secondVersion}} for {{secondPlatform}}</a>
				</p>
			</div>
		</div>
	`,
	data: function(){
		return {
			loading: false,
			classroom: {
				version: '',
				secondVersion: '',
				url: '',
				secondUrl: '',
				baseUrl: 'https://classroom.locstatt.net/releases/',
				macYml: 'https://classroom.locstatt.net/releases/mac/latest-mac.yml',
				winYml: 'https://classroom.locstatt.net/releases/win/latest.yml'
			},
			logbook: {
				version: '',
				secondVersion: '',
				url: '',
				secondUrl: '',
				baseUrl: 'https://classroom.locstatt.net/Logbook/',
				macYml: 'https://classroom.locstatt.net/Logbook/mac/latest-mac.yml',
				winYml: 'https://classroom.locstatt.net/Logbook/win/latest.yml'
			},
			remote: {
				version: '',
				secondVersion: '',
				url: '',
				secondUrl: '',
				baseUrl: 'https://classroom.locstatt.net/RemoteRegistration/',
				macYml: 'https://classroom.locstatt.net/RemoteRegistration/mac/latest-mac.yml',
				winYml: 'https://classroom.locstatt.net/RemoteRegistration/win/latest.yml'
			},
			platform: '',
			secondPlatform: ''
		}
	},
	created: function(){
		this.loading = true;
		let isMac = navigator.userAgent.includes('Mac');
		if(isMac){
			this.platform = "Mac OS";
			this.secondPlatform = "Windows";
			for(let app of [this.classroom,this.logbook,this.remote]){
				app.url = app.baseUrl+'mac/';
				app.secondUrl = app.baseUrl+'win/';
			}
		}else{
			this.platform = "Windows";
			this.secondPlatform = "Mac OS";
			for(let app of [this.classroom,this.logbook,this.remote]){
				app.url = app.baseUrl + 'win/';
				app.secondUrl = app.baseUrl + 'mac/';
			}
		}
		let promises: Array<PromiseLike<void>> = [];
		promises.push(
			$.ajax({
				url: this.classroom.winYml+'?'+Math.ceil(Math.random()*10000000000)
			}).done( (yml) => {
				let lines = yml.split('\n');
				let version = lines[0].split(':')[1];
				let path = lines[5].split(': ')[1];
				if(isMac){
					this.classroom.secondVersion = version;
					this.classroom.secondUrl += path;
				}else{
					this.classroom.version = version;
					this.classroom.url += path;
				}
			})
		);
		promises.push(
			$.ajax({
				url: this.classroom.macYml+'?'+Math.ceil(Math.random()*10000000000)
			}).done( (yml) => {
				let lines = yml.split('\n');
				let version = lines[0].split(':')[1];
				let path = lines[4].split(': ')[1];
				if(isMac){
					this.classroom.version = version;
					this.classroom.url += path;
				}else{
					this.classroom.secondVersion = version;
					this.classroom.secondUrl += path;
				}
			})
		);
		[this.logbook,this.remote].forEach((app) => {
			promises.push(
				$.ajax({
					url: app.winYml+'?'+Math.ceil(Math.random()*10000000000)
				}).done( (yml) => {
					let lines = yml.split('\n');
					let version = lines[0].split(':')[1];
					let path = lines[5].split(': ')[1];
					if(isMac){
						app.secondVersion = version;
						app.secondUrl += path;
					}else{
						app.version = version;
						app.url += path;
					}
				})
			);
			promises.push(
				$.ajax({
					url: app.macYml+'?'+Math.ceil(Math.random()*10000000000)
				}).done( (yml) => {
					let lines = yml.split('\n');
					let version = lines[0].split(':')[1];
					let path = lines[6].split(': ')[1];
					if(isMac){
						app.version = version;
						app.url += path;
					}else{
						app.secondVersion = version;
						app.secondUrl += path;
					}
				})
			);
		});
		Promise.all(promises).then(() => { this.loading = false; });
	}
}