import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import * as $ from 'jquery';
import * as rx from 'rx';

export default {
	template: `
		<div id="Course" class="controller" :class="{ 'white': LightsOn, 'black': !LightsOn }" @keyup.space="togglePlay" @keyup.left="previousSlide" @keyup.right="nextSlide" tabindex="0">
			<loader v-if="Loading"></loader>
			
			<div class="media-wrapper center-align valign-wrapper" v-if="CourseSpecification && CurrentSlide" :class="{ 'expand': !ShowActions }">

				<video id="course-video" class="valign" v-if="CurrentSlide.IsVideo" @playing="VideoPlaying = true" @pause="VideoPlaying = false" autoplay>
					<source :src="'/carlo/classroom/courses/'+CourseSpecification.Code+'/'+CurrentSlide.Media"></source>
				</video>

				<img id="course-img" class="valign" :src="'/carlo/classroom/courses/'+CourseSpecification.Code+'/'+CurrentSlide.Media" onerror="this.src='/carlo/classroom/images/image-placeholder.png';" v-if="CurrentSlide.IsImg">

				<div class="video-controls row" v-if="CurrentSlide.IsVideo">
					<div class="col s8 left-align">
						<a class="btn-flat small waves-effect waves-light tooltipped" data-position="top" data-tooltip="Play/Pause Video" @click="togglePlay">
							<i class="material-icons" v-if="VideoPlaying">pause</i>
							<i class="material-icons" v-else>play_arrow</i>
						</a>
						<a class="btn-flat small waves-effect waves-light tooltipped" data-position="top" data-tooltip="Replay Slide" @click="replaySlide">
							<i class="material-icons">replay</i>
						</a>
						<a class="btn-flat small waves-effect waves-light tooltipped" data-position="top" data-tooltip="Volume" @click="toggleVolumeSlide">
							<i class="material-icons">volume_up</i>
						</a>
						<div class="volume-wrapper" style="display: none;">
							<form>
								<p class="range-field">
									<input id="video-volume" type="range" min="0" max="10" v-model="Volume">
								</p>
							</form>
						</div>
						<a class="btn-flat small waves-effect waves-light tooltipped" data-position="top" data-tooltip="Toggle Lights" @click="toggleLights">
							<i class="ion-ios-lightbulb" v-if="LightsOn"></i>
							<i class="ion-ios-lightbulb-outline" v-else></i>
						</a>
						<a class="btn-flat small waves-effect waves-light tooltipped" data-position="top" data-tooltip="Fullscreen Slide" data-position="top" data-tooltip="Fullscreen Slide" @click="fullscreen">
							<i class="material-icons">fullscreen</i>
						</a>
					</div>
				</div>

			</div>

			<div class="bottom-actions row white z-depth-3" v-if="CourseSpecification" :class="{ 'expand': ShowActions }">
				<a class="btn-flat small center-align waves-effect waves-light" @click="toggleActions">
					<i class="material-icons" v-if="ShowActions">keyboard_arrow_down</i>
					<i class="material-icons" v-else>keyboard_arrow_up</i>
				</a>
				<div class="row valign-wrapper white center-align">
					<div class="col s3 no-padding">
						<a class="btn red waves-effect waves-light" @click="exit">
							<i class="material-icons">exit_to_app</i>
							Exit
						</a>
					</div>
					<div class="col s1 no-padding">
						<a class="btn  blue z-depth-0 waves-effect waves-light" @click="previousSlide">
							<i class="material-icons">skip_previous</i>
						</a>
					</div>

					<div class="col s4 no-padding">
						<label>Slide:</label>
						<!--
						<p class="input-field white-text">
						-->
							<select class="browser-default" v-model="CurrentSlideIndex" @change="changeSlide">
								<option v-for="(slide, index) in CourseSpecification.Content" :value="index">({{slide.Id}}) {{slide.Title}}</option>
							</select>
							<span class="white-text"> of {{CourseSpecification.Content.length}} </span>
						<!--
						</p>
						-->
					</div>

					<div class="col s1 no-padding">
						<a class="btn blue z-depth-0 waves-effect waves-light" @click="nextSlide">
							<i class="material-icons">skip_next</i>
						</a>
					</div>

					<div class="col s3 no-padding">
						<a class="btn small blue waves-effect">
							<i class="material-icons">attachment</i>
							Extra Resources
						</a>
					</div>

				</div>
			</div>

		</div>
	`,
	data: function(){
		return {
			CourseSpecification: null,
			CurrentSlide: null,
			CurrentSlideIndex: 0,
			LightsOn: true,
			Loading: false,

			VideoPlaying: false,

			ShowActions: true,
			ShowVolumeSlider: false,
			Volume: 10
		};
	},
	created: function(){
		this.Loading = true;
		$.ajax({
			url: 'https://107.170.200.90/courses/'+this.$route.params.CourseCode,
			method: 'GET'
		}).done( (spec: CourseSpecification) => {
			this.CourseSpecification = spec;
			if(this.CourseSpecification.Content.length){
				this.CurrentSlide = spec.Content[0];
			}
		}).fail( (status, errorDesc) => {
			Materialize.toast('Unable to load course specification', 3260);
			console.error(status, errorDesc);
		}).always( () => {
			this.Loading = false;
		});
	},
	mounted: function(){
		let $el: JQuery<HTMLElement> = $(this.$el);
		$el.find('.tooltipped').tooltip();
	},
	computed: { },
	methods: {
		fullscreen: function(){
			let v: HTMLVideoElement = $(this.$el).find('.media-wrapper video').get(0);
			if(v.requestFullscreen){
				v.requestFullscreen();
			}else if(v.mozRequestFullscreen){
				v.mozRequestFullscreen();
			}else if(v.webkitRequestFullscreen) {
				v.webkitRequestFullscreen();
			}else if(v.msRequestFullscreen) {
				v.msRequestFullscreen();
			}
		},
		previousSlide: function(){
			if(this.CurrentSlideIndex == 0) {
				return;
			}
			this.CurrentSlideIndex = this.CurrentSlideIndex-1;
			this.CurrentSlide = this.CourseSpecification.Content[this.CurrentSlideIndex];
			if(this.CurrentSlide.IsVideo){
				$(this.$el).find('.media-wrapper video').get(0).load();
			}
		},
		nextSlide: function(){
			if(this.CurrentSlideIndex == this.CourseSpecification.Content.length-1){
				return;
			}
			this.CurrentSlideIndex = this.CurrentSlideIndex+1;
			this.CurrentSlide = this.CourseSpecification.Content[this.CurrentSlideIndex];
			if(this.CurrentSlide.IsVideo){
				$(this.$el).find('.media-wrapper video').get(0).load();
			}
		},
		changeSlide: function(){
			this.CurrentSlide = this.CourseSpecification.Content[this.CurrentSlideIndex];
			if(this.CurrentSlide.IsVideo){
				$(this.$el).find('.media-wrapper video').get(0).load();
			}
		},
		replaySlide: function(){
			let v: HTMLVideoElement = $(this.$el).find('.media-wrapper video').get(0);
			v.currentTime = 0;
			v.play();
		},
		showResources: function(){},
		toggleActions: function(){
			this.ShowActions = !this.ShowActions;
		},
		toggleVolumeSlide: function(){
			this.ShowVolumeSlider = !this.ShowVolumeSlider;
			$(this.$el).find('.volume-wrapper').animate({ width: 'toggle' });
		},
		toggleLights: function(){
			this.LightsOn = !this.LightsOn;
		},
		togglePlay: function(){
			if(this.CurrentSlide.IsVideo){
				let v: HTMLVideoElement = $(this.$el).find('.media-wrapper video').get(0);
				if(v.paused){
					v.play();
				}else{
					v.pause();
				}
			}
		},
		exit: function(){
			this.$router.push({ 
				path: '/classrooms' 
			});
		}
	}
};