import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import * as $ from 'jquery';
import * as rx from 'rx';

export default {
	template: `
		<div id="Search" :class="{ 'open-drawer': showDrawer }">
			<navbar></navbar>
			<sidenav></sidenav>
			<div class="container">
				<a @click="goBack" class="blue-grey-text"> <i class="material-icons">chevron_left</i> Go Back </a>
				<div class="card-panel">
					<h5>Search for:</h5>
					<div class="input-field">
						<input type="text" v-model="Query" @keyup.enter="search">
					</div>
					<loader v-if="Loading"></loader>
				</div>

				<br>
				
				<h5 class="blue-grey-text" v-if="SearchResultsQuery && !SearchResults.length">No results found.</h5>
				
				<ul class="collection with-header z-depth-1" v-if="SearchResults.length">
					<li class="collection-header">
						<h5 class="blue-grey-text">Search results for: <b>{{SearchResultsQuery}}</b></h5>
					</li>
					<li class="collection-item blue-text" v-for="result in SearchResults">
						{{result.title}}
						<a class="secondary-content">{{result.type}}</a>
					</li>
				</ul>

			</div>
		</div>
	`,
	props: ['Query'],
	data: function(){
		return {
			Loading: false,
			Query: '',
			SearchResults: [],
			SearchResultsQuery: ''
		};
	},
	created: function(){
		//if(this.$store.state.ShowMenuDrawer){
		//	this.$store.commit('toggleMenuDrawer', false);
		//}
		if(this.Query){
			this.search();
		}
	},
	methods: {

		search: function(){
			
			this.Loading = true;
			this.SearchResults = [];
			this.SearchResultsQuery = '';
			setTimeout( () => {
				this.Loading = false;
				this.SearchResultsQuery = this.Query;
				this.SearchResults = [{
					title: 'Example Course #1',
					type: 'Course'
				},{
					title: 'Example Course #2',
					type: 'Course'
				},{
					title: 'Example Course #3',
					type: 'Course'
				},{
					title: 'Example Live Session #1',
					type: 'Live Session'
				},{
					title: 'Example Live Session #2',
					type: 'Live Session'
				},{
					title: 'Example Live Session #3',
					type: 'Live Session'
				}];
			}, 3000);
		},
		goBack: function(){
			this.$router.go(-1);
		}
	},
	computed: {
		showDrawer: function(){
			return this.$store.state.ShowMenuDrawer;
		}
	}
};