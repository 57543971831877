import * as Vue from "vue";
import * as $ from "jquery";

export class VoiceChat{
	Context: AudioContext;
	Track: MediaStreamTrack?;
	Info: VoiceParticipant;
	Participants: VoiceParticipant[];
	constructor(){
		this.Track = null;
		this.Info = {
			AudioLevel: 0,
			EMP_CODE: "",
			EMP_NAME: "",
			Muted: false,
			TrackId: ""
		};
		this.Participants = [];
	}
}
export class LiveSession{
	Classroom: VirtualSession?;
	Key: string;
	Token: string;
	SessionStatus: number;
	Layout: number;
	MainApp: boolean;
	Location: UserLocation?;
	InstructorConnected: boolean;
	InstructorStreams: Stream[];
	Evaluation: Evaluation?;
	Employees: Employee[];
	VoiceChat: VoiceChat;
	StartDate: string;
	Completed: boolean;
	constructor(){
		this.Classroom = null;
		this.Key = "";
		this.Token = "";
		this.SessionStatus = 1453;
		this.Layout = 0;
		this.MainApp = false;
		this.Location = null;
		this.InstructorConnected = false;
		this.InstructorStreams = [];
		this.Evaluation = null;
		this.Employees = [];
		this.VoiceChat = new VoiceChat();
		let date = new Date();
		let dateStr = (date.getMonth()+1)+"/"+date.getDate()+"/"+date.getFullYear();
		let timeStr = date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
		this.StartDate = dateStr+" "+timeStr;
		this.Completed = false;
	}
}
export const LiveModule = {
	namespaced: true,
	strict: true,
	state: new LiveSession(),
	getters: {
		key: function(state: LiveSession){
			return state.Key;
		},
		classroom: function(state: LiveSession){
			return state.Classroom;
		},
		token: function(state: LiveSession){
			return state.Token;
		},
		location: function(state: LiveSession){
			return state.Location;
		},
		instructor: function(state: LiveSession){
			return state.InstructorConnected;
		},
		streams: function(state: LiveSession){
			return state.InstructorStreams;
		},
		evaluation: function(state: LiveSession){
			return state.Evaluation;
		},
		employees: function(state: LiveSession){
			return state.Employees;
		},
		voice: function(state: LiveSession){
			return state.VoiceChat;
		},
		layout: function(state: LiveSession){
			return state.Layout;
		},
		mainApp: function(state: LiveSession){
			return state.MainApp;
		},
		completed: function(state: LiveSession){
			return state.Completed;
		}
	},
	mutations: {
		setStartDate: function(state: LiveSession, date: string){
			state.StartDate = date;
		},
		setKey: function(state: LiveSession, key: string){
			state.Key = key;
		},
		setSessionStatus: function(state: LiveSession, status: number){
			state.SessionStatus = status;
		},
		setClassroom: function(state: LiveSession, classroom: VirtualSession){
			state.Classroom = classroom;
		},
		setToken: function(state: LiveSession, token: string){
			state.Token = token;
		},
		setInstructorStatus: function(state: LiveSession, status: boolean){
			state.InstructorConnected = status;
		},
		setInstructorStreams: function(state: LiveSession, tracks: Stream[]){
			state.InstructorStreams = tracks;
		},
		addInstructorStream: function(state: LiveSession, track: Stream){
			state.InstructorStreams.push(track);
		},
		removeInstructorStream: function(state: LiveSession, id: string){
			for(let i=0;i<state.InstructorStreams.length;i++){
				if(state.InstructorStreams[i].id == id){
					state.InstructorStreams.splice(i, 1);
					break;
				}
			}
		},
		setLayout: function(state: LiveSession, layout: number){
			state.Layout = layout;
		},
		rotateLayout: function(state: LiveSession){
			if(state.Layout == 3){
				state.Layout = 0;
			}else{
				state.Layout += 1;
			}
		},

		setVoiceChat: function(state: LiveSession, voice: VoiceChat){
			state.VoiceChat = voice;
		},
		setMicContext: function(state: LiveSession, context: AudioContext){
			Vue.set(state.VoiceChat, "Context", context);
		},
		setMicTrackId: function(state: LiveSession, trackId: string){
			Vue.set(state.VoiceChat.Info, "TrackId", trackId); 
		},
		setMicTrack: function(state: LiveSession, track: any){
			Vue.set(state.VoiceChat, "Track", track)
		},
		setMicLevels: function(state: LiveSession, level: number){
			Vue.set(state.VoiceChat.Info, "AudioLevel", level);
		},
		setMicMute: function(state: LiveSession, muted: boolean){
			Vue.set(state.VoiceChat.Info, "Muted", muted);
		},

		setVoiceChatParticipants: function(state: LiveSession, participants: VoiceParticipant[]){
			state.VoiceChat.Participants = participants;
		},
		addVoiceChatParticipant: function(state: LiveSession, participant: VoiceParticipant){
			state.VoiceChat.Participants.push(participant);
		},
		removeVoiceChatParticipant: function(state: LiveSession, code: string){
			for(let i=0;i<state.VoiceChat.Participants.length;i++){
				if(state.VoiceChat.Participants[i].EMP_CODE == code){
					state.VoiceChat.Participants.splice(i, 1);
					break;
				}
			}
		},
		setVoiceChatParticipantMute: function(state: LiveSession, payload: { code: string, muted: boolean }){
			for (let i = 0; i < state.VoiceChat.Participants.length; i++) {
				if(state.VoiceChat.Participants[i].EMP_CODE == payload.code){
					Vue.set(state.VoiceChat.Participants[i], "Muted", payload.muted);
					break;
				}
			}
		},
		setVoiceChatParticipantTrack: function(state: LiveSession, payload: { code: string, track: string }){
			for(let participant of state.VoiceChat.Participants){
				if(participant.EMP_CODE == payload.code){
					Vue.set(participant, "TrackId", payload.track);
					break;
				}
			}
		},
		setVoiceChatParticipantName: function(state: LiveSession, payload: { code: string, name: string }){
			for(let participant of state.VoiceChat.Participants){
				if(participant.EMP_CODE == payload.code){
					Vue.set(participant, "EMP_NAME", payload.name);
					break;
				}
			}
		},
		setVoiceChatParticipantsLevels: function(state: LiveSession, payload: { string: number }){
			for(let participant of state.VoiceChat.Participants){
				if(payload[participant.TrackId]){
					Vue.set(participant, "AudioLevel", payload[participant.TrackId]);
				}
			}
		},
		setVoiceChatParticipantsMute: function(state: LiveSession, muted: boolean){
			for(let participant of state.VoiceChat.Participants){
				Vue.set(participant, "Muted", muted);
			}
		},

		setEvaluation: function(state: LiveSession, evaluation: Evaluation){
			state.Evaluation = evaluation;
		},
		toggleEvaluationStatus: function(state: LiveSession){
			state.Evaluation.Status = state.Evaluation.Paused ? "In Progress" : "Paused";
			state.Evaluation.Paused = !state.Evaluation.Paused;
			state.Evaluation.Enabled = !state.Evaluation.Enabled;
		},
		setEvaluationStatus: function(state: LiveSession, status: string){
			state.Evaluation.Status = status;
		},
		setEvaluationEnabled: function(state: LiveSession, enabled: boolean){
			state.Evaluation.Enabled = enabled;
		},

		setEmployees: function(state: LiveSession, emps: Employee[]){
			state.Employees = emps;
		},
		addEmployee: function(state: LiveSession, emp: Employee){
			state.Employees.push(emp);
		},
		removeEmployee: function(state: LiveSession, code: string){
			for(let i=0;i<state.Employees.length;i++){
				if(state.Employees[i].EMP_CODE == code){
					state.Employees.splice(i, 1);
					break;
				}
			}
		},
		setMainApp: function(state: LiveSession, connected: boolean){
			state.MainApp = connected;
		},
		setLocation: function(state: LiveSession, location: Location){
			state.Location = location;
		},
		setCompleted: function(state: LiveSession, status: boolean){
			state.Completed = status;
		}
	},
	actions: {
		create: function({ commit, dispatch }, key: string){
			return dispatch("getVirtualCourse", key)
			.then((sessions: Array<VirtualSession>) => {
				if(sessions.length){
					commit("setClassroom", sessions[0]);
					return dispatch("verifyUser");
				}else{
					return new Promise((_, rej) => {
						rej("Unable to get live session info.");
					});
				}
			})
			.then((userList) => {
				if(userList.length){
					return dispatch("getUserLocation", null, { root: true })
				}else{
					return new Promise((_, rej) => {
						rej("Session restricted.");
					});
				}				
			})
			.then((location) => {
				commit("setLocation", location);
				return dispatch("getToken");
			})
			.then((token: string) => {
				commit("setToken", token);
				return dispatch("setVirtualHeader");
			})
			.then((sessions) => {
				return dispatch("setVirutalSession");
			})
			.then(() => {
				return dispatch("livesocket/create", null, { root: true });
			});
		},
		setStatus: function({ commit, dispatch }, statusInfo){
			commit("setInstructorStatus", !statusInfo.INSTRUCTOR_GONE);
			commit("setLayout", statusInfo.SCREEN_LAYOUT);
			commit("setMainApp", statusInfo.MAIN_APP_CONNECTED);

			let instructorStreams: Stream[] = [];
			for(let id in statusInfo.INSTRUCTOR_STREAMS){
				instructorStreams.push({
					id: id,
					type: statusInfo.INSTRUCTOR_STREAMS[id]
				});
			}
			commit("setInstructorStreams", instructorStreams);

			if(statusInfo.ACTIVITIES.length){
				dispatch("startEvaluation", { 
					evaluation_id: statusInfo.ACTIVITIES[0].EVALUATION_ID, 
					status: statusInfo.ACTIVITIES[0] 
				});
			}

		},
		changeSessionStatus: function({ rootState, state, commit }, payload: { status: number, test: string = "", score: number = 0 }){
			let date: Date = new Date();
			var dateStr: string = ""
			var month: number = date.getMonth()+1;
			dateStr += (month < 10 ? "0"+month : month) + "/";
			var day: number = date.getDate();
			dateStr += (day < 10 ? "0"+day : day) + "/";
			dateStr += date.getFullYear()+" ";
			var hour: number = date.getHours();
			dateStr += (hour < 10 ? "0"+hour : hour)+":";
			var minute: number = date.getMinutes();
			dateStr += (minute < 10 ? "0"+minute : minute)+":";
			var second: number = date.getSeconds();
			dateStr += (second < 10 ? "0"+second : second);
			$.ajax({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				method: "POST",
				data: {
					method: "setUpdateEmployeeVirtualSessionStatus",
					classroom_virtual_session_id: state.Classroom.CLASSROOM_VIRTUAL_SESSION_ID,
					end_time: dateStr,
					test_structure: payload.test != null ? payload.test : "",
					emp_code: rootState.UserData.EMP_CODE,
					session_status_id: payload.status,
					score: payload.score != null ? payload.score : 0,
					approved: payload.score != null ? 1 : 0
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			})
			.always(function(){ commit("setSessionStatus", status); });
		},

		// Evaluation
		startEvaluation: function({ commit, dispatch }, payload: { evaluation_id: number, status: {} }){
			commit("setEvaluation", { Loading: true });
			return dispatch("getEvaluation", payload.evaluation_id)
			.then((evaluation: Evaluation) => {
				evaluation.test_header = evaluation.test_header[0];
				if(payload.status){
					if(payload.status.STUDENT_FINISHED){
						evaluation.Enabled = false;
						evaluation.Status = "Finished";
					}else{
						evaluation.Enabled = !status.PAUSED;
						if(status.PAUSED){
							evaluation.Status = "Paused";
						}else{
							evaluation.Status = "In Progress";
						}
					}
				}else{
					evaluation.Enabled = true;
					evaluation.Paused = false;
					evaluation.Status = "In Progress";
				}
				evaluation.Loading = false;
				commit("setEvaluation", evaluation);
			})
			.catch((error) => {
				console.error(error);
				commit("setEvaluation");
				Materialize.toast("Unable to get activity details", 3260);
			});
		},
		finishEvaluation: function({ commit }, cancelled: boolean){
			commit("setEvaluationStatus", cancelled ? "Cancelled" : "Finished");
			commit("setEvaluationEnabled", false);
			setTimeout(() => {
				commit("setEvaluation");
			}, 5000);
		},
		sendFinishEvaluation: function({ commit, dispatch }){
			commit("setEvaluationStatus", "Finished");
			commit("setEvaluationEnabled", false);
			dispatch("livesocket/finishEvaluation", null, { root: true });
		},

		// Participants
		addEmployee: function({ state, commit, dispatch }, emp: Employee){
			return new Promise((res, rej) => {
				if(state.Employees.filter(function(e: Employee){ return e.EMP_CODE == emp.EMP_CODE; }).length){
					rej("Employee already on list.");
				}else{
					commit("addEmployee", emp);
					dispatch("livesocket/addEmployee", emp, { root: true });
					res("Employee added.");
				}
			});
		},
		removeEmployee: function({ commit, dispatch }, emp: Employee){
			commit("removeEmployee", emp.EMP_CODE);
			dispatch("livesocket/removeEmployee", emp, { root: true });
		},

		// Voice Chat
		startVoiceChat: function({ state, commit, dispatch }){
			return navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false
			})
			.then((stream: MediaStream) => {
				if(!state.VoiceChat.Context) {
					commit("setMicContext", new AudioContext());
				}
				let sourceNode: AudioNode = state.VoiceChat.Context.createMediaStreamSource(stream);
				let analyser: AnalyserNode = state.VoiceChat.Context.createAnalyser();
				analyser.smoothingTimeConstant = 0.2;
				analyser.fftSize = 1024;
				let node: ScriptProcessorNode = state.VoiceChat.Context.createScriptProcessor(1024*2, 1, 1);
				node.onaudioprocess = () => {
					let data: Uint8Array = new Uint8Array(analyser.frequencyBinCount);
					analyser.getByteFrequencyData(data);
					let rms = 0;
					for(let i=0;i < data.length; i++){
						rms = rms + data[i] * data[i];
					}
					rms = rms / data.length;
					rms = Math.round( Math.sqrt(rms) );
					commit("setMicLevels", rms);
				};
				sourceNode.connect(analyser);
				analyser.connect(node);
				node.connect(state.VoiceChat.Context.destination);
				let micTrack: MediaStreamTrack = stream.getAudioTracks()[0];
				commit("setMicTrackId", micTrack.id);
				//voice.Track = micTrack;
				//voice.Info.TrackId = micTrack.id;
				//commit("setVoiceChat", voice);
				return new Promise(function(res, rej){
					res(micTrack);
				});
			});
		},
		stopVoiceChat: function({ state, commit }){
			if(state.VoiceChat.Track){
				state.VoiceChat.Track.stop();
			}
			if(state.VoiceChat.Context){
				state.VoiceChat.Context.close();
			}
			commit("setMicMute", false);
			commit("setMicTrack");
			commit("setMicLevels", 0);
			commit("setMicTrackId", "");
			commit("setMicContext", new AudioContext());
		},
		handleAudioStats: function({ state, commit }, stats: StatsReport){
			if(state.VoiceChat){
				let leveledStats: { string: number; } = {};
				for(let track of stats.remoteAudioTrackStats){
					leveledStats[track.trackSid] = (track.audioLevel/32767)*100;
				}
				commit("setVoiceChatParticipantsLevels", leveledStats);
			}
		},
		setMicMute: function({ state, commit }, muted: boolean){
			if(state.VoiceChat.Track){
				if(muted){
					state.VoiceChat.Track.disable();		
				}else{
					state.VoiceChat.Track.enable();
				}
			}
			commit("setMicMute", muted);
		},


		reconnect: function({ state, commit, dispatch }){
			return dispatch("getVirtualCourse", state.Key)
			.then((sessions: Array<VirtualSession>) => {
				if(sessions.length){
					return dispatch("livesocket/create", null, { root: true });
				}else{
					return new Promise((_, rej) => {
						rej("Classroom session no longer available.");
					});
				}
			});
		},
		disconnect: function({ state, commit, dispatch }, ending: boolean){
			dispatch("stopVoiceChat");
			commit("setVoiceChatParticipants", []);
			commit("setEvaluation");
			if(!ending){
				dispatch("livesocket/disconnect", null, { root: true });	
			}
			commit("setInstructorStreams", []);
		},
		end: function({ dispatch }){
			dispatch("disconnect", true);
			dispatch("livesocket/quit", null, { root: true });
			dispatch("reset");
		},
		complete: function({ commit }){
			commit("setCompleted", true);
		},
		reset: function({ commit }){
			commit("setClassroom");
			commit("setCompleted", false);
			commit("setEmployees", []);
			commit("setLocation");
			commit("setMainApp", false);
			commit("setStartDate", "");
			commit("setToken", "");
		},
		getVirtualCourse: function(_, key: string){
			return $.ajax({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				method: "GET",
				data: {
					method: "getVirtualSessionCourseInfoByClassId",
					class_key: key
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			});
		},
		verifyUser: function({ state, rootState }){
			return $.ajax({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				method: "GET",
				data: {
					method: "getEmployeeById",
					company_code: state.Classroom.COMPANY_CODE,
					emp_code: rootState.UserData.EMP_CODE
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			});
		},
		getToken: function({ state, rootState }){
			return $.ajax({
				url: "https://classroom.locstatt.net/Token.cfc",
				method: "POST",
				data: {
					method: "getToken",
					identity: rootState.UserData.EMP_CODE,
					room: state.Classroom.CLASSROOM_STRING_ID
				}
			});
		},
		setVirtualHeader: function({ state, rootState }){
			return $.ajax({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				method: "POST",
				data: {
					method: "setNewClassroomVirtualClassHeader",
					class_key: state.Key,
					classroom_string_id: state.Classroom.CLASSROOM_STRING_ID,
					emp_code: rootState.UserData.EMP_CODE,
					company_code: rootState.UserData.COMPANY_CODE,
					start_time: state.StartDate,
					training_code_id: state.Classroom.TRAINING_CODE_ID,
					instructor_id: rootState.UserData.EMP_CODE,
					lat: 0.0, lng: 0.0,
					site_code: state.Location.SITE_CODE,
					location_id: state.Location.LOCATION_ID,
					session_status_id: 10000,
					is_the_presenter: 0
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			});
		},
		setVirutalSession: function({ state, rootState }){
			return $.ajax({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				method: "POST",
				data: {
					method: "setNewEmployeeOnVirtualSessionWebApp",
					emp_code: rootState.UserData.EMP_CODE,
					start_time: state.StartDate,
					classroom_virtual_session_id: state.Classroom.CLASSROOM_VIRTUAL_SESSION_ID,
					session_status_id: 10000,
					lat: 0.0, lng: 0.0,
					classroom_device_type_id: 1003,
					site_code: state.Location.SITE_CODE,
					location_id: state.Location.LOCATION_ID
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			})
		},
		getEvaluation: function(_, evaluationID: number){
			return $.ajax({
				url: "https://locstatt.net/app/cfcChevron/classroom.cfc",
				method: "GET",
				data: {
					method: "getCompleteTestByTestHeaderIdWebApp",
					CLASSROOM_TEST_HEADER_ID: evaluationID
				},
				xhrFields: { withCredentials: true },
				crossDomain: true
			});
		}

	}
}