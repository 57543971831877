import * as Materialize from 'materialize-css';
import * as swal from 'sweetalert';
import * as Rx from 'rxjs';
import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import { GeneralSocket } from './ClassroomSocket';
import * as parser from 'xml2json-light';
import * as  convert from 'xml-js';
import * as Utils from "./Utils";

export default {
	template: `
	<div id="SearchBar" class="hideSearch">
		<div id="SearchBarBtn" @click="OpenSearch()">
			<i class="material-icons">search</i>
			<p>Search</p>
		</div>
		<div id="SearchBarField" class="white z-depth-1 black-text" v-if="ShowSearch">
			<loader v-if="Loading"></loader>
			<i class="material-icons prefix">search</i>
			<input type="text" placeholder="Search" v-model="Query" @keyup="Search" @keyup.esc="CloseSearch" >
			<i class="material-icons close" @click="CloseSearch">close</i>
			<ul class="autocomplete-content dropdown-content col s12" v-if="Query && !Loading">
				<li v-if="!SearchResults.length">
					<span class="grey-text">No results found.</span>
				</li>
				<li v-for="result in SearchResults" v-if="SearchResults.length">
					<a>{{result.TRAINING_NAME}}</a>
				</li>
				<li v-if="SearchResults.length > 5">
					<router-link class="center-align blue-text" :to="{ name: 'Search', query: { q: Query } }">More Results</router-link>
				</li>
			</ul>
		</div>
	</div>
	`,

	data: function(){
		return {
			Loading: false,
			Query: '',
			SearchResults: [],
			OriginalCourses: [],
			ShowSearch: false
		};
	},
	methods: {
		OpenSearch: function(){
			this.SearchResults = [];
			this.ShowSearch = true;
			this.$nextTick(() => {
				$(this.$el).find('#SearchBarField input').focus();
			});
		},
		CloseSearch: function(){
			this.Query = '';
			this.SearchResults = [];
			this.ShowSearch = false;
		},

		Search: function(){
			this.Loading = true;
			if (this.OriginalCourses.length === 0){
				$.ajax({
					url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
					method: 'GET',
					data: {
						method: 'getVirtualSessionsPresenter',
						company_code: this.$store.state.UserData.COMPANY_CODE
					}
				}).done((list: Array<any>) => {
					
					if(list.length){
						
						
						this.OriginalCourses = list;
						var courses = this.OriginalCourses;
						this.SearchResults = courses.filter(o=>o.TRAINING_NAME.toLowerCase().includes(this.Query.toLowerCase()));
					}
					this.Loading = false;
				});
			}else{
				var courses = this.OriginalCourses;
				this.SearchResults = courses.filter(o=>o.TRAINING_NAME.toLowerCase().includes(this.Query.toLowerCase()));
				
				this.Loading = false;
			}
			
			
			/*this.SearchResults = [];
			setTimeout( () => {
				this.SearchResults = [{
					title: 'Example Course #1',
					type: 'Course'
				},{
					title: 'Example Course #2',
					type: 'Course'
				},{
					title: 'Example Live Session #1',
					type: 'Live Session'
				}];
				
			}, 3000);*/
		},
		FullSearch: function(){
			this.$router.push({ path: 'search', query: { q: this.Query } });
		}
	}
};