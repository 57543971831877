import { SocketMessage, SocketWelcome, SocketAuthentication, SocketSubscribe, SocketData, SocketUnsubscribe } from "./livesocket";
export class GeneralSocket{
	Socket: WebSocket;
	quitting: boolean;
	constructor(context){
		this.quitting = false;
		this.create(context);
	}
	create(context): void{
		try{
			this.Socket = new WebSocket("wss://classroom.locstatt.net/cfws");
		}catch(err){
			console.error("Error creating socket "+err);
			return;
		}
		this.Socket.onopen = () => {
			let welcome: SocketWelcome = new SocketWelcome();
			this.publish(welcome);
		};
		this.Socket.onmessage = (msg: MessageEvent) => {
			let response: SocketCFResponse = JSON.parse(msg.data);
			if(response.type == "response"){
				if(response.reqType == ColdFusion.WebSocket.WELCOME){
					let subscribeMsg: SocketSubscribe = new SocketSubscribe("General");
					this.publish(subscribeMsg);
				}else if(response.reqType == ColdFusion.WebSocket.SUBSCRIBE){
					context.commit("setGeneralSocket", this);
				}else if(response.reqType == ColdFusion.WebSocket.UNSUBSCRIBE){
					this.Socket.close();
				}
			}else if(response.type == "data"){
				context.dispatch("loadLiveCourses");
			}
		};
		this.Socket.onerror = (err: Event) => {
			console.error("General Socket error: " , err);
			this.Socket.close();
		};
		this.Socket.onclose = () => {
			this.Socket.close();
			context.commit("setGeneralSocket");
			if(!this.quitting){
				context.dispatch("createGeneralSocket");	
			}
		};
	}
	publish(msg: SocketMessage): void{
		if(this.Socket.readyState == this.Socket.OPEN){
			if(!msg.channel){
				msg.channel = "General";
			}
			this.Socket.send(msg.toString());
		}
	}
	disconnect(): void{
		this.Socket.close();
	}
	quit(): void{
		this.quitting = true;
		let unsub: SocketUnsubscribe = new SocketUnsubscribe();
		this.publish(unsub);
	}
}