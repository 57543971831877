export default {
	template: `
			<div id="contact-info-modal" class="modal modal-fixed-footer" >
				<div class="modal-content">
					<h4 style="border-bottom: 1px solid #c8c8c8"><i class="material-icons" style="margin-right: 10px;font-size: 35px;margin-bottom: 20px;">mail</i>Get In Touch</h4>
					<p>Our Help and Support Team can be reached  to provide you with more information or answer any questions you may have.</p>
					<p>Contact us at: </p>
					<a href="mailto:contact@locstatt.com" style="margin-left:50px;">contact@locstatt.com</a>
				</div>
				<div class="modal-footer">
					<a class="modal-action modal-close waves-effect waves-green btn-flat ">Got It!</a>
				</div>
			</div>
	`,
	created: function(){

	},
	mounted: function(){

	},
	methods: {
		
	},
	computed: {
		
	}
};