import * as Materialize from 'materialize-css';
import * as swal from 'sweetalert2';
import * as Vue from 'vue';
import { mapGetters } from "vuex";
import * as parser from 'xml2json-light';
import * as  convert from 'xml-js';
import * as Utils from "./Utils";
import * as axios from 'axios';

const CLASSROOM_URL = "https://locstatt.net/app/cfcChevron/classroom.cfc";


export default {
	template: `
	<div id="ClassRooms" class="controller" :class="{ 'open-drawer': showDrawer }">
		<navbar></navbar>
		<sidenav></sidenav>
		<loader v-if="Loading"></loader>
		<div class="row" :class="{ container: !showDrawer }">
			<div class="col s12" :class="{ 'no-padding': showDrawer }">
				<div class="row">
					<div class="col s12 no-padding">
						<ul class="tabs" ref="mainTabs">
							<li class="tab col" :class="showLiveSessions ? 's6' : 's12'"><a id="courses_tab" v-on:click="hideSearchBar" href="#History" class="active">Course Library</a></li>
							<li class="tab col s6" v-if="showLiveSessions"><a v-on:click="showSearchBar" href="#Live">Live Classes</a></li>
						</ul>
					</div>
					<div id="Live" class="content-wrapper" v-if="showLiveSessions">
						<live-menu
							v-bind:liveCourses="liveCourses"
							v-bind:loadingLive="loadingLive"
							v-bind:Loading="Loading"
						>
						</live-menu>
					</div>
					<div id="History">
						<div class="row" style="margin-top:-16px;">
							<div class="col s12" :class="{ 'no-padding': showDrawer }">
								<ul class="tabs" ref="historyTabs">
									<li class="tab col s3"><a class="active" href="#InProgress">In Progress</a></li>
									<li class="tab col s3"><a href="#Scheduled">Scheduled <a v-if="scheduled_amount > 0" class="btn btn-floating pulse" style="display: inline;width: 30px;margin-right: 0px;pointer-events: none;color: white !important;background-color: red;height: 30px;padding: 5px 10px;">{{scheduled_amount}}</a></a></li>
									<li class="tab col s3"><a href="#RequiredTraining">Required Training</a></li>
       								<li class="tab col s3"><a href="#AllCourses">Course Library</a></li>
								</ul>
							</div>

							<div :key="section.random_id" v-bind:id="section.id" v-for="(section, sectionIndex) in onlineTrainingSections">
								<br><br>
								<div class="row">
									<course-library
										v-bind:coursesGroups=section.groups
										v-bind:displayCourses=section.display
										v-bind:coursesType=section.type
									>
									</course-library>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<course-message-modal v-for="(course,index) in allCoursesUngroupped"
			v-bind:course="course"
		>
		</course-message-modal>


	  	<div id="no-content-warning" class="modal">
			<div class="modal-content">
				<h4>Warning</h4>
				<p>This Course has no content available. Please contact support to get help with this issue.</p>
			</div>
			<div class="modal-footer">
				<a class="modal-action modal-close waves-effect waves-green btn blue">Got It</a>
			</div>
		</div>
		<contact></contact>
		<profile></profile>
		<image-cropper v-if="renderImageCropper"></image-cropper>
	</div>`,


	data: function(){
		return {
			allCoursesUngroupped: [],
			onlineTrainingSections: [],
			scheduled_amount: 0,
			displayInProgress: true,
			displayRequiredTraining: true,
			OtherCourseData: true,
			returnedFromCourse: false,
			Loading: false, 
			dateFormat: "",
			employee_code: "",
			company_code: "",
			renderImageCropper: false
		};
	},

	created: async function(){
		await this.mountAllData();
	},

	mounted: function(){
		
	},


	beforeDestroy: function(){
		$(this.$el).find('.tooltipped').tooltip('remove');
		if(this.Socket){
			this.Socket.quit();
		}
	},
	computed: {
		...mapGetters(["loadingLive", "liveCourses"]),
		showDrawer: function(){
			return this.$store.state.ShowMenuDrawer;
		},
		showLiveSessions: function(){
			if(this.$store.state.UserData){
				return this.$store.state.UserData.CLASSROOM_VIRTUAL_SESSIONS;	
			}else{
				return [];
			}
		}
	},
	methods: {

		initializeDOMElements: function(){
			$(this.$el).find('.tooltipped').tooltip();
			if (this.$route.params.fromCourse){
				this.returnedFromCourse = this.$route.params.fromCourse;
			}	
			$(window).unbind('beforeunload');
			setTimeout(() => {
				if(this.returnedFromCourse){
					$("#courses_tab").click();
				}
			},100);
			
		}

		mountAllData: function(){
			const c = this;
			return new Promise(async function (resolve, reject){
				c.Loading = true;
				c.dateFormat = c.$store.state.UserData.LOCSTATT_DATE_FORMAT_X;
				c.employee_code = c.$store.state.UserData.EMP_CODE;
				c.company_code = c.$store.state.UserData.COMPANY_CODE;

				const site_code: number = c.$store.state.UserData.SITE_CODE ? c.$store.state.UserData.SITE_CODE : 0;
				const pos_code: number = c.$store.state.UserData.POS_CODE ? c.$store.state.UserData.POS_CODE : 0;

				/* In Progress Courses */
				let inProgressTrainingList: any;
				let scheduledTrainingList: any;
				let requiredTrainingList: any;
				let completeTrainingList: any;

				try {
					inProgressTrainingList = await axios.get(CLASSROOM_URL,{ params: {method: "getInProgressTrainingByEmployee", emp_code: c.employee_code} });
					scheduledTrainingList = await axios.get(CLASSROOM_URL,{ params: {method: "getScheduledCoursesByEmployeeId", emp_code: c.employee_code} });
					requiredTrainingList = await axios.get(CLASSROOM_URL,{params: {method: 'getSuggestedCoursesByFacilityAndJobPosition',company_code: c.company_code, site_code: site_code,pos_code: pos_code,emp_code: c.employee_code}});
					completeTrainingList = await axios.get(CLASSROOM_URL,{params: {method: 'getCourseLibraryByCompanyCode',company_code: c.company_code}});
				}catch (error){
					reject(error);
				}

				inProgressTrainingList = inProgressTrainingList.data;
				c.getProgressPercentageFromInProgressCourses(inProgressTrainingList);
				const inProgressCourses = c.divideListIntoGroups(inProgressTrainingList,3);
				
				/* Scheduled Training */
				scheduledTrainingList = scheduledTrainingList.data;
				/* Check if one is already included in In Progress*/

				for(var i = 0; i < scheduledTrainingList.length; i++){
					for(var j = 0; j < inProgressTrainingList.length; j++){
						if(scheduledTrainingList[i].TRAINING_CODE_ID === inProgressTrainingList[j].TRAINING_CODE_ID){
							scheduledTrainingList[i].IN_PROGRESS = true;
						}
					}
				}
				
				c.scheduled_amount = scheduledTrainingList.length;
				const scheduledCourses = c.divideListIntoGroups(scheduledTrainingList,3);
				
				/* Required Training */
				requiredTrainingList = requiredTrainingList.data;
				const requiredTrainingCourses = c.divideListIntoGroups(requiredTrainingList, 3);

				/* All Other Courses */
				completeTrainingList = completeTrainingList.data;

				const completeTrainingListWithoutInProgress = completeTrainingList.filter(x => !inProgressTrainingList.some(y => y.TRAINING_CODE_ID === x.TRAINING_CODE_ID));
				c.allCoursesUngroupped = [...completeTrainingListWithoutInProgress, ...scheduledTrainingList];
				const allCourses = c.divideListIntoGroups(completeTrainingListWithoutInProgress,3);

				c.onlineTrainingSections = [
					{
						random_id: Math.floor(Math.random() * 10000), 
						id: "InProgress",
						groups: inProgressCourses,
						display: inProgressCourses.length ? true:false,
						type: "in-progress"
					},
					{
						random_id: Math.floor(Math.random() * 10000),
						id: "Scheduled",
						groups: scheduledCourses,
						display: scheduledCourses.length ? true:false,
						type: "scheduled-training"	
					},
					{
						random_id: Math.floor(Math.random() * 10000),
						id: "RequiredTraining",
						groups: requiredTrainingCourses,
						display: requiredTrainingCourses.length ? true:false,
						type: "required-training"
						
					},
					{
						random_id: Math.floor(Math.random() * 10000),
						id: "AllCourses",
						groups: allCourses,
						display: allCourses.length ? true:false,
						type: "all-courses"
					}
				];
				setTimeout(function(){
					$('.tabs').tabs();
					c.Loading = false;
					resolve(true);
				}, 1000);
			});
		},
		
		setLoading: function(){
			this.Loading = !this.Loading;
		},

		openEditProfilePicture: function(){
			if(!this.renderImageCropper){
				this.renderImageCropper = true;
				setTimeout(()=>{
					$("#change-profile-picture").modal();
					$("#change-profile-picture").modal('open');
				},100)
			}else{
				$("#change-profile-picture").modal('open');
			}
		},

		openEmployeeProfileModal: function(){
			$("#user-profile-modal").modal("open");
			this.renderImageCropper = true;
			setTimeout(()=>{$("#change-profile-picture").modal()},500)
		},

		destroyCropperModal: function(){
			this.renderImageCropper = false;
		},

		getProgressPercentageFromInProgressCourses: function(inProgressCourses: Array<any>){
			if (inProgressCourses.length){
				inProgressCourses.forEach( async (course) => {
					const response = await axios.get(`https://classroom.locstatt.net/courseLibrary/${course.TRAINING_CODE_ID}/${course.TRAINING_CODE_ID}.xml`);
					let json = convert.xml2json(response.data, {compact: true, spaces: 4});
					json = JSON.parse(json);
					const sections = json.LOCSTATT.TRAINING.SECTION;
					let slides = null;
					if(sections instanceof Array){
						slides = this.getTotalNumberOfSlidesMultiSection(sections)
					}else{
						slides = {
							name:sections._attributes.name, 
							section_slides: sections.CONTENT.FRAME
						};
					}
					//Now we calculate the course length
					let course_length = 0;
					if (slides instanceof Array){
						slides.forEach((section) => {
							course_length += section.section_slides.length;
						});
					}else{
						course_length = slides.section_slides.length;
					}
					Vue.set(course, 'progress', Math.round((course.LAST_SLIDE / course_length) * 100));						
				});
			}
		},

		getTotalNumberOfSlidesMultiSection: function(sections: Array<any>){
			return sections.map(section => { 
				return {
					name:section._attributes.name, 
					section_slides:section.CONTENT.FRAME
				}; 
			});
		},

		reloadLiveSessions: function(){
			this.$store.dispatch("loadLiveCourses");
		},
		showSearchBar: function(){
			$("#SearchBar").show();
		},
		hideSearchBar: function(){
			$("#SearchBar").hide();
		},
		getPercentageFromInProgressCourse: function(course){
			if (course.LAST_SLIDE){
				var x = new XMLHttpRequest();
				var fileURL = "https://classroom.locstatt.net/courseLibrary/" + course.TRAINING_CODE_ID + "/" + course.TRAINING_CODE_ID + ".xml";
				x.onreadystatechange = () => {
					if (x.readyState == 4 && x.status == 200){
						var doc = x.responseXML;
						var XMLString = doc.documentElement.innerHTML;
						var json = {};
						var slides = [];
						try {
							json = convert.xml2json(XMLString, {compact: true, spaces: 4});
							json = jQuery.parseJSON(json);
							var sections = json.TRAINING.SECTION;
							if (sections instanceof Array){
								for(var i = 0; i < sections.length;i++){
									slides.push(
										{
											name: sections[i]._attributes.name,
											section_slides: sections[i].CONTENT.FRAME
										}
									)
								}
							}else{
								//slides = json.TRAINING.SECTION.CONTENT.FRAME;
								slides.push(
									{
										name: sections._attributes.name;
										section_slides: sections.CONTENT.FRAME;
									}
								);
							}
							
						} catch(err) {
							slides = []
							var slides_section = [];
							json = parser.xml2json(XMLString);
							var sections = json.TRAINING.SECTION;
							var slides_before = json.TRAINING.SECTION.CONTENT.FRAME;
							
							if (slides_before.length){
								for(var i in slides_before){

									var object = {
										_attributes: {
											id: slides_before[i].id,
											media: slides_before[i].media,
											video: slides_before[i].video,
											type: slides_before[i].type,
											have_question: slides_before[i].have_question,
											title: slides_before[i].title
										}

									}
									slides_section.push(object);
								}

								slides.push(
									{
										name: sections.name,
										section_slides: slides_section
									}
								);

							}else{
								slides_section = {
									_attributes: {
										id: slides_before.id,
										media: slides_before.media,
										video: slides_before.video,
										type: slides_before.type,
										have_question: slides_before.have_question,
										title: slides_before.title
									}
								}

								slides.push(
									{
										name: sections.name,
										section_slides: slides_section
									}
								);
							}
						}

						var course_length = 0;

						if (slides.length > 1){
							for(var i = 0; i < slides.length; i++){
								course_length += slides[i].section_slides.length;
							}
						}else{
							if (slides[0].section_slides instanceof Array){
								course_length = slides[0].section_slides.length
							}else{
								course_length = 1;
							}
							
						}

						Vue.set(course, 'progress', Math.round((course.LAST_SLIDE / course_length) * 100));						
						
					}
				};
				x.open("GET", fileURL, true);
				x.send();
			}else{
				Vue.set(course, 'progress', 0);
			}
		},


		checkIfExpired: function(suggestedCourse){
			var today = new Date()
			var date = Utils.getrequestDate(suggestedCourse.LAST_EXPIRATION_DATE, this.dateFormat))
			var courseDate = new Date(date);
			if (suggestedCourse.LAST_EXPIRATION_DATE){
				if (courseDate < today){
					return "Expired"
				}else if (courseDate > today){
					return "Current"
				}
			}	
			return "Not Taken"
		},


		getActivePage: function(){
			for(var i = 0; i < this.CoursesPaginationGroups.length; i++){
				if (document.getElementById("show-page-"+i).parentNode.classList.contains('active')){
					return i;
				}
			}
			return -1;
		},


		nextPage: function(event){
			if !(event.target.parentNode.parentNode.classList.contains('disabled')){
				if (this.CoursesPaginationGroups.length > 1){
					var activePage = this.getActivePage();
					var nextPage = activePage+1;
					if (activePage != -1){
						document.getElementById("show-page-"+activePage).parentNode.classList.remove('active');
						document.getElementById("show-page-"+nextPage).parentNode.classList.add('active');
						document.getElementById("page-"+activePage).style.display = "none";
						document.getElementById("page-"+nextPage).style.display = "";

						if (nextPage === this.CoursesPaginationGroups.length-1){
							event.target.parentNode.parentNode.classList.add('disabled');
						}

						if (nextPage > 0){
							document.getElementById('previousPage').classList.remove('disabled');
						}
					}
				}
			}
		},
		previousPage: function(event){
			if !(event.target.parentNode.parentNode.classList.contains('disabled')){
				var activePage = this.getActivePage();
				var previouspage = activePage-1;
				if (activePage != -1){
					document.getElementById("show-page-"+activePage).parentNode.classList.remove('active');
					document.getElementById("show-page-"+previouspage).parentNode.classList.add('active');
					document.getElementById("page-"+activePage).style.display = "none";
					document.getElementById("page-"+previouspage).style.display = "";

					if (previouspage === 0){
						event.target.parentNode.parentNode.classList.add('disabled');
					}

					if (previouspage < this.CoursesPaginationGroups.length-1){
						document.getElementById('nextPage').classList.remove('disabled');
					}


				}
			}
		
		},
		showPage: function(event){
			var index = event.target.dataset.index;
			if !(document.getElementById("show-page-"+index).parentNode.classList.contains('active')){
				var activePage = this.getActivePage();
				if (activePage != -1){
					document.getElementById("show-page-"+activePage).parentNode.classList.remove('active');
					document.getElementById("page-"+activePage).style.display = "none";
					document.getElementById("page-"+index).style.display = "";
					document.getElementById("show-page-"+index).parentNode.classList.add('active');

					if (parseInt(index) === 0){
						document.getElementById("previousPage").classList.add('disabled');
						document.getElementById("nextPage").classList.remove('disabled');
					}

					if(parseInt(index) > 0 && parseInt(index) < this.CoursesPaginationGroups.length-1){
						document.getElementById("previousPage").classList.remove('disabled');
						document.getElementById("nextPage").classList.remove('disabled');
					}

					if(parseInt(index) === this.CoursesPaginationGroups.length-1){
						document.getElementById("nextPage").classList.add('disabled');
						document.getElementById("previousPage").classList.remove('disabled');
					}
				}
			}
		},

		loadInProgressCourses: async function(){
			/* In Progress Courses */
			let inProgressTrainingList: any = await axios.get(CLASSROOM_URL,{ params: {method: "getInProgressTrainingByEmployee", emp_code: this.employee_code} });
			inProgressTrainingList = inProgressTrainingList.data;
			this.getProgressPercentageFromInProgressCourses(inProgressTrainingList);
			const inProgressCourses = this.divideListIntoGroups(inProgressTrainingList,3);
			const inProgressData = {
				id: "InProgress",
				groups: inProgressCourses,
				display: inProgressCourses.length ? true:false,
				type:"in-progress"
			}
			Vue.set(this.onlineTrainingSections,0,inProgressData);
			$('.dropdown-button').dropdown({
				inDuration: 300,
				outDuration: 225,
				constrainWidth: false, // Does not change width of dropdown to that of the activator
				hover: false, // Activate on hover
				gutter: 0, // Spacing from edge
				belowOrigin: false, // Displays dropdown below the button
				alignment: 'left', // Displays dropdown with edge aligned to the left of button
				stopPropagation: false // Stops event propagation
			});
		},

		loadSuggestedCourses: function(){
			const c = this;
			this.RequiredTrainingCourses = [];
			var site_code = 0;
			var pos_code = 0;
			if (this.$store.state.UserData.POS_CODE){
				pos_code = this.$store.state.UserData.POS_CODE;
			}
			if (this.$store.state.UserData.SITE_CODE){
				site_code = this.$store.state.UserData.SITE_CODE;
			}
			if (site_code === 0 && pos_code === 0){
				//
			}else{
				$.ajax({
					url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
					method: 'GET',
					data: {
						method: 'getSuggestedCoursesByFacilityAndJobPosition',
						company_code: this.$store.state.UserData.COMPANY_CODE,
						site_code: site_code,
						pos_code: pos_code,
						emp_code: this.$store.state.UserData.EMP_CODE
					}
				}).done ( (list: Array<any>) => {
					var newList = [];
					if (list.length){
						for (var i = 0; i < list.length; i++){
							list[i].LAST_EXPIRATION_DATE = Utils.dateFormat(list[i].LAST_EXPIRATION_DATE, this.dateFormat);
							for(var j = 0; j < this.originalInProgressCourses.length; j++){
								if(list[i].TRAINING_CODE === this.originalInProgressCourses[j].TRAINING_CODE){
									this.originalInProgressCourses.splice(j,1);
								}
							}
						}

						if (this.divideListIntoGroups(list, 3).length){
							this.RequiredTrainingCourses = this.divideListIntoGroups(list, 3);
							this.originalSuggestedCoursesGroups = this.RequiredTrainingCourses;
							this.originalSuggestedCourses = list;
						}
					}else{
						this.displayRequiredTraining = false;
					}
					setTimeout(() => {
						$('.modal').modal();
					},1000);
					
				}).fail((status, errorDesc) => {
					Materialize.toast('Unable to load suggested courses', 4000);
				}).always(() => {
					this.Loading = false;
				});
			}
		},

		divideListIntoGroups: function(list, groupSize){
			var size = groupSize;
			var groups = Math.ceil(list.length/size);
			let resultData: Array<object> = [];
			var ec = 0;

			for(var i = 0; i < groups; i++){
				var newG:any = {};
				let courses: Array<object> = []
				for (var j = i*size; j < (i+1)*size; j++){
					courses.push(list[j])
					ec++;
					if (ec === list.length){
						break;
					}
				}
				newG.GroupedCourses = courses;
				resultData.push(newG);
			}
			return resultData;
		},

		loadCourseLibrary: function(){
			var c = this;
			$.ajax({
				url: 'https://locstatt.net/app/cfcChevron/classroom.cfc',
				method: 'GET',
				data: {
					method: 'getCourseLibraryByCompanyCode',
					company_code: this.$store.state.UserData.COMPANY_CODE
				}
			}).done((list: Array<any>) => {
				if(list.length){
					var results = [];
					var repeated = false;
					for(var i = 0; i < list.length; i++){
						for(var j = 0; j < this.originalSuggestedCourses.length; j++){
							if (list[i].TRAINING_CODE === this.originalSuggestedCourses[j].TRAINING_CODE){
								repeated = true;
								break;
							}
						} 
						if (repeated){
							repeated = false;
						}else{
							results.push(list[i]);
						}
					}

					var newResults = [];
					for(var i = 0; i < results.length; i++){
						for(var j = 0; j < this.originalInProgressCourses.length; j++){
							if (results[i].TRAINING_CODE === this.originalInProgressCourses[j].TRAINING_CODE){
								repeated = true;
								break;
							}
						} 
						if (repeated){
							repeated = false;
						}else{
							newResults.push(results[i]);
						}
					}
					/*
					for (var i = 0; i = list.length; i++){
						for(var j = 0; j < this.originalInProgressCourses.length; j++){
							if(list[i].TRAINING_CODE === this.originalInProgressCourses[j].TRAINING_CODE){
								//list.splice(i,1);
							}
						}
					}*/
					var paginationGroups = this.divideListIntoGroups(newResults,20);
					if(paginationGroups.length){
						for(var i = 0; i < paginationGroups.length; i++){
							if (paginationGroups[i].GroupedCourses.length){
								var groupedCourses = this.divideListIntoGroups(paginationGroups[i].GroupedCourses, 3);
								if (groupedCourses.length){
									paginationGroups[i] = groupedCourses;
								}
							}
						}
					}

					this.CoursesPaginationGroups = paginationGroups;
					this.originalCoursePaginationGroups = this.CoursesPaginationGroups;
					this.allCourses = list;
				}else{
					this.OtherCourseData = false;
				}
				setTimeout(() => {
					$('.modal').modal();
				},1000);
			});
		},

		searchSuggestedCourses: function(event){
			var suggested = [];
			if (event.target.value === ""){
				this.RequiredTrainingCourses = this.originalSuggestedCoursesGroups;
			}else{
				if (/\S/.test(event.target.value)) {
					this.originalSuggestedCourses.forEach(function(element){
						if ((element.TRAINING_NAME.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1) || (element.TRAINING_CODE.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1)) {
							suggested.push(element);
						}
					});

					if(suggested.length){
						var groups = this.divideListIntoGroups(suggested, 3);
						if(groups.length){
							this.RequiredTrainingCourses = groups;
						}
					}

					if(suggested.length === 0){
						this.RequiredTrainingCourses = [];
					}					
				}
			}
		},
		searchAllCourses: function(event){
			var courses = [];
			if (event.target.value == ""){
				this.CoursesPaginationGroups = this.originalCoursePaginationGroups;
			}else{
				if (/\S/.test(event.target.value)) {
					this.allCourses.forEach(function(element){
						if ((element.TRAINING_NAME.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1) || (element.TRAINING_CODE.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1)) {
							courses.push(element);
						}
					});

					if(courses.length){
						var paginationGroups = this.divideListIntoGroups(courses,20);
						if(paginationGroups.length){
							for(var i = 0; i < paginationGroups.length; i++){
								if (paginationGroups[i].GroupedCourses.length){
									var groupedCourses = this.divideListIntoGroups(paginationGroups[i].GroupedCourses, 3);
									if (groupedCourses.length){
										paginationGroups[i] = groupedCourses;
									}
								}
							}
						}
						this.CoursesPaginationGroups = paginationGroups;
						document.getElementById("page-0").style.display = "";
						document.getElementById("show-page-0").parentNode.classList.add('active');

						if (this.CoursesPaginationGroups.length === 1){
							document.getElementById("nextPage").classList.add('disabled');
							document.getElementById("previousPage").classList.add('disabled');
						}else if (this.CoursesPaginationGroups.length > 1){
							document.getElementById("nextPage").classList.remove('disabled');
						}
					}
					
					if(courses.length === 0){
						this.CoursesPaginationGroups = [];
					}
				}
			}
		},
		join: function(session){
			this.$router.push({ 
				name: 'Live' ,
				params: { ClassKey: session.CLASS_KEY }
			});
		}
	}
};