import * as Materialize from 'materialize-css';
import * as SweetAlert from 'sweetalert';
import * as Rx from 'rxjs';
import * as Vue from 'vue';
import * as VueRouter from 'vue-router';
import * as $ from 'jquery';
import * as parser from 'xml2json-light';
import 'jquery-ui-sortable-npm';
import * as datepickerFactory from 'jquery-datepicker';
import * as datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';

export default {
	template: `
				<div class="testBody" v-if="CourseTest.test_sections" style="margin-top: 82px;">
					<ul class="collapsible" data-collapsible="expandable">
						<li v-for="(section,index) in CourseTest.test_sections" class="active">
							<div class="collapsible-header active row" style="margin-bottom:0px;">
								<div class="col s6">
							    	<span><i class="material-icons">list</i> <span style="margin-top: -30px;display: block;margin-left: 35px;">{{index+1}}. {{section.SECTION_NAME}}</span></span>
							    </div>
							    <div class="col s6">
							    	<span class="right" v-if="section.QUESTIONS_ANSWERED === 0"> Answered: 0/{{section.QUESTIONS.length}}</span>
							    	<span class="right" v-else> Answered: {{section.QUESTIONS_ANSWERED}}/{{section.QUESTIONS.length}}</span>
							    </div>
							</div>
							<div class="collapsible-body">
								<div v-for="(question, questionIndex) in section.QUESTIONS" class="question">
							    	<div class="question-content">
							    		<div class="question-type-icon">
							    		</div>
							    		<b style="margin-right:10px;">{{question.section_index+1}}.{{question.question_index+1}} </b>
							    		<span style="flex-basis:100%"> {{question.QUESTION_NAME}}</span>
							    	</div>
							    	<div class="answer-preview"> 
							    		<div v-if="question.QUESTION_TYPE_ID === 10000 || question.QUESTION_TYPE_ID === 11899">
							    			<textarea v-on:change="setQuestionAsAnswered(index, questionIndex)" v-bind:id="'short-answer-question-'+index+'-'+questionIndex" class="materialize-textarea">
							    			</textarea>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 10211">
							    			<input v-on:change="setQuestionAsAnswered(index, questionIndex)" v-bind:id="'integer-question-'+index+'-'+questionIndex" type="text" class="validate" >
							    			</input>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 11477">
							    			<div v-if="question.STRUCTURE_PARSED !== undefined">
							    				<div class="row" v-if="question.STRUCTURE_PARSED.IS_RANGE">
													<div class="col s6">
														Start: 
														<div class="input-field inline">
															<input v-bind:data-isrange="question.STRUCTURE_PARSED.IS_RANGE" v-bind:data-sIndex="index" v-bind:data-qIndex="questionIndex" class="datepick" readonly type="text" v-bind:id="'start-date-range-question-'+index+'-'+questionIndex" style="cursor:pointer;">
														</div>
													</div>
													<div class="col s6">
														End: 
														<div class="input-field inline">
															<input v-bind:data-isrange="question.STRUCTURE_PARSED.IS_RANGE" v-bind:data-sIndex="index" v-bind:data-qIndex="questionIndex" class="datepick" readonly type="text" v-bind:id="'end-date-range-question-'+index+'-'+questionIndex" style="cursor:pointer;">
														</div>
													</div>
												</div>
												<div class="row" v-else>
													<div class="input-field col s12">
														<input v-bind:data-isrange="question.STRUCTURE_PARSED.IS_RANGE" v-bind:data-sIndex="index" v-bind:data-qIndex="questionIndex" class="datepick" readonly type="text" v-bind:id="'date-question-'+index+'-'+questionIndex" style="cursor:pointer;">
													</div>
												</div>
							    			</div>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 11688">
							    			<input v-on:change="setQuestionAsAnswered(index, questionIndex)" v-bind:id="'barcode-question-'+index+'-'+questionIndex" type="text" class="validate" ></input>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 10844">
							    			<div v-if="question.STRUCTURE_PARSED !== undefined">
							    				<p v-for="(choice, choiceIndex) in question.STRUCTURE_PARSED.CHOICES">
							    					<input v-on:change="setQuestionAsAnswered(index, questionIndex)" v-bind:name="'question-'+index+'-'+questionIndex" v-bind:data-choiceindex="choiceIndex" v-bind:id="choiceIndex+'-'+index+'-'+questionIndex" type="radio"  v-bind:data-name="choice.VAL" v-bind:value="choice.VAL" v-bind:data-answer="choice.VAL"/>
							    					<label v-bind:for="choiceIndex+'-'+index+'-'+questionIndex">{{choice.VAL}}</label>
							    				</p>
							    			</div>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 10633">
							    			<div v-if="question.STRUCTURE_PARSED !== undefined">
							    				<p v-for="(choice, choiceIndex) in question.STRUCTURE_PARSED.CHOICES">
							    					<input v-on:change="setQuestionAsAnswered(index, questionIndex)" v-bind:name="'question-'+index+'-'+questionIndex" v-bind:data-choiceindex="choiceIndex" v-bind:id="choiceIndex+'-'+index+'-'+questionIndex" type="radio"  v-bind:data-name="choice.VAL" v-bind:value="choice.VAL" v-bind:data-answer="choice.VAL"/>
							    					<label v-bind:for="choiceIndex+'-'+index+'-'+questionIndex">{{choice.VAL}}</label>
							    				</p>
							    			</div>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 10422 || question.QUESTION_TYPE_ID === 12110">
							    			<div v-if="question.STRUCTURE_PARSED !== undefined">
							    				<p v-for="(choice, choiceIndex) in question.STRUCTURE_PARSED.CHOICES">
							    					<input v-on:change="setQuestionAsAnswered(index, questionIndex)" v-bind:class="'question-'+index+'-'+questionIndex" v-bind:name="'question-'+index+'-'+questionIndex" v-bind:data-choiceindex="choiceIndex" v-bind:id="choiceIndex+'-'+index+'-'+questionIndex" type="checkbox"  v-bind:data-name="choice.VAL" v-bind:data-answer="choice.VAL"/>
							    					<label v-bind:for="choiceIndex+'-'+index+'-'+questionIndex">{{choice.VAL}}</label>
							    				</p>
							    			</div>
							    		</div>
							    		<div v-if="question.QUESTION_TYPE_ID === 12532">
							    			<div class="row" style="margin-top:10px;margin-bottom:0px;font-weight:bold;">
							    				<span>Drag the option from the list on the left and drop it in the square on the right. Drag and drop the options in the correct order.
							    					Please drag and drop all options to the right, otherwise your question will remain unanswered. If you want to reorder your answer, just drag an option and drop it in another position in the same list.
							    				</span>
							    			</div>
							    			<div class="row">
							    				<div class="col s6">
							    					<div v-if="question.STRUCTURE_PARSED !== undefined">
							    						<ul v-bind:id="'matching-question-'+index+'-'+questionIndex+'-2'" v-bind:class="'sortable-lists connect-lists-'+index+'-'+questionIndex">
															<li v-for="(choice, choiceIndex in question.STRUCTURE_PARSED.CHOICES" v-bind:data-choiceindex="choiceIndex" v-bind:data-originalIndex="choice.I" class="ui-state-default" v-bind:data-qIndex="questionIndex" v-bind:data-sIndex="index" v-bind:data-answer="choice.VAL"> {{choice.VAL}}</li>
														</ul>
													</div>
							    				</div>
							    				<div class="col s6">
							    					<div>
							    						<ul v-bind:id="'matching-question-'+index+'-'+questionIndex+'-1'" v-bind:class="'sortable-lists connect-lists-'+index+'-'+questionIndex">
																					
														</ul>
													</div>
							    				</div>
							    			</div>
							    		</div>
							    	</div>
							    </div>
							</div>
						</li>
					</ul>
				</div>
			
	`,

	props:["CourseTest"],

	created: function(){

	},
	mounted: function(){

		
	},
	methods: {

		setQuestionAsAnswered: function(index, questionIndex){
			this.$parent.setQuestionAsAnswered(index, questionIndex);
		},
		
	},
	computed: {
		
	}
};