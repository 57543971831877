import * as axios from 'axios';
import { materialize } from 'rxjs/operator/materialize';
import * as Utils from "../controllers/Utils";
const UPLOAD_URL = "https://locstatt.net/eduardo/ImageCropUpload.cfm";

export default {
	template: `
                <div class="modal modal-fixed-footer" id="change-profile-picture" style="height:700px !important">
                    <div class="modal-content">
                        <div class="row">
                            <div class="col s12 m7 l8">
                                <div class="image-cropper-prev">
                                </div>
                            </div>

                            <div class="col s12 m5 l4 center">

                                <div class="image-cropper-res-frame">
                                    <img class="image-cropper-result" src="images/user-nopic.jpg" style="width:200px;" accept="image/*"/>
                                </div>


                                <div class="image-cropper-confirm">
                                    <a class="img-crop-save waves-effect waves-green white-text btn" href="#" @click="saveUpdatedImage">Save</a>
                                </div>

                            </div>
                        </div>
                        <div class="image-cropper-chooser">
                            <i class="material-icons tiny">image</i>&nbsp;Choose a picture:&nbsp;<input @change="handleImagePreview" class="image-cropper-input" type="file" name="user_image" style="max-width: 300px;"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="waves-effect waves-green btn-flat" @click="closeCropperModal">Close</a>
                    </div>
                </div>
            `,

    data: function(){ 
        return { 
            boundx: -1,
            boundy: -1,
            result: {},
            uploading:false,
        }; 
    },
	created: function(){

	},
	mounted: function(){

	},
	methods: {
        
        setLoading:function(){
            this.$parent.setLoading();
        },

        closeCropperModal:function(){
            this.$parent.destroyCropperModal();
            $(".modal-overlay")[1].remove();
            
        },

        saveUpdatedImage: async function(){
            this.setLoading();
            const c = this;
            const cc = this.result;
            const realImage = $('.image-cropper-preview').first()[0]
            const resizedImage = $('.image-cropper-preview').last();
            const rw =  (realImage.width/resizedImage.width());
            const rh =  (realImage.height/resizedImage.height());
            const emp_Code = this.$store.state.UserData.EMP_CODE;
            cc.w = cc.h = Math.round(cc.w * ((rw > rh) ? rw : rh));
            cc.x = Math.round(cc.x * (realImage.width/resizedImage.width()));
            cc.y = Math.round(cc.y * (realImage.height/resizedImage.height()));
            this.uploading = true;
            const formData = new FormData();
            formData.append('Filedata', $('.image-cropper-input').prop('files')[0] );
            const response = await axios.post(`${UPLOAD_URL}?emp_code=${emp_Code}&coor_x=${cc.x}&coor_y=${cc.y}&image_width=${cc.w}&image_height=${cc.h}`,formData);
            if (response.status === 200){
                $("#change-profile-picture").modal('close');
                $("#user-profile-modal").modal('close');
                c.pictureUpdated(Utils.getUserPhoto(emp_Code.toString()) + "?" + new Date().getTime());
                Materialize.toast("Your Photo has been  Successfully updated!", 4000);
                this.setLoading();
                c.closeCropperModal();
            }
        },
        pictureUpdated: function(newURL){
            $('.user-picture').attr('src', newURL)
            $('.user-profile-picture').attr('src', newURL)    
        },
        updateResultPreview(){
            const resultWidth = 200;
            const resultHeight = 200;
            const $pimg = $('.image-cropper-result')
            const xsize = resultWidth
            const ysize = resultHeight
            const self = this;
            return (c) => {
                console.log("this is c: ",c);
                if (parseInt(c.w) > 0)
                self.result = c;
                const rx = xsize / c.w
                const ry = ysize / c.h
                $pimg.css({
                    width: Math.round(rx * self.boundx) + 'px',
                    height: Math.round(ry * self.boundy) + 'px',
                    marginLeft: '-' + Math.round(rx * c.x) + 'px',
                    marginTop: '-' + Math.round(ry * c.y) + 'px'
                });
            }
        },


        handleImagePreview: async function(el){
            const c = this;
            const input = el.target;
            const reader = new FileReader();
            reader.onload = await function(){
                const dataURL = reader.result;
                $('.image-cropper-prev *').remove();
                const $img = $('<img class="image-cropper-preview" src="public/images/user-nopic.jpg" />');
                $('.image-cropper-prev').append($img)
                $(".image-cropper-preview").attr("src", dataURL);
                $('img.image-cropper-result').attr("src", dataURL);

                $img.Jcrop({
                    aspectRatio: 1,
                    onChange: c.updateResultPreview()
                }, function(){
                    var bounds = this.getBounds();
                    c.boundx = bounds[0];
                    c.boundy = bounds[1];
                    this.animateTo([5,5,$img.width()-5,$img.height()-5]);
                })
                $('.image-cropper-confirm').fadeIn();
                return              
            };
            reader.readAsDataURL(input.files[0]);
            $('.jcrop-holder').next().css('left', '40px !important;')
            $('.jcrop-holder').next().css('top', '30px !important;')
        },
	},
	computed: {
		
	}
};