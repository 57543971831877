export default {
	template: ` <div class="col s12 m6 l3">
                    <div class="card course live">
                        <div class="card-image waves-effect waves-block waves-light center z-depth-1 activator" :style="{ 'height':'165px', 'background-color': session.COVER_COLOR}">
                            <a class="btn-floating yellow darken-3 pulse right"></a>
                            <img v-bind:src="'https://locstatt.net/app/classroomCovers/' + session.COVER_IMAGE_NAME">
                        </div>
                        <div class="card-content center" style="padding:0px;">
                            <span class="card-title grey-text text-darken-4">{{session.TRAINING_NAME}}</span>
                            <span><strong>Code: </strong>{{session.TRAINING_CODE}}</span><br>
                            <span><strong>Instructor: </strong>{{session.EMP_NAME}}</span><br>
                            <span><strong>Creation Date: </strong>{{session.CLASS_DATE}}</span><br>
                            <span><strong>Time: </strong>{{session.CLASS_TIME}}</span>
                            <p class="center-align">
                                <b>Class Key: <b><span>{{session.CLASS_KEY.toUpperCase()}}</span>
                            </p>
                        </div>
                        <div class="card-action center-align">
                            <router-link :to="{ name: 'Live', params: { ClassKey: session.CLASS_KEY } }" class="btn blue modal-trigger">
                                Join Class
                            </router-link>
                        </div>
                        <div class="card-reveal">
                            <span class="card-title grey-text text-darken-4"><i class="material-icons right">close</i></span>
                            <p>by {{session.EMP_NAME}}</p>
                            <p class="description">{{session.TRAINING_DESCRIPTION}}</p>
                        </div>
                    </div>
                </div>`,

    data: function(){
        return{
            
        };
    },

	props:["session"],

	created: function(){
	},

	mounted: function(){

	},
	methods: {
        
	}
};